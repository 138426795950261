import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FaArrowCircleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import postData from "../../../hooks/postData";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";

const AddCustomer = () => {
  const { t } = useTranslation();
  const { register, reset, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const response = await postData("/vendor/createcustomer", data);
    if (response) {
      toast.success("Customer added Successfully");
      reset();
    } else {
      toast.error("Something went Wrong");
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="flex">
        <h2 className="text-title text-lg md:text-2xl pb-2 mr-3">
          {t("add_customers")}
        </h2>
        <Link to={"/vendor/customers"}>
          <button className="btn btn-primary btn-xs rounded text-white mb-5 flex justify-center items-center gap-x-2">
            <FaArrowCircleLeft size={15} />
            {t("add_new_product_back")}
          </button>
        </Link>
      </div>
      <div className="mt-4">
        <div className="flex flex-col gap-4 md:px-12 bg-white border rounded shadow p-5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-control grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5 ">
              <div>
                <label className="label">
                  <span className="label-text">{t("Full_Name")}</span>
                </label>
                <input
                  {...register("cust_fullname")}
                  type="text"
                  placeholder={`${t("Full_Name")}`}
                  className="input input-bordered input-sm w-full rounded-none"
                  required
                />
              </div>
              <div>
                <label className="label">
                  <span className="label-text">{t("l_phone")}</span>
                </label>
                <input
                  {...register("cust_phone")}
                  type="number"
                  placeholder={`${t("num")}`}
                  className="input input-bordered input-sm w-full rounded-none"
                  required
                />
              </div>
              <div>
                <label className="label">
                  <span className="label-text">{t("form_email")}</span>
                </label>
                <input
                  {...register("cust_email")}
                  type="email"
                  placeholder="demo@gmail.com"
                  className="input input-bordered input-sm w-full rounded-none"
                  required
                />
              </div>
              <div>
                <label className="label">
                  <span className="label-text">{t("l_p")}</span>
                </label>
                <input
                  {...register("cust_password")}
                  type="text"
                  className="input input-bordered input-sm w-full rounded-none"
                  required
                />
              </div>
            </div>
            <button className="btn btn-sm btn-primary rounded text-white mt-5">
              {t("add_customer")}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddCustomer;
