import React from "react";
import slider1 from "../../assets/slider/guide_slider1.jpg"
import slider1Ar from "../../assets/slider/guide_slider2.jpg"
import slider2 from "../../assets/slider/guide_slider3.jpg"
import slider2Ar from "../../assets/slider/guide_slider4.jpg"
import slider3 from "../../assets/slider/guide_slider5.jpg"
import slider3Ar from "../../assets/slider/guide_slider6.jpg"
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const GuideSlider = () => {
  const selectLanguage = i18next.language;
  const { t } = useTranslation();

  return (
    <>
      <div>
        <h1 className="capitalize text-[30px] font-semibold text-[#236260] mt-[-4px] font-poppin">
        {t("Slider Setting")}
        </h1>
        <ul className="list-disc">
          <li className="text-lg text-gray-600 my-3">
            {selectLanguage === "ar"
              ? "انتقل إلى كافة الإعدادات وحدد إعدادات شريط التمرير/الأعمدة"
              : " Go to All Settings and select  slider/banner settings"}
            <img
              src={selectLanguage === "ar" ? slider1Ar : slider1}
              className="w-full md:w-[70%] m-auto my-2"
            />
          </li>
          <li className="text-lg text-gray-600 my-3">
            {selectLanguage === "ar"
              ? "حدد شريط التمرير / الشعار"
              : "Select slider / banner"}
            <img
              src={selectLanguage === "ar" ? slider2Ar : slider2}
              className="w-full md:w-[70%] m-auto my-2"
            />
          </li>
          <li className="text-lg text-gray-600 my-3">
            {selectLanguage === "ar"
              ? "أضف صورة شريط التمرير وانقر على زر الحفظ"
              : "Add slider image and click on save button"}
            <img
              src={selectLanguage === "ar" ? slider3Ar : slider3}
              className="w-full md:w-[70%] m-auto my-2"
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default GuideSlider;
