import React, { useEffect, useState } from "react";
import SectionTitle from "../utils/SectionTitle";
import { AiTwotonePhone } from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";
import { MdLocationOn } from "react-icons/md";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Div from "../utils/Div";
import { phoneNumberAutoFormat } from "../utils/NumberFormat";
import axios from "axios";

const ContactUs = ({ ganarel }) => {
  const { t } = useTranslation();
  let selectLanguage = i18next.language;
  const [contact, setContact] = useState();
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm();

  const { email, contact_no, address } = ganarel;
  useEffect(() => {
    if (!contact_no) {
      return;
    }
    const contact_number = phoneNumberAutoFormat(contact_no);
    setContact(contact_number);
  }, [ganarel]);

  const onSubmit = async (data, e) => {
    e.preventDefault();

    const res = await axios.post(
      `${process.env.REACT_APP_APIURL}/admin/creategeneralquery`,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const response = await res.data;
    if (response.status_code === 200) {
      toast.success(response.message);
      reset();
      return;
    }
    toast.error(response.err);
  };

  return (
    <section id="contact" className="bg-[#F7F7F7] ">
      <Div>
        <div className="pt-10">
          <SectionTitle page={"contact"} />
          <div className="pt-1">
            <p className="text-[16px] font-bold underline text-[#236260]">
              {selectLanguage === "ar"
                ? "مرحبا بك في موقعنا. نحن مسرورون بمجيئك"
                : "Welcome to our Website. We are glad to have you around"}
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-20 py-10">
            <div className="">
              <div className="pt-7">
                <div className=" grid grid-cols-2">
                  <div className="flex">
                    <div className="mr-3 mt-1">
                      <AiTwotonePhone className="text-primary" size={30} />
                    </div>
                    <div>
                      <div>
                        <h5 className="text-lg mb-3 font-Roboto font-semibold md:text-[18px]">
                          {t("form_phone")}
                        </h5>
                      </div>
                      <div>
                        <p className="font-Roboto font-bold text-gray-600">
                          {contact}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="mr-3 mt-1">
                      <HiOutlineMail className="text-primary" size={30} />
                    </div>
                    <div>
                      <div>
                        <h5 className="text-lg mb-3 font-Roboto font-semibold md:text-[18px]">
                          {t("Cus_Mail")}{" "}
                        </h5>
                      </div>
                      <div>
                        <p className="font-Roboto font-bold text-gray-600">
                          {" "}
                          {email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="pt-10">
                <div className="flex">
                  <div className="mr-3 mt-1">
                    <MdLocationOn className="text-primary" size={30} />
                  </div>
                  <div>
                    <div>
                      <h5 className="text-lg mb-3 font-Roboto font-semibold md:text-[18px]">
                        {t("Address")}
                      </h5>
                    </div>
                    <div>
                      <p className="font-Roboto font-bold text-gray-600">
                        {selectLanguage === "ar"
                          ? "العليا، الرياض 12213، المملكة العربية السعودية"
                          : `${address}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="pt-10">
                <div className="flex gap-3 mt-8">
                  <a
                    href="https://www.facebook.com/AITECHTECHNOLOGY"
                    target="_blank"
                  >
                    <div
                      title="Facebook"
                      className="flex justify-center items-center w-7 h-7 rounded-full border-2 border-gray-600 text-gray-600 hover:text-primary hover:scale-[1.07] transition-all cursor-pointer"
                    >
                      <i className="fa fa-facebook hover:scale-105"></i>
                    </div>
                  </a>
                  {ganarel?.twitter_url && (
                    <a href={ganarel?.twitter_url} target="_blank">
                      <div
                        title="Linkedin"
                        className="flex justify-center items-center w-7 h-7 rounded-full border-2 border-gray-600 text-gray-600 hover:text-primary cursor-pointer hover:scale-[1.07] transition-all"
                      >
                        <i className="fa fa-linkedin hover:scale-105"></i>
                      </div>
                    </a>
                  )}

                  <a
                    href="https://www.youtube.com/channel/UC30Fl3vkUf6y44CBr6aOjig"
                    target="_blank"
                  >
                    <div
                      title="Youtube"
                      className="flex justify-center items-center w-7 h-7 rounded-full border-2 border-gray-600 text-gray-600 hover:text-primary cursor-pointer hover:scale-[1.07] transition-all"
                    >
                      <i className="fa fa-youtube hover:scale-105"></i>
                    </div>
                  </a>
                  <a href="https://www.instagram.com/ai_tech.com.sa/" target="_blank">
                    <div
                      title="Instagram"
                      className="flex justify-center items-center w-7 h-7 rounded-full border-2 border-gray-600 text-gray-600 hover:text-primary hover:scale-[1.07] transition-all cursor-pointer" 
                    >
                      <i className="fa fa-instagram hover:scale-105"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div>
              <form onSubmit={handleSubmit(onSubmit)} className="md:p-0 py-1">
                <div
                  className={`form-control w-full ${
                    selectLanguage === "ar" ? "ml-10" : "mr-10"
                  } `}
                >
                  <input
                    {...register("name", {
                      required: true,
                      pattern: /^(?=.{1,30}$)[a-zA-Z ,.'-]+$/i,
                    })}
                    type="text"
                    placeholder={`${t("form_name_place")}*`}
                    className="input input-sm input-bordered p-5 focus:border-primary focus:outline-none rounded-none w-full "
                  />
                  <label className="label">
                    {errors.name?.type === "required" && (
                      <span className="label-text-alt text-red-500 font-Roboto">
                        {t("form_name_req")}
                      </span>
                    )}
                    {errors.name?.type === "pattern" && (
                      <span className="label-text-alt text-red-500 font-Roboto">
                        Name should consists of alphabet letters and maximum of
                        30 characters
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <input
                    {...register("email", { required: true })}
                    type="email"
                    placeholder={`${t("form_email_place")}*`}
                    className="input input-sm input-bordered focus:border-primary p-5 focus:outline-none rounded-none w-full "
                  />
                  <label className="label">
                    {errors.email?.type === "required" && (
                      <span className="label-text-alt text-red-500 font-Roboto">
                        {t("form_email_req")}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <input
                    {...register("phone", {
                      required: true,
                      minLength: 10,
                      maxLength: 12,
                    })}
                    type="number"
                    placeholder={`${t("form_phone_place")}*`}
                    className="input input-sm input-bordered focus:border-primary p-5 focus:outline-none rounded-none w-full "
                  />
                  <label className="label">
                    {errors.phone?.type === "required" && (
                      <span className="label-text-alt text-red-500 font-Roboto">
                        {t("form_phone_req")}
                      </span>
                    )}
                    {(errors.phone?.type === "maxLength" ||
                      errors.phone?.type === "minLength") && (
                      <span className="label-text-alt text-red-500 font-Roboto">
                        Contact number must be of minimum 10 and maximum 12
                        length
                      </span>
                    )}
                  </label>
                </div>

                <div
                  className={`form-control w-full ${
                    selectLanguage === "ar" ? "ml-10" : "mr-10"
                  }`}
                >
                  <textarea
                    {...register("query", { required: true })}
                    type="text"
                    placeholder={`${t("form_message_place")}*`}
                    className="input input-sm input-bordered p-5 focus:border-primary focus:outline-none rounded-none w-full h-[100px] max-h-[200px]"
                    maxLength={200}
                  />

                  <label className="label">
                    {errors.query?.type === "required" && (
                      <span className="label-text-alt text-red-500 font-Roboto">
                        {t("form_message_req")}
                      </span>
                    )}
                  </label>
                </div>

                <button className="rounded-md bg-primary font-bold py-3 px-8 mt-1 text-white">
                  {t("btn_send")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </Div>
    </section>
  );
};

export default ContactUs;
