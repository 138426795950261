import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CustomerTable from "./CustomerTable";
import getAllAdmin from "../../../hooks/getAllAdmin";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";

const AdminCustomers = () => {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const getAllData = async () => {
      const res = await getAllAdmin("admin/getallcustomer");
      if (res.status_code === 200) {
        setCustomers(res.data);
      }
      setLoading(false);
    };
    getAllData();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <h2 className="text-title text-lg md:text-2xl pb-2">{t("customers")}</h2>
      <CustomerTable customerData={customers} isSuspended={false} />
    </>
  );
};

export default AdminCustomers;
