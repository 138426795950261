import { useNavigate } from "react-router-dom";
import { MdStore } from "react-icons/md";
import { FaShippingFast } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { BsFillCartPlusFill } from "react-icons/bs";
import { TbPackage } from "react-icons/tb";
import { FcApproval } from "react-icons/fc";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function VendorFlow() {
  const navigate = useNavigate();

  const [completed, setCompleted] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
  });

  //   set this array from api response
  const bool = [1, 5, 4];

  // useEffect(() => {
  //   if (bool.includes(1)) {
  //     setCompleted((prev)=>({ ...prev, first: true }));
  //   }
  //   if (bool.includes(2)) {
  //       setCompleted((prev)=>({ ...prev, second: true }));
  //   }
  //   if (bool.includes(3)) {
  //       setCompleted((prev)=>({ ...prev, third: true }));
  //   }
  //   if (bool.includes(4)) {
  //       setCompleted((prev)=>({ ...prev, fourth: true }));
  //   }
  //   if (bool.includes(5)) {
  //       setCompleted((prev)=>({ ...prev, fifth: true }));
  //   }
  // }, []);

  const { t } = useTranslation();
  const selectLanguage = i18next.language;

  const flowArray = [
    {
      icon: <MdStore size={25} />,
      heading: `${t("Flow1")}`,
      content: "",
      list: [
        `${t("main1.1")}`,
        `${t("main1.2")}`,
        `${t("main1.3")}`,
        `${t("main1.4")}`,
      ],
      route: "/vendor/settings/store-settings",
      status: completed.first,
      last: `${t("main_last")}`,
    },
    {
      icon: <FaShippingFast size={24} />,
      heading: `${t("Flow3")}`,
      content: `${t("Flow4")}`,
      list: [],
      route: "/vendor/settings/shipping-methods",
      status: completed.second,
      last: "",
    },
    {
      icon: <MdPayment size={24} />,
      heading: `${t("Flow5")}`,
      content: `${t("Flow6")}`,
      list: [],
      route: "/vendor/settings/payment-methods",
      status: completed.third,
      last: "",
    },
    {
      icon: <BsFillCartPlusFill size={24} />,
      heading: `${t("Flow7")}`,
      content: `${t("Flow8")}`,
      list: [],
      route: "/vendor/add-product",
      status: completed.fourth,
      last: "",
    },
    {
      icon: <TbPackage size={24} />,
      heading: `${t("Flow9")}`,
      content: `${t("Flow10")}`,
      list: [],
      route: "/vendor/packages",
      status: completed.fifth,
      last: "",
    },
  ];
  
  return (
    <>
      <h4 className="font-bold">
        {selectLanguage == "ar"
          ? "مرحبًا! هذا هو دليلك خطوة بخطوة لتحسين تجربة المستخدم. يرجى اتباع هذه الإرشادات."
          : "Hello User! This is Your Step-by-Step Guide for a Seamless Experience. Follow These Guidelines for an Enhanced User Journey."}
      </h4>
      <ul className="my-4">
        {flowArray.map((flow, idx) => {
          return (
            <li
              key={idx}
              className="bg-white border-[1.5px] shadow border-gray-400 p-2 rounded-lg flex my-4"
            >
              {flow?.status && (
                <span>
                  <FcApproval size={28} className="ml-[-20px] mt-[-18px]" />
                </span>
              )}
              <div
                className={`w-6 ${selectLanguage == "ar" ? "ml-2" : "ml-0"}`}
              >
                <span className="icon">{flow.icon}</span>
              </div>

              <div className="ml-2">
                <p className="text-xl font-bold capitalize my-0">
                  {flow?.heading}
                </p>
                <p className="my-0">{flow?.content}</p>
                {flow.list.length > 0 && (
                  <ul className="list-[upper-roman] my-3">
                    {flow.list.length > 0
                      ? flow.list.map((l, index) => {
                          return <li key={index}>{l}</li>;
                        })
                      : null}
                  </ul>
                )}
                <p className="my-0">{flow?.last}</p>
                <button
                  className={`px-2 py-[6px] mt-2 rounded-lg w-full ${
                    selectLanguage == "ar" ? "sm:w-24" : "sm:w-36"
                  } bg-[#F4900C] text-white hover:bg-[#D2820A] hover:scale-95 duration-200 ease-in`}
                  onClick={() => {
                    navigate(flow?.route);
                  }}
                >
                  {selectLanguage == "ar" ? "اضغط هنا" : "Click here"}
                </button>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default VendorFlow;
