import i18next from "i18next";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaArrowCircleLeft } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ApiLoadingSpinner from "../../../components/utils/ApiLoadingSpinner";

const AdminAddCustomer = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [password, SetPassword] = useState("");
  const [btnAction, SetBtnAction] = useState(false);
  const [eye, seteye] = useState(true);
  const [passwordFi, setpasswordFi] = useState("password");
  const { t } = useTranslation();
  const selectLanguage = i18next.language;

  const Eye = () => {
    if (passwordFi === "password") {
      setpasswordFi("text");
      seteye(false);
    } else {
      setpasswordFi("password");
      seteye(true);
    }
  };

  const typePassword = (value) => {
    SetPassword(value);
    SetBtnAction(true);
    if (value.length < 1) {
      SetBtnAction(false);
    }
  };

  const user = JSON.parse(localStorage.getItem("admin"));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (data) => {
    setLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_APIURL}/admin/creatcustomer`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const response = await res.json();

    if (response?.status_code === 200) {
      toast.success(`${t("add_vendor_text")}`);
      setLoading(false);
      navigate("/admin/customers");
    } else {
      toast.error(response?.message || response?.err);
    }
    setLoading(false);
  };

  if (loading) {
    return <ApiLoadingSpinner />;
  }

  return (
    <div className="mb-20">
      <div className="max-w-2xl mx-auto border bg-white mt-5">
        <div className="card-title border-b p-4">
          <h3 className="text-lg font-medium">{t("add_customer")}</h3>
        </div>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div className="py-5 px-4">
            <div className="form-group mb-8">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]">{t("form_name")}</label>
                </div>
                <div className="col-span-8">
                  <input
                    type="text"
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder={t("form_name")}
                    {...register("cust_fullname", { required: true })}
                  />
                  {errors.admin_name?.type === "required" &&
                    "admin name name is required"}
                </div>
              </div>
            </div>
            <div className="form-group mb-8">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="vendorEmail">
                    {t("form_email")}
                  </label>
                </div>
                <div className="col-span-8">
                  <input
                    type="email"
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder={t("l_phone_m")}
                    {...register("cust_email", { required: true })}
                  />
                  {errors.planName?.type === "required" &&
                    "admin email name is required"}
                </div>
              </div>
            </div>

            <div className="form-group mb-8">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]">{t("Phone Number")}</label>
                </div>
                <div className="form-control rounded-none col-span-8">
                  <div>
                    <Controller
                      name="cust_contact"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <PhoneInput
                          {...field}
                          inputProps={{
                            name: "Contact",
                            required: true,
                          }}
                          containerclassName="max-w-md"
                          country={"sa"}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group mb-8">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]">
                    {t("New")} {t("l_p")}
                  </label>
                </div>
                <div className="col-span-8 relative">
                  <input
                    onChange={(e) => typePassword(e.target.value)}
                    type={passwordFi}
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder={`${t("New")} ${t("l_p")}`}
                    {...register("cust_password", { required: true })}
                  />
                  <i
                    onClick={Eye}
                    className={`fa ${
                      eye ? "fa-eye-slash" : "fa-eye"
                    } cursor-pointer absolute right-2 top-3`}
                  ></i>
                </div>
              </div>
            </div>

            <div className="form-group mb-8">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="vendorEmail">
                    {t("Plan")}
                  </label>
                </div>
                <div className="col-span-8">
                  <select className="w-full h-[40px] input input-bordered rounded">
                    <option>
                      {selectLanguage === "ar" ? "خطة مجانية" : "Free Plan"}
                    </option>
                    <option>
                      {selectLanguage === "ar" ? "بداية" : "Starter"}
                    </option>
                    <option>
                      {selectLanguage === "ar" ? "يتقدم" : "Advance"}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div className="form-group mb-8">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="vendorEmail">
                    {t("Domain Name Without Protocol")}
                  </label>
                </div>
                <div className="col-span-8">
                  <input
                    type="email"
                    placeholder="example.com"
                    className="w-full h-[40px] input input-bordered rounded"
                    // {...register("admin_email", { required: true })}
                  />
                  {errors.planName?.type === "required" &&
                    "admin email name is required"}
                </div>
              </div>
            </div>

            <div className="form-group mb-8">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="vendorEmail">
                    {t("Full Domain Name With Protocol")}
                  </label>
                </div>
                <div className="col-span-8">
                  <input
                    type="email"
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder="https://example.com"
                    // {...register("admin_email", { required: true })}
                  />
                  {errors.planName?.type === "required" &&
                    "admin email name is required"}
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mx-auto pb-5">
            <input
              className={`py-2 px-6 text-white rounded cursor-pointer ${
                btnAction ? "bg-slate-500" : "bg-primary"
              }`}
              type="submit"
              value="Save"
              disabled={btnAction}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminAddCustomer;
