import axios from "../components/utils/Interceptors"

const deleteDataById = async (url, id) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const baseUrl = `${process.env.REACT_APP_APIURL}/${url}/${id}`;

  const res = await axios.delete(`${baseUrl}`, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });

  const response = await res.data;

  return response;
};

export default deleteDataById;
