const languages = [
    {
        code: 'fr',
        name: 'Français',
        country_code: 'fr',
    },
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    }
]
export default languages;