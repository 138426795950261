import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowCircleLeft } from "react-icons/fa";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import DataTable from "react-data-table-component";
import { AiFillShopping } from "react-icons/ai";
import FilterComponent from "../../../components/utils/FilterComponent";
import { ShoppingBasket } from "@mui/icons-material";
import { TiDelete } from "react-icons/ti";
import { useTranslation } from "react-i18next";
import axios from "../../../components/utils/Interceptors"

const AddOrder = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [cart, setCart] = useState([]);
  const navigate = useNavigate();

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  let filteredItems = products.filter(
    (item) =>
      item.product_name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.category_name.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    setLoading(true);
    const getAllProducts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallproductwithfulldetails`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const response = await res.data;
      if (response.status_code === 200 && response.data) {
        setProducts(response.data);
      } else {
        setProducts([]);
      }

      setLoading(false);
    };

    // getAllParentCategories();
    getAllProducts();
  }, []);

  const setColor = (colorId, productId) => {
    if (colorId > 0) {
      filteredItems = products.map((product) => {
        if (product.product_id === productId) {
          return {
            ...product,
            color: colorId,
          };
        } else {
          return product;
        }
      });
      setProducts(filteredItems);
    }
  };
  const setSize = (sizeId, productId) => {
    if (sizeId > 0) {
      filteredItems = products.map((product) => {
        if (product.product_id === productId) {
          return {
            ...product,
            size: sizeId,
          };
        } else {
          return product;
        }
      });
      setProducts(filteredItems);
    }
  };

  const cartCollumns = [
    {
      name: `${t("add_new_product_name")}`,
      selector: (row) => row.product_name,
      sortable: true,
    },
    {
      name: `${t("all_product_dataTable2")}`,
      selector: (row) => row.product_price,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.qty,
      sortable: true,
    },

    {
      name: `${t("all_product_dataTable6")}`,
      sortable: true,
      cell: (item) => (
        <div className="flex gap-2 items-center">
          <span
            onClick={() => removeFromCart(item.product_id)}
            className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300"
            title={`${t("Delete")}`}
          >
            <TiDelete />
          </span>
        </div>
      ),
    },
  ];
  const columns = [
    {
      name: `${t("add_new_product_name")}`,
      selector: (row) => row.product_name,
      sortable: true,
    },
    {
      name: `${t("all_product_dataTable2")}`,
      selector: (row) => row.product_price,
      sortable: true,
    },
    {
      name: `${t("add_new_product_Colors")}`,
      selector: (row) => {
        return row.colors.length > 0 ? (
          <select
            onChange={(e) =>
              setColor(parseInt(e.target.value), parseInt(row.product_id))
            }
            className="input input-xs input-bordered rounded-none"
          >
            <option>{t("Select_Color")}</option>
            {row.colors.map((i) => (
              <option key={i.color_id} value={i.color_id}>
                {i.color_name}
              </option>
            ))}
          </select>
        ) : (
          "N/A"
        );
      },
    },
    {
      name: `${t("add_new_product_Sizes")}`,
      selector: (row) => {
        return row.sizes.length > 0 ? (
          <select
            onChange={(e) =>
              setSize(parseInt(e.target.value), parseInt(row.product_id))
            }
            className="input input-xs input-bordered rounded-none"
          >
            <option>{t("Select_Sizes")}</option>
            {row.sizes.map((i) => (
              <option key={i.size_id} value={i.size_id}>
                {i.size_name}
              </option>
            ))}
          </select>
        ) : (
          "N/A"
        );
      },
      sortable: true,
    },

    {
      name: `${t("all_product_dataTable6")}`,
      sortable: true,
      cell: (item) => (
        <form onSubmit={(e) => addToCart(e, item)}>
          <div className="flex gap-2 items-center">
            <input
              type="number"
              className="input input-bordered input-xs w-[50px] rounded-none focus:outline-none"
              name="qty"
              defaultValue="1"
            />

            <button
              type="submit"
              className="p-2 flex items-center outline border border-blue-500 hover:bg-blue-500 rounded-full text-blue-500 hover:text-white cursor-pointer duration-300 modal-button"
              title={`${t("Edit")}`}
            >
              <AiFillShopping />
            </button>
          </div>
        </form>
      ),
    },
  ];

  const addToCart = (e, item) => {
    e.preventDefault();
    setLoading(true);
    const qty = parseInt(e.target.qty.value);

    const find = cart.find((i) => i.product_id === item.product_id);
    if (find) {
      const newCart = cart.map((product) => {
        if (product.product_id === find.product_id) {
          return {
            ...product,
            qty: product.qty + qty,
          };
        } else {
          return product;
        }
      });
      setCart(newCart);
    } else {
      const product = {
        ...item,
        qty: qty,
      };
      setCart([...cart, product]);
    }
    // const newCart = [...cart, item];
    setLoading(false);
  };

  const removeFromCart = (id) => {
    const newCart = cart.filter((i) => i.product_id !== id);
    setCart(newCart);
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="mb-16">
      <div className="flex gap-3 mb-5 items-center">
        <h2 className="text-title text-lg md:text-2xl">
          {t("vendor_Add_new_Order")}
        </h2>
        <Link to={"/vendor/orders"}>
          <button className="btn btn-primary rounded btn-xs text-white flex justify-center items-center gap-x-2">
            <FaArrowCircleLeft size={15} />
            {t("add_new_product_back")}
          </button>
        </Link>
        <div className="ml-auto cursor-pointer">
          <label
            htmlFor="my-modal-4"
            className="py-2 px-4 bg-secondary rounded text-white cursor-pointer"
          >
            <ShoppingBasket className="text-white" />
            <span className="px-2">
              {" "}
              {cart.reduce((prev, item) => prev + item.qty, 0)}
            </span>
          </label>
        </div>
      </div>

      <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <DataTable
            columns={columns}
            data={filteredItems}
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            paginationPerPage={6}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            // selectableRows
            pagination
          />
        )}
      </div>

      <input type="checkbox" id="my-modal-4" className="modal-toggle" />

      <label htmlFor="my-modal-4" className="modal cursor-pointer">
        <div className="modal-box w-10/12 max-w-4xl md:ml-20">
          <h3 className="text-lg font-bold">{t("vendor_Cart_items")}</h3>
          <DataTable
            columns={cartCollumns}
            data={cart}
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            paginationPerPage={6}
            pagination
          />

          {cart.length > 0 && (
            <button
              onClick={() =>
                navigate("/vendor/checkout", { state: { cart: cart } })
              }
              className="btn btn-sm text-white btn-primary rounded"
            >
              {t("vendor_Checkout")}
            </button>
          )}
        </div>
      </label>
    </div>
  );
};

export default AddOrder;
