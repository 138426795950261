import React, { useEffect, useState } from "react";
import {
  AiFillInstagram,
  AiFillYoutube,
  AiFillMail,
  AiFillPhone,
  AiFillHome,
} from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { phoneNumberAutoFormat } from "../utils/NumberFormat";
import "./footer.css";
import { Link } from "react-router-dom";
import i18next from "i18next";
import axios from "axios";

const Footer = () => {
  const { t } = useTranslation();
  const [ganarel, setGanarel] = useState([]);
  const [contact, setContact] = useState();
  const customer = JSON.parse(localStorage.getItem("customer"));
  const selectLanguage = i18next.language;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}/admin/getgeneraldetails`, {
        headers: {
          Authorization: `Bearer ${customer ? customer.token : ""}`,
        },
      })
      .then((res) => {
        setGanarel(res.data.data[0]);
      });
  }, []);

  useEffect(() => {
    if (!ganarel.contact_no) {
      return;
    }
    const contact_number = phoneNumberAutoFormat(ganarel.contact_no);
    setContact(contact_number);
  }, [ganarel]);

  return (
    <div className="bg-controller z-10">
      <div className="w-full  ">
        <div className="pt-10">
          <footer className="container mx-auto max-w-[1280px] px-2 md:px-6 lg:px-10">
            <div className="">
              <div className="grid grid-cols-1 md:grid-cols-4 lg:gap-4">
                {/* <div className=""> */}
                <div className="flex md:justify-start justify-center">
                  <div className="flex flex-col items-center">
                    <img src={ganarel?.logo} className="w-60 md:w-32" alt="" />
                    <div className="social flex items-center gap-2 mt-4">
                      <span className="w-[24px] h-[24px] p-1 rounded-full bg-gray-100  shadow-md flex justify-center items-center cursor-pointer">
                        <a
                          href="https://www.instagram.com/ai_tech.com.sa/"
                          target="_blank"
                        >
                          <AiFillInstagram size={14} />
                        </a>
                      </span>
                      <span className="w-[24px] h-[24px] p-1 rounded-full bg-white shadow-md flex justify-center items-center cursor-pointer">
                        <a
                          href="https://www.youtube.com/channel/UC30Fl3vkUf6y44CBr6aOjig"
                          target="_blank"
                        >
                          <AiFillYoutube size={14} />
                        </a>
                      </span>
                      <span className="w-[24px] h-[24px] p-1 rounded-full bg-white shadow-md flex justify-center items-center cursor-pointer">
                        <a
                          href="https://www.facebook.com/AITECHTECHNOLOGY"
                          target="_blank"
                        >
                          <FaFacebookF size={14} />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="px-8 md:px-0 pt-5 md:pt-0 md:ml-[-16px] lg:ml-0">
                  {selectLanguage === "ar" ? (
                    <p className="text-justify w-full font-Roboto text-[#CCCCCC]  text-[15px] leading-6">
                      AI Tech، أفضل حلاً شاملاً للتجارة الإلكترونية. AI Tech هي
                      المنصة المفضلة لأصحاب المتاجر الذين يسعون للنجاح في عالم
                      التجارة الإلكترونية. ابدأ مع AI Tech اليوم واستفد من
                      إمكانيات النمو غير المحدودة لأعمالك عبر الإنترنت.
                    </p>
                  ) : (
                    <p className="text-justify w-full font-Roboto text-[#CCCCCC] text-[15px] leading-6">
                      AI Tech, the ultimate all-in-one e-commerce solution. AI
                      Tech is the platform of choice for Store Owners looking to
                      succeed in the world of e-commerce. Get started with AI
                      Tech today and unlock unlimited growth potential for your
                      online business.{" "}
                    </p>
                  )}
                </div>
                {/* </div> */}
                <div
                  className={`mt-5 md:mt-0 lg:ml-16 ${
                    selectLanguage === "ar" ? "px-8 md:px-0 md:mr-14" : "ml-8"
                  }`}
                >
                  <h6 className="text-lg mb-2 md:mb-5 text-primary font-semibold underline ">
                    {t("Quick Links")}
                  </h6>
                  <span
                    className={`${
                      selectLanguage === "ar"
                        ? "md:justify-center items-start"
                        : ""
                    } flex flex-col gap-2`}
                  >
                    <Link
                      to="/plans-pricing"
                      className={`md:${
                        selectLanguage === "ar" ? "ml-0" : "ml-[90px]"
                      } hover:text-primary md: text-[#CCCCCC] duration-100 font-opensans bg-transparent`}
                    >
                      {t("plansPrice")}
                    </Link>
                    <a
                      className={`md:${
                        selectLanguage === "ar" ? "ml-0" : "ml-[90px]"
                      } hover:text-primary text-[#CCCCCC] duration-100 font-opensans bg-transparent`}
                      href="https://ai-tech.com.sa/blog"
                      target="_blank"
                    >
                      {t("blogs")}
                    </a>
                    <Link
                      to="/privacy-policy"
                      className={`md:${
                        selectLanguage === "ar" ? "ml-0" : "ml-[90px]"
                      } hover:text-primary text-[#CCCCCC] duration-100 font-opensans bg-transparent`}
                    >
                      {selectLanguage === "ar"
                        ? "سياسة الخصوصية"
                        : "Privacy Policy"}
                    </Link>
                    <Link
                      to="/Agrement"
                      className={`md:${
                        selectLanguage === "ar" ? "ml-0" : "ml-[90px]"
                      } hover:text-primary text-[#CCCCCC] duration-100 font-opensans bg-transparent`}
                    >
                      {selectLanguage === "ar"
                        ? "اتفاقية الاستخدام"
                        : "Usage Agreement"}
                    </Link>
                  </span>
                </div>

                <div className="px-8 mt-5 md:mt-0 md:px-5 text-gray-500">
                  <h6 className="text-lg mb-2 md:mb-5 text-primary font-semibold underline ">
                    {t("HNav_contact")}
                  </h6>
                  {ganarel?.email && (
                    <div
                      title="Email"
                      className="flex gap-2 my-2 text-[#CCCCCC] hover:text-primary cursor-pointer"
                    >
                      <AiFillMail size={20} />
                      <span className="paragaraph-font text-sm">
                        {ganarel?.email}
                      </span>
                    </div>
                  )}
                  {ganarel?.contact_no && (
                    <div
                      title="Phone"
                      className="flex gap-2 my-5 text-[#CCCCCC] hover:text-primary cursor-pointer"
                    >
                      <AiFillPhone size={20} />
                      <span className="paragaraph-font text-sm">{contact}</span>
                    </div>
                  )}
                  {/* {ganarel?.address && (
                    <div
                      title="Address"
                      className="flex gap-2 text-[#CCCCCC] hover:text-primary cursor-pointer"
                    >
                      <AiFillHome size={30} />
                      <span className="paragaraph-font text-sm">
                        {" "}
                        {selectLanguage === "ar"
                          ? "العليا، الرياض 12213، المملكة العربية السعودية"
                          : `${ganarel?.address}`}
                      </span>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </footer>
        </div>
        <div className="px-1 md:px-0 pt-10 pb-5">
          <p className="text-center text-[#CCCCCC] text-sm ">
            {t("copy_write")} {new Date().getFullYear()}, {t("copy_write1")} @{" "}
            <a
              className="text-[#CCCCCC]"
              href="https://ai-tech.com.sa"
              target="_black"
            >
              ai-tech.com.sa
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
