import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../utils/SectionTitle";
import { useTranslation } from "react-i18next";
import Div from "../utils/Div";
import i18next from "i18next";
import axios from "axios";

const Pricing = () => {
  const { t } = useTranslation();
  const [plans, setplans] = useState([]);
  const customer = JSON.parse(localStorage.getItem("customer"));
  const [perMonth, setPerMonth] = useState([]);
  const [peryear, setPeryear] = useState([]);
  const [btnClick, setBtnClink] = useState(1);

  const selectLanguage = i18next.language;
  useEffect(() => {
    const getAllPlans = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallplan`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${customer?.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.status_code === 200) {
        const filterItem = response.data.filter(
          (item) => !(item.plan_id === 9)
        );
        const perMonth = filterItem.filter((item) => item.no_of_days === 30);
        setPerMonth(perMonth);
        const peryear = filterItem.filter((item) => !(item.no_of_days === 30));
        setPeryear(peryear);
        setplans(perMonth);
      }
    };
    getAllPlans();
  }, []);

  const handleBtnClick = (value) => {
    setBtnClink(value);
    if (value === 1) {
      setplans(perMonth);
    }
    if (value === 2) {
      setplans(peryear);
    }
  };

  return (
    <section id="plans" className="py-8 px-2 bg-[#f5f5f5] le">
      <Div>
        <div>
          <SectionTitle page={"pricing"} />
          <div className="flex justify-center mt-10">
            <div className="btn-group border-4 rounded-xl border-[#236260]">
              <span
                onClick={() => handleBtnClick(1)}
                className={`btn border-none ${
                  btnClick === 1
                    ? "text-white hover:text-primary bg-[#236260] hover:bg-[#236260] disabled"
                    : "bg-white text-[#236260] hover:bg-primary hover:text-white"
                }`}
              >
                {t("Monthly")}
              </span>
              <span
                onClick={() => handleBtnClick(2)}
                className={`btn border-none ${
                  btnClick === 2
                    ? "text-white hover:text-primary bg-[#236260] hover:bg-[#236260] disabled"
                    : "bg-white text-[#236260] hover:bg-primary hover:text-white"
                }`}
              >
                {t("Yearly")}
              </span>
            </div>
          </div>
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12 gap-y-16 mt-10">
            {plans.length > 0 ? (
              <>
                {plans.map((plan, idx) => (
                  <div
                    key={idx}
                    className={`${plan.plan_price === -1 && "hidden"} ${
                      idx === 1 && "bg-[#236260]"
                    } px-3 pb-3 pt-16 border rounded-2xl shadow-lg bg-white `}
                  >
                    <p className="text-primary text-center text-[16px] font-poppin font-bold mt-[-12px]">
                      {idx === 1 && selectLanguage === "ar"
                        ? "أفضل مبيعًا"
                        : idx === 1 && selectLanguage != "ar"
                        ? "Best Seller"
                        : ""}
                    </p>
                    <p
                      className={`text-[24px] uppercase ${
                        idx === 1 ? "text-white" : "text-[#2E2E2E]"
                      }  font-bold text-center `}
                    >
                      {plan.plan_name}
                    </p>

                    <div className="price mb-2 gap-x-1 items-center justify-center">
                      <p
                        className={`text-[30px] uppercase ${
                          idx === 1 ? "text-white" : "text-[#236260]"
                        } font-bold text-center`}
                      >
                        {plan.plan_price} ر.س
                      </p>
                      {plan.no_of_days === 30 && (
                        <p
                          className={`text-[16px] px-2  text-center ${
                            idx === 1 ? "text-white" : "text-[#2E2E2E]"
                          }`}
                        >
                          {t("Per")} {t("Month")}{" "}
                        </p>
                      )}
                      {plan.no_of_days === 365 && (
                        <p
                          className={`text-[16px] px-2  text-center ${
                            idx === 1 ? "text-white" : "text-[#2E2E2E]"
                          }`}
                        >
                          {t("Per")} {t("Year")}{" "}
                        </p>
                      )}
                      {plan.no_of_days === 730 && (
                        <p
                          className={`text-[16px] px-2  text-center ${
                            idx === 1 ? "text-white" : "text-[#2E2E2E]"
                          }`}
                        >
                          {t("Per")} {t("Two")} {t("Year")}{" "}
                        </p>
                      )}
                    </div>

                    <div className="pt-10 pb-5 w-4/5 mx-auto">
                      <ul
                        className={`text-sm flex flex-col leading-3 justify-center gap-4 px-2 ${
                          idx === 1 ? "text-white" : "text-[#2E2E2E]"
                        }`}
                      >
                        <li className="flex gap-x-6 items-center justify-center">
                          <p className="text-center">
                            {idx == 2
                              ? selectLanguage === "ar"
                                ? "عدد غير محدود من المنتجات"
                                : "Unlimited number of products"
                              : `${plan.customer_limit} ${t("cus_limit")}`}
                          </p>
                        </li>
                        <li className="flex flex-auto gap-x-6 items-center  justify-center">
                          <p className="text-center">
                            {idx == 2
                              ? selectLanguage === "ar"
                                ? "حد غير محدود للعملاء"
                                : "Unlimited Customer Limit"
                              : `${plan.product_limit} ${t("product_limit")}`}
                          </p>
                        </li>
                        <li className="flex gap-x-6 items-center justify-center">
                          <p>
                            {selectLanguage === "ar"
                              ? "لا عمولة على المبيعات"
                              : "No commission on sales"}
                          </p>
                        </li>
                        <li className="flex gap-x-6 items-center justify-center">
                          <p>
                            {selectLanguage === "ar"
                              ? "دعم جميع أنواع المنتجات"
                              : "Support all kinds of products"}
                          </p>
                        </li>
                        <li className="flex gap-x-6 items-center justify-center">
                          <p>
                            {selectLanguage === "ar"
                              ? "شركات الشحن المتعددة"
                              : "Multiple shipping companies"}
                          </p>
                        </li>
                        <li className="flex gap-x-2 items-center justify-center">
                          <p>
                            {selectLanguage === "ar"
                              ? "تفعيل الدفع الالكتروني"
                              : "Activate electronic payment"}
                          </p>
                        </li>
                        <li className="flex gap-x-2 items-center justify-center">
                          <p>
                            {selectLanguage === "ar"
                              ? "كوبونات خصم"
                              : "Discount coupons"}
                          </p>
                        </li>
                        <li className="flex gap-x-2 items-center justify-center">
                          <p>
                            {idx === 2
                              ? selectLanguage === "ar"
                                ? "تحسين محركات البحث غير محدود"
                                : "Unlimited SEO"
                              : selectLanguage === "ar"
                              ? "تحسين محركات البحث المحدودة"
                              : "Limited SEO"}
                          </p>
                        </li>
                        <li className="flex gap-x-2 items-center justify-center">
                          <p>
                            {idx === 0
                              ? selectLanguage === "ar"
                                ? "الشحن - تورود"
                                : "SHIPPING - TOROD"
                              : selectLanguage === "ar"
                              ? "الشحن - TOROD - التنشيط"
                              : "SHIPPING - TOROD - Activate"}
                          </p>
                        </li>
                        <li className="flex gap-x-2 items-center justify-center">
                          <p className="text-center leading-5">
                            {idx === 0
                              ? selectLanguage === "ar"
                                ? "طريقة الدفع - مويسر"
                                : "PAYMENT METHOD - MOYASAR"
                              : selectLanguage === "ar"
                              ? "طريقة الدفع - الميسر - تابي وتمارا"
                              : "PAYMENT METHOD - MOYASAR - Tabby and Tamara"}
                          </p>
                        </li>
                        <li className="flex gap-x-2 items-center justify-center">
                          <p className="text-center leading-5">
                            {idx === 2
                              ? selectLanguage === "ar"
                                ? "تخصيص التصميم من خلال Custom CSS & JS"
                                : "Customize the design through Custom CSS & JS"
                              : selectLanguage === "ar"
                              ? "لا يمكنك تخصيص التصميم من خلال Custom CSS & JS"
                              : "No Customize the design through Custom CSS & JS"}
                          </p>
                        </li>
                        <li className="flex gap-x-2 items-center justify-center">
                          <p>
                            {selectLanguage === "ar"
                              ? "شهادة SSL مجانية"
                              : "Free SSL certificate"}{" "}
                          </p>
                        </li>
                        <li className="flex gap-x-2 items-center justify-center">
                          <p>
                            {selectLanguage === "ar" ? "التقارير" : "Reports"}{" "}
                          </p>
                        </li>
                        <li className="flex gap-x-2 items-center justify-center">
                          <p>
                            {idx === 2
                              ? selectLanguage === "ar"
                                ? "حجز رابط خاص (نطاق)"
                                : "Reserve a private link (domain)"
                              : selectLanguage === "ar"
                              ? "لا يوجد حجز رابط خاص (نطاق)"
                              : "No Reserve a private link (domain)"}
                          </p>
                        </li>
                        <li className="flex gap-x-2 items-center justify-center">
                          <p>
                            {selectLanguage === "ar"
                              ? "تصدير واستيراد المنتجات"
                              : "Export and import of products"}{" "}
                          </p>
                        </li>
                        <li className="flex gap-x-2 items-center justify-center">
                          <p>
                            {selectLanguage === "ar"
                              ? "تحديث المنتجات باستخدام Excel"
                              : "Updating products using Excel"}{" "}
                          </p>
                        </li>
                        <li className="flex gap-x-2 items-center justify-center">
                          <p>
                            {idx === 0
                              ? selectLanguage === "ar"
                                ? "لا إدارة العملاء"
                                : "No Customer Management"
                              : selectLanguage === "ar"
                              ? "ادارة الزبائن"
                              : "Customer Management"}
                          </p>
                        </li>
                        <li className="flex gap-x-2 items-center justify-center">
                          <p className="text-center leading-5">
                            {idx === 0
                              ? selectLanguage === "ar"
                                ? "لا يوجد دعم لتثبيت أعمالي في Google"
                                : "NO Google MY BUSINESS INSTALL SUPPORT"
                              : selectLanguage === "ar"
                              ? "جوجل دعم تثبيت أعمالي"
                              : "Google MY BUSINESS INSTALL SUPPORT"}
                          </p>
                        </li>
                      </ul>
                      <div className="text-center pt-8 pb-2">
                        {idx === 1 ? (
                          <Link
                            to="/vendor/register"
                            className="py-3 text-[16px] px-4 hover:bg-white duration-200 border-2 border-primary bg-primary  text-white rounded font-medium"
                          >
                            {t("Start Free Trial")}
                          </Link>
                        ) : (
                          <Link
                            to="/vendor/register"
                            className="py-3 text-[16px] px-4 bg-white text-[#236260] border-4 border-[#236260] rounded font-medium"
                          >
                            {t("Start Free Trial")}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <> {t("p_price_no")} </>
            )}
          </div>
        </div>
      </Div>
    </section>
  );
};

export default Pricing;
