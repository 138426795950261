import React, { useContext } from "react";
import { domainProvider } from "../../../contexts/DomainContext";

function ProductHeader(props) {
  const url = window.location.href;
  const urls = url.split("/");
  const { customdomain } = useContext(domainProvider);

  return (
    <>
      <div className="bg-[#F9F9F9] py-16">
        <div className="container max-w-[1280px] mx-auto flex flex-col sm:flex-row justify-between">
          {/* <div className="medium:px-25"> */}
            <div className="flex flex-col justify-center items-center sm:items-start mb-6 sm:mb-0 w-full pl-8 xl:px-[4.5rem]">
              <p className="text-4xl text-gray-700 font-medium">{props.header}</p>
              <p className="text-base mt-2 lg:mt-5 capitalize">Home / {customdomain === "aitech.com.sa" ? urls[4] : urls[3]}</p>
            </div>

            {/* <div className="flex flex-col justify-center items-center">
            <p className="text-lg uppercase">Aitech</p>
            <p className="text-3xl lg:text-5xl italic text-[#A27A34] text-center">
              Store Collections
            </p>
            <p className="text-[#236260] text-lg font-semibold">
              {customdomain === "aitech.com.sa"
                ? `https://${urls[2]}/${urls[3]}`
                : `https://${urls[2]}`}{" "}
            </p>
          </div> */}
          </div>
        {/* </div> */}
      </div>
    </>
  );
}

export default ProductHeader;
