import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { FaEdit } from "react-icons/fa";
import { usePopper } from "react-popper";
import FilterComponent from "../../../components/utils/FilterComponent";
import { t } from "i18next";

const AllDomain = () => {
  const [selectedData, setSelectedData] = useState();
  const user = JSON.parse(localStorage.getItem("admin"));
  const [loading, setLoading] = useState(false);
  const [domains, setDomains] = useState([]);
  const [action, setAction] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

  let filteredItems;
  if (filterText) {
    filteredItems = domains?.filter((item) =>
      item.domain_name?.toLowerCase().includes(filterText?.toLowerCase())
    );
  } else {
    filteredItems = domains;
  }

  const getAllDomains = async (status_id) => {
    let url = `${process.env.REACT_APP_APIURL}/admin/getalldomain`;
    const res = await fetch(url, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });
    const response = await res.json();

    if (response.status_code === 200) {
      setDomains(response.data.reverse());
    } else {
      setDomains([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllDomains();
  }, []);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="flex items-center  gap-2 flex-wrap my-5">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="Filter by Domain name"
          setFilterText={setFilterText}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const handleChange = (state) => {
    setSelectedData(state.selectedRows);
  };

  function handleAction() {
    setAction(!action);
  }

  const columns = [
    {
      name: "Domain Name",
      selector: (row) => row.domain_name,
      sortable: true,
    },

    {
      name: "Full Domain",
      selector: (row) => row.full_domain_name,
      sortable: true,
    },

    {
      name: "Store Id",
      selector: (row) => row.store_id,
      sortable: true,
    },
    // {
    //     name: 'User',
    //     selector: row => row.user,
    //     sortable: true,
    // },
    {
      name: "Status",
      selector: (row) => row.is_active,
      sortable: true,
      cell: (record) => {
        return (
          <>
            <span>{record.is_active ? "Active" : "Pending"}</span>
          </>
        );
      },
    },
    {
      name: "Created At",
      selector: (row) => row.created_at,
      sortable: true,
    },
    {
      name: "Action",
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <>
            <div className="dropdown dropdown-left dropdown-bottom z-20 px-2">
              <label tabIndex={0}>
                <span
                  className="p-2 flex items-center border border-primary bg-primary rounded-full text-white hover:bg-white hover:text-primary cursor-pointer duration-300 shadow-md"
                  onClick={handleAction}
                  title="View"
                >
                  <FaEdit />
                </span>
              </label>
              {action && (
                <ul
                  className={`bg-white shadow rounded border w-40 py-2 top-20 menu menu-compact dropdown-content`}
                  tabIndex={0}
                >
                  <li className="text-[#282828] hover:bg-slate-50 normal-case px-4 py-2 text-sm cursor-pointer">
                    Publish
                  </li>
                  <li className="text-[#282828] hover:bg-slate-50 normal-case px-4 py-2 text-sm cursor-pointer">
                    Suspend
                  </li>
                  <li className="text-[#282828] hover:bg-slate-50 normal-case px-4 py-2 text-sm cursor-pointer">
                    Move to trash
                  </li>
                </ul>
              )}
            </div>
          </>
        );
      },
    },
  ];
  return (
    <>
      <h2 className="text-title text-lg md:text-2xl pb-2">{t("All Domains")}</h2>
      <div className="dashboard-box">
        <DataTable
          columns={columns}
          data={filteredItems}
          // selectableRows
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          onSelectedRowsChange={handleChange}
        />
      </div>
    </>
  );
};

export default AllDomain;
