import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import { AiFillEdit } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import deleteDataById from "../../../hooks/admin/deleteDataById";
import { useTranslation } from "react-i18next";

const AllRoles = () => {
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const user = JSON.parse(localStorage.getItem("admin"));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAllRole = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_APIURL}/admin/getallrole`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.json();
      if (response.data) {
        setRoles(response.data);
      }
    };

    getAllRole();
    setLoading(false);
  }, []);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  let filteredItems = roles?.filter(
    (item) =>
      item.role_name &&
      item.role_name.toLowerCase().includes(filterText.toLowerCase())
  );

  const columns = [
    // {
    //     name: 'ID',
    //     selector: row => row.role_id,
    //     sortable: true,
    // },
    {
      name: `${t("Role_Name")}`,
      selector: (row) => row.role_name,
      sortable: true,
    },

    {
      name: `${t("Options")}`,
      sortable: true,
      cell: (item) => (
        <div className="flex gap-2">
          <Link
            to={`/admin/edit-roles/${item.role_id}`}
            className="p-2 flex items-center border border-blue-500 hover:bg-blue-500 rounded-full text-blue-500 hover:text-white cursor-pointer duration-300 modal-button"
            title={t("Edit")}
          >
            <AiFillEdit />
          </Link>
          <span
            onClick={() => deleteItem(item.role_id)}
            className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300"
            title={t("Delete")}
          >
            <TiDelete />
          </span>
        </div>
      ),
    },
  ];

  if (loading) {
    return <LoadingSpinner />;
  }

  const deleteItem = (id) => {
    confirmAlert({
      title: `${t("delete_alert_title")}`,
      message: `${t("delete_alert_des")}`,
      buttons: [
        {
          label: `${t("yes")}`,
          onClick: async () => {
            setLoading(true);

            const response = await deleteDataById("admin/deleterole", id);

            if (response.status_code === 200) {
              const newData = roles.filter((item) => item.role_id !== id);
              setRoles(newData);
              filteredItems = filteredItems.filter(
                (item) => item.role_id !== id
              );
            }

            setLoading(false);
          },
        },
        {
          label: `${t("no")}`,
          onClick: () => {},
        },
      ],
    });
  };
  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="text-title text-lg md:text-2xl pb-2">
          {t("All_Roles")}
        </h2>
        <Link
          to="/admin/add-roles"
          className="py-2 text-sm px-4 bg-primary text-white rounded font-normal"
        >
          {t("Add_Roles")}
        </Link>
      </div>
      <div className="my-5">
        <DataTable columns={columns} data={filteredItems} pagination />
      </div>
    </>
  );
};

export default AllRoles;
