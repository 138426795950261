import whatsapp from "../../assets/icons/whatsapp.svg";
import { RiWhatsappFill } from "react-icons/ri";
import "./style.css";

export default function Whatsapp() {
  return (
    <>
      <a
        href="https://wa.me/966559604635?text=Ask query"
        id="whatsapp"
        target="_blank"
      >
        <div className="fixed bottom-4 right-6 bg-none flex flex-row items-center gap-x-1">
          <div
            title="Whatsapp"
            className="flex justify-center items-center w-12 h-12 rounded-full border-2 border-green-700 text-green-700 hover:scale-110 shadow-md hover:shadow-lg transition-all cursor-pointer"
          >
            <span className="text-green-600 hover:text-green-700">
              <RiWhatsappFill size={42} />
            </span>
          </div>
          <span className="text-green-700 font-bold text-lg">Whatsapp now</span>
        </div>
      </a>
    </>
  );
}
