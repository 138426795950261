const getAllAdmin = async (url) => {
  const user = JSON.parse(localStorage.getItem("admin"));
  const baseUrl = `${process.env.REACT_APP_APIURL}/${url}`;

  const res = await fetch(`${baseUrl}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user?.token}`,
    },
  });

  const response = await res.json();

  return response;
};

export default getAllAdmin;
