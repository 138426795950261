import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import err from "../../../assets/err.png";
import { FaArrowCircleLeft } from "react-icons/fa";
import axios from "../../../components/utils/Interceptors";

const ThankyouUp = () => {
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const user = JSON.parse(localStorage.getItem("user"));
  const packageDetails = JSON.parse(localStorage.getItem("vendorPackagesInfo"));
  const [status, setStatus] = useState();

  useEffect(() => {
    const id = searchParams.get("id");
    const status = searchParams.get("status");
    setStatus(status);

    if (status === "paid") {
      const updateOrder = async () => {
        const data = {
          plan_id: packageDetails?.plan_id,
          payment_mode: 2,
          payment_status: status,
          transaction_id: id,
          status: 1,
        };
        const res = await axios.post(
          `${process.env.REACT_APP_APIURL}/vendor/upgradeplan`,
          JSON.stringify(data),
          {
            headers: {
              "Content-type": "Application/json",
              Authorization: `Bearer ${user ? user.token : ""}`,
            },
          }
        );
        let response = await res.data;
        if (response.status_code === 200) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
        localStorage.removeItem("vendorPackagesInfo");
        setLoading(false);
      };
      updateOrder();
    }
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div className="flex gap-x-4">
        <h2 className="text-title text-lg md:text-2xl pb-2 uppercase">
          Package Update massage
        </h2>
        <Link to={"/vendor/dashboard"}>
          <button className="btn btn-primary btn-xs text-white mb-5 flex justify-center items-center gap-x-2 mt-1">
            <FaArrowCircleLeft size={15} />
            Go To Dashboard
          </button>
        </Link>
      </div>
      <div className="border p-10 my-10 mx-auto">
        {status === "paid" ? (
          <div className="flex justify-center my-5">
            <img
              src="https://megamart.pythonistindia.com/frontend/assets/images/thankyou-img.png"
              alt="img"
            />
          </div>
        ) : (
          <div className="flex justify-center my-5">
            <img src={err} alt="img" />
          </div>
        )}

        {status === "paid" ? (
          <div className="thankyou-content text-center">
            <div>
              <p className="text-xl font-bold">
                Thank you for your Subscription!
              </p>
            </div>
          </div>
        ) : (
          <div className="thankyou-content text-center">
            <div>
              <p className="text-xl font-bold">Your Order Not Complete!</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThankyouUp;
