import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import ApiLoadingSpinner from "../../../components/utils/ApiLoadingSpinner";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const AdminProfile = () => {
  const userInfo = JSON.parse(localStorage.getItem("admin"));
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [password, SetPassword] = useState("");
  const [btnAction, SetBtnAction] = useState(false);
  const [eye, seteye] = useState(true);
  const [passwordFi, setpasswordFi] = useState("password");
  const { t } = useTranslation();

  const typePassword = (value) => {
    SetPassword(value);
    SetBtnAction(true);
    if (value.length < 1) {
      SetBtnAction(false);
    }
  };

  const Eye = () => {
    if (passwordFi === "password") {
      setpasswordFi("text");
      seteye(false);
    } else {
      setpasswordFi("password");
      seteye(true);
    }
  };

  const checkingPassword = (value) => {
    setErr(true);
    if (value === password) {
      setErr(false);
      SetBtnAction(false);
      setSuccess(true);
    } else {
      setSuccess(false);
      if (value.length < 1) {
        setErr(false);
      }
    }
  };

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  useEffect(() => {
    const getSinglePlan = async () => {
      if (userInfo) {
        setValue("admin_contact", userInfo.admin_contact);
        setValue("admin_email", userInfo.admin_email);
        setValue("admin_name", userInfo.admin_name);
      }
    };
    getSinglePlan();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    // toast.success("Updated Profile!");
    const userInfoData = new FormData();
    if (images[0]?.file) {
      userInfoData.append("profile_image", images[0]?.file);
    }
    if (password && password.length >= 8) {
      userInfoData.append("admin_password", password);
    } else if (password.length === 0) {
    } else {
      setLoading(false);
      return toast.error("Password Must 8 or more");
    }
    userInfoData.append("admin_name", data.admin_name);
    userInfoData.append("admin_email", data.admin_email);
    userInfoData.append("admin_contact", data.admin_contact);

    const res = await fetch(
      `${process.env.REACT_APP_APIURL}/admin/updateprofile`,
      {
        method: "PATCH",
        body: userInfoData,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );
    const response = await res.json();

    if (response?.status_code === 200) {
      toast.success("Profile updated Successful");
      userInfo.admin_contact = response?.data?.admin_contact;
      userInfo.admin_email = response?.data?.admin_email;
      userInfo.admin_name = response?.data?.admin_name;
      userInfo.profile_image = response?.data?.profile_image;
      localStorage.setItem("admin", JSON.stringify(userInfo));
    } else {
      toast.error("Something went Wrong");
    }
    setLoading(false);
  };

  const selectLanguage = i18next.language;

  if (loading) {
    return <ApiLoadingSpinner />;
  }

  return (
    <div className="mb-20">
      <div className="max-w-xl mx-auto border bg-white mt-5">
        <div className="card-title border-b p-4">
          <h3 className="text-base font-medium">{t("Profile_Info")}</h3>
        </div>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div className="py-5 px-4">
            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]">{t("form_name")}</label>
                </div>
                <div className="col-span-8">
                  <input
                    type="text"
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder="Admin Name"
                    {...register("admin_name", { required: true })}
                  />
                  {errors.admin_name?.type === "required" &&
                    "admin name name is required"}
                </div>
              </div>
            </div>
            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="vendorEmail">
                    {t("form_email")}
                  </label>
                </div>
                <div className="col-span-8">
                  <input
                    type="email"
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder="Admin email"
                    {...register("admin_email", { required: true })}
                  />
                  {errors.planName?.type === "required" &&
                    "admin email name is required"}
                </div>
              </div>
            </div>

            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="vendorPhone">
                    {t("form_phone")}
                  </label>
                </div>
                <div className="col-span-8">
                  <input
                    type="number"
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder="+12345679"
                    {...register("admin_contact", { required: true })}
                  />
                  {errors.planName?.type === "required" &&
                    "admin email name is required"}
                </div>
              </div>
            </div>
            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]">
                    {t("New")} {t("l_p")}
                  </label>
                </div>
                <div className="col-span-8 relative">
                  <input
                    onChange={(e) => typePassword(e.target.value)}
                    type={passwordFi}
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder={`${t("New")} ${t("l_p")}`}
                    name="new-password"
                  />
                  <i
                    onClick={Eye}
                    className={`fa ${
                      eye ? "fa-eye-slash" : "fa-eye"
                    } cursor-pointer absolute right-2 top-3`}
                  ></i>
                  <small className="text-[10px]">
                    {selectLanguage == "ar"
                      ? "اتركها فارغة إذا كنت لا ترغب في تغيير كلمة المرور"
                      : "Leave empty if you don't want to change password"}
                  </small>
                </div>
              </div>
            </div>
            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label
                    className="text-[13px]"
                    htmlFor="vendorConfirmPassword"
                  >
                    {t("l_c_p_p")}
                  </label>
                </div>
                <div className="col-span-8">
                  <input
                    onChange={(e) => checkingPassword(e.target.value)}
                    type={passwordFi}
                    name="confirm-password"
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder={`${t("l_c_p_p")}`}
                  />

                  <small className="text-[10px]">
                    {selectLanguage == "ar"
                      ? "اتركها فارغة إذا كنت لا ترغب في تغيير كلمة المرور"
                      : "Leave empty if you don't want to change password"}
                  </small>
                  {err && (
                    <>
                      <br />
                      <small className="text-[12px] text-red-500">
                        {t("Password Not Match")}
                      </small>
                    </>
                  )}
                  {success && (
                    <>
                      <br />
                      <small className="text-[12px] text-green-500">
                        {selectLanguage == "ar"
                          ? "تم مطابقة كلمة المرور"
                          : "Password Matched"}
                      </small>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="vendorPhoto">
                    {t("Photo")}
                  </label>
                </div>
                <div className="col-span-8">
                  <ImageUploading
                    value={images}
                    onChange={onChange}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <p
                          className="border text-sm cursor-pointer py-2 px-3"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          {selectLanguage == "ar"
                            ? "انقر أو اسحب هنا لتحميل الصورة"
                            : "Click or Drop here to Upload Image"}
                        </p>
                        &nbsp;
                        {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                        {images.length > 0
                          ? imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <div className="image-item__btn-wrapper">
                                  {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                                  <button
                                    className="text-red-600"
                                    onClick={() => onImageRemove(index)}
                                  >
                                    X
                                  </button>
                                </div>
                                <img
                                  className="w-20 h-20"
                                  src={image["data_url"]}
                                  alt=""
                                />
                              </div>
                            ))
                          : ""}
                      </div>
                    )}
                  </ImageUploading>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mx-auto pb-5">
            <input
              className={`py-1 px-3 text-white rounded cursor-pointer ${
                btnAction ? "bg-slate-500" : "bg-primary"
              }`}
              type="submit"
              value="Save"
              disabled={btnAction}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminProfile;
