import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import postData from "../../../hooks/postData";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaArrowCircleLeft } from "react-icons/fa";
import axios from "../../../components/utils/Interceptors"

const AddCoupon = () => {
  const { t } = useTranslation();
  const [couponType, setCouponType] = useState("Amount");
  const [dateRange, setDateRange] = useState([]);
  const [startDate, endDate] = dateRange;
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const getAllProducts = async () => {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallproduct`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.status_code === 200 && response.data) {
        const data = response.data.map(({ product_id, product_name }) => ({
          label: product_name,
          value: product_id,
        }));
        setProducts(data);
      }
      setLoading(false);
    };
    getAllProducts();
  }, []);

  const [error, setError] = useState("");
  const [showErr, setShowErr] = useState(false);
  const onSubmit = async (data) => {
    data["from_date"] = startDate;
    data["to_date"] = endDate;
    const minAmount = data.minimum_purchase_amount;
    const maxAmount = data.maximum_discount;
    const couponType = data.coupon_type;

    if (couponType === "Amount" && parseInt(maxAmount) > parseInt(minAmount)) {
      setShowErr(true);
      setError("Discount value can't be greater than minimum purchase price");

      return;
    } else if (couponType === "Percentage" && parseInt(maxAmount) > 100) {
      setShowErr(true);
      setError("Discount percenatge can't exceed more than 100%");

      return;
    } else {
      setShowErr(false);
      setError("");
      const responseInfo = await postData("/vendor/createcoupon", data);
      if (responseInfo.status_code === 200) {
        toast.success(responseInfo.message);
        reset();
      } else {
        toast.error(responseInfo.messsage || responseInfo.err);
      }
    }
  };

  const toggleCouponForm2 = (e) => {
    setCouponType(e.target.value);
  };

  return (
    <div className="mb-20">
      <div className="max-w-xl mx-auto border bg-white">
        <div className="card-title border-b p-4">
          <div className="flex gap-x-4">
            <h3 className="text-base font-medium">{t("add_coupon_info")}</h3>
            <Link to={"/vendor/coupons"}>
              <button className="btn btn-primary btn-xs text-white mb-5 flex justify-center items-center gap-x-2 mt-1">
                <FaArrowCircleLeft size={15} />
                {t("add_new_product_back")}
              </button>
            </Link>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pb-5 pt-2 px-4">
            <div>
              <div className="card-title border-b p-4">
                <h3 className="text-base font-medium">
                  {t("add_coupon_for_total_base")}
                </h3>
              </div>

              <div className="my-5">
                <div className="form-group mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-12">
                    <div className="col-span-3">
                      <label className="text-[13px]" htmlFor="couponCode">
                        {t("add_coupon_code")}
                        <span className="text-error">*</span>
                      </label>
                    </div>
                    <div className="col-span-9">
                      <input
                        className="w-full h-[40px] input input-bordered rounded"
                        placeholder={`${t("add_coupon_code")}`}
                        {...register("coupon_code", { required: true })}
                      />
                      {errors.coupon_code?.type === "required" && (
                        <span className="label-text-alt text-red-500 font-Roboto my-1">
                          {t("coupon_error1")}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-12">
                    <div className="col-span-3">
                      <label className="text-[13px]" htmlFor="couponType">
                        {t("add_coupon_type")}
                        <span className="text-error">*</span>
                      </label>
                    </div>
                    <div className="col-span-9">
                      <select
                        {...register("coupon_type")}
                        onChange={toggleCouponForm2}
                        className="w-full h-[40px] input input-bordered rounded"
                      >
                        <option>{t("add_coupon_type_i")}</option>
                        <option value="Percentage">{t("Percentage")}</option>
                        <option selected value="Amount">
                          {t("Amount")}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-group mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-12">
                    <div className="col-span-3">
                      <label className="text-[13px]" htmlFor="minimumShopping">
                        {t("add_coupon_min_shopping")}
                      </label>
                    </div>
                    <div className="col-span-9">
                      <input
                        type="number"
                        className="w-full h-[40px] input input-bordered rounded"
                        placeholder={t("Minimum Amount")}
                        {...register("minimum_purchase_amount", {
                          required: true,
                        })}
                      />
                      {errors.minimum_purchase_amount?.type === "required" && (
                        <span className="label-text-alt text-red-500 font-Roboto my-1">
                          Add a minimum purchase amount{" "}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                {couponType === "Amount" && (
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="maxDiscount">
                          {t("add_coupon_max_dis_am")}
                        </label>
                      </div>
                      <div className="col-span-9">
                        <input
                          type="number"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={`${t("add_coupon_max_dis_am")}`}
                          {...register("maximum_discount", { required: true })}
                        />
                        {errors.maximum_discount?.type === "required" && (
                          <span className="label-text-alt text-red-500 font-Roboto my-1">
                            Add a maximum discount{" "}
                          </span>
                        )}
                        {showErr ? (
                          <span className="label-text-alt text-red-500 font-Roboto my-1">
                            {error}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}

                {couponType === "Percentage" && (
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="discount">
                          Discount Percentage
                        </label>
                      </div>
                      <div className="col-span-9">
                        <input
                          type="number"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={`Discount Percentage`}
                          {...register("maximum_discount", { required: true })}
                        />
                        {errors.minimum_purchase_amount?.type ===
                          "required" && (
                          <span className="label-text-alt text-red-500 font-Roboto my-1">
                            Add a maximum percentage{" "}
                          </span>
                        )}
                        {showErr ? (
                          <span className="label-text-alt text-red-500 font-Roboto my-1">
                            {error}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}

                <div className="form-group mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-12">
                    <div className="col-span-3">
                      <label className="text-[13px]" htmlFor="dateRange">
                        {t("coupon_date")}
                      </label>
                    </div>
                    <div className="col-span-9">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        placeholderText={`${t("add_coupon_date_p")}`}
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          setDateRange(update);
                        }}
                        isClearable={true}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mx-auto pb-5">
            <input
              className="py-1 px-3 bg-primary text-white rounded cursor-pointer"
              type="submit"
              value={`${t("vd_pc_btn")}`}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCoupon;
