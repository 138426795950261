import React from 'react';

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div>
            <ul className="flex gap-x-2 items-center justify-center">
                <li >
                    <p onClick={() => paginate(1)} className={`rounded-full btn btn-sm border flex items-center justify-center text-sm ${currentPage === 1 && 'btn-disabled'}`}>{`<<`}</p>
                </li>
                <li >
                    <p onClick={() => paginate(currentPage - 1)} className={`rounded-full btn btn-sm border flex items-center justify-center text-sm ${currentPage === 1 && 'btn-disabled'}`}>{`<`}</p>
                </li>
                {
                    pageNumbers.map((number, idx) => {
                        if (number <= currentPage + 1 && number >= currentPage - 1) {
                            return (


                                <li key={idx}>
                                    <p onClick={() => paginate(number)} key={number} className={`rounded-full btn btn-sm border flex items-center justify-center text-sm ${currentPage === number && 'btn-primary text-white'} `}>{number}</p>
                                </li>


                            )
                        }
                        else if (number === pageNumbers.length) {
                            return (


                                <li key={idx}>
                                    <p onClick={() => paginate(number)} key={number} className={`rounded-full btn btn-sm border flex items-center justify-center text-sm ${currentPage === number && 'btn-primary text-white'} `}>{number}</p>
                                </li>


                            )
                        }
                        else {
                            return (


                                <>.</>


                            )

                        }

                    })
                }
                <li >
                    <p onClick={() => paginate(currentPage + 1)} className={`rounded-full btn btn-sm border flex items-center justify-center text-sm ${currentPage === pageNumbers.length && 'btn-disabled'} `}>{`>`}</p>
                </li>
                <li >
                    <p onClick={() => paginate(pageNumbers.length)} className={`rounded-full btn btn-sm border flex items-center justify-center text-sm ${currentPage === pageNumbers.length && 'btn-disabled'}`}>{`>>`}</p>
                </li>
            </ul>

        </div>
    );
};

export default Pagination;