import React, { useState } from "react";
import "./DashboardLayout.css";
import i18next from "i18next";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/DashboardPartials/Sidebar/Sidebar";
import DashboardHeader from "../../components/DashboardPartials/DashboardHeader/DashboardHeader";
import { Helmet } from "react-helmet-async";
import { useContext } from "react";
import { storeInformation } from "../../contexts/StoreInformationContext";
import logo from "../../assets/logo.png";

const DashboardLayout = () => {
  const { storeInfo } = useContext(storeInformation);
  let selectLanguage = i18next.language;
  const [sidebarToggle, setSidebarToggle] = useState(false);

  return (
    <div className="container h-[100vh] mx-auto ">
      <Helmet>
        {storeInfo?.store_favicon ? (
          <link rel="icon" href={storeInfo.store_favicon} />
        ) : (
          <link rel="icon" href={logo} />
        )}
      </Helmet>

      <div className="sticky z-30 h-[10vh]">
        <DashboardHeader
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
          role="vendor"
        />
      </div>

      <div
        className={`${
          selectLanguage === "ar" ? "md:pr-10" : "md:pl-10"
        } bg-[#F6F9FC]`}
        id="dashboard"
      >
        <div className="drawer h-[90vh] pt-8 drawer-mobile">
          <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
          <div className={`drawer-content flex flex-col `}>
            <div className={`p-5 md:p-5 md:px-12 duration-200`}>
              <Outlet />
            </div>
          </div>
          <Sidebar role="vendor" sidebarToggle={sidebarToggle} />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
