import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdWeb } from "react-icons/md";
import { useState } from "react";
import { Link } from "react-router-dom";
import getAllAdmin from "../../hooks/getAllAdmin";
import { HiUserGroup } from "react-icons/hi";
import { IoDocuments } from "react-icons/io5";
import { GrDomain } from "react-icons/gr";
import { BsCardChecklist } from "react-icons/bs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement
);

const options221 = {
  legend: {
    display: false,
  },

  maintainAspectRatio: false,
};

const AdminDashboard = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [getAllData, setGetAllData] = useState([]);
  const [latestOrders, setLatestOrders] = useState([]);
  const user = JSON.parse(localStorage.getItem("admin"));

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_APIURL;
    const fetchLatestOrders = async () => {
      const res = await fetch(`${baseUrl}/vendor/getallorder`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const response = await res.json();

      if (response.status_code === 200) {
        setLatestOrders(response.data.slice(-3));
      }
    };
    const allData = async () => {
      const res = await getAllAdmin("admin/getdashboarddetails");
      if (res.status_code === 200) {
        setGetAllData(res);
      }
    };
    fetchLatestOrders();
    allData();
  }, []);

  useEffect(() => {
    const getAllPlans = async () => {
      let res = await getAllAdmin("admin/getallvendorplan");
      if (res.status_code === 200) {
        setData(res.data);
      }
    };
    getAllPlans();
  }, []);

  const columns = [
    {
      name: `${t("Order_Id")}`,
      selector: (row) => row.id,
    },
    {
      name: `${t("Vendor")}`,
      selector: (row) => (row.store_name ? row.store_name : ""),
    },

    {
      name: `${t("Plan")}`,
      selector: (row) => row.plan_name,
    },
    {
      name: `${t("Transaction_id")}`,
      selector: (row) => row.transaction_id,
    },

    {
      name: `${t("Status")}`,
      selector: (row) =>
        row.status === 1 ? (
          <span className="badge badge-success text-white text-xs">
            {t("Active")}
          </span>
        ) : (
          <span className="badge badge-error text-white text-xs">
            {t("Pending")}
          </span>
        ),
    },
  ];

  const data221 = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "SEPT",
      "OCT",
      "NOV",
      "DEC",
    ],
    datasets: [
      {
        label: `${t("Subscriptions")}`,
        backgroundColor: "rgba(127, 143, 244,1)",
        borderColor: "rgba(127, 143, 244,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(127, 143, 244,0.8)",
        hoverBorderColor: "rgba(127, 143, 244,1)",
        // data: [265, 119, 280, 121, 316, 255, 279, 140]
        data: getAllData?.monthWiseSubscriptions_arr,
      },
    ],
  };
  const data222 = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "SEPT",
      "OCT",
      "NOV",
      "DEC",
    ],
    datasets: [
      {
        label: `${t("Earnings")}`,
        backgroundColor: "rgba(255, 138, 101,1)",
        borderColor: "rgba(255, 138, 101,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255, 138, 101,0.8)",
        hoverBorderColor: "rgba(255, 138, 101,1)",
        // data: [255, 279, 140, 211, 116, 265, 119, 280]
        data: getAllData?.monthWiseEarnings_arr,
      },
    ],
  };

  return (
    <>
      <div>
        {/* firstRow */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 gap-y-4 mb-8 ">
          <Link to="/admin/vendors">
            <div className="p-3 dashboard-box border border-gray-200 bg-white shadow-sm flex items-center gap-x-4 hover:scale-105 ease-in duration-500">
              <div className="bg-primary rounded p-5 text-white inline-block">
                <HiUserGroup size="30px" />
              </div>
              <div>
                <p className="text-gray-400 text-xs">{t("Vendors")}</p>
                <span className="text-xl font-medium text-gray-600">
                  {getAllData?.vendors?.count}
                </span>
              </div>
            </div>
          </Link>

          <Link to="/admin/orders">
            <div className="p-3 dashboard-box border border-gray-200 bg-white shadow-sm flex items-center gap-x-4 hover:scale-105 ease-in duration-500">
              <div className="bg-primary rounded p-5 text-white inline-block">
                <IoDocuments size="30px" />
              </div>
              <div>
                <p className="text-gray-400 text-xs">{t("V_allOrder_title")}</p>
                <span className="text-xl font-medium text-gray-600">
                  {getAllData?.orders?.count}
                </span>
              </div>
            </div>
          </Link>

          <Link to="/admin/domain">
            <div className="p-3 dashboard-box border border-gray-200 bg-white shadow-sm flex items-center gap-x-4 hover:scale-105 ease-in duration-500">
              <div className="bg-primary rounded p-5 text-white inline-block">
                <BsCardChecklist size="30px" />
              </div>
              <div>
                <p className="text-gray-400 text-xs">{t("Domain_Requests")}</p>
                <span className="text-xl font-medium text-gray-600">
                  {getAllData?.domainrequests?.count}
                </span>
              </div>
            </div>
          </Link>

          <Link to="/admin/plan/all-plans">
            <div className="p-3 dashboard-box border border-gray-200 bg-white shadow-sm flex items-center gap-x-4 hover:scale-105 ease-in duration-500">
              <div className="bg-primary rounded p-5 text-white inline-block">
                <FaMoneyCheckAlt size="30px" />
              </div>
              <div>
                <p className="text-gray-400 text-xs">{t("All_Plans")}</p>
                <span className="text-xl font-medium text-gray-600">
                  {getAllData?.expiredsubscriptions?.count}
                </span>
              </div>
            </div>
          </Link>
        </div>

        {/* secondRow */}
        <div className=" mb-8">
          <div className="p-4 dashboard-box  flex flex-col border border-gray-200 bg-white shadow-sm md:col-span-2">
            <div className="flex justify-between items-center">
              <p className="text-black text-base font-bold self-start">
                {t("Latest_Orders")}
              </p>
              <Link
                to="/admin/orders"
                className="btn btn-primary btn-sm rounded text-white buttonLink"
              >
                {t("View_All")}
              </Link>
            </div>
            <DataTable
              className="mt-[-25px]"
              columns={columns}
              data={data.slice(0, 3)}
              subHeader
            />
          </div>
        </div>

        {/* thirdRow */}
        {/* <div className="grid grid-cols-1  lg:grid-cols-3 gap-8  mb-8">
          <div className="p-4 px-6 dashboard-box border border-gray-200 bg-white shadow-sm gap-x-4 col-span-2">
            <div className="flex items-center justify-between gap-2">
              <h6 className="text-sm font-medium text-primary">Earning Performance </h6>
              <select className="select select-xs select-bordered rounded-full select-ghost focus:outline-none">
                <option selected>Last 7 Days</option>
                <option>15 Days</option>
                <option>30 Days</option>
                <option>365 Days</option>
              </select>
            </div>

            <div className="mt-5">
              <Line options={earningPerformanceOptions} data={earningPerformanceData} />
            </div>
          </div>

          <div className="p-4 px-6 dashboard-box border border-gray-200 bg-white shadow-sm gap-x-4">
            <div className="flex items-center justify-between gap-2">
              <h6 className="text-sm font-medium text-primary">Recent Requests</h6>

            </div>
          </div>
        </div> */}

        {/* Subscription */}
        <div className="grid grid-cols-1 md:grid-cols-2  gap-5 mb-8">
          <div className="p-4 dashboard-box flex flex-col justify-center items-center border border-gray-200 bg-white shadow-sm col-span-2">
            <p className="text-gray-500 text-sm md:text-md self-start uppercase">
              {t("Subscriptions")}
            </p>

            <div className="chart w-full md:p-5">
              <Bar data={data221} height={350} options={options221} />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2  gap-5">
          <div className="p-4 dashboard-box flex flex-col justify-center items-center border border-gray-200 bg-white shadow-sm col-span-2">
            <p className="text-gray-500 text-sm md:text-md self-start uppercase">
              {t("Earnings")}
            </p>

            <div className="chart w-full md:p-5">
              <Bar data={data222} height={350} options={options221} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
