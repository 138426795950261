import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { BiStore } from "react-icons/bi";
import { storeInformation } from "../../../../contexts/StoreInformationContext";
import { domainProvider } from "../../../../contexts/DomainContext";

const image1 =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDbfV-tyKw0PN-mNcqXef1ymCVYgn0nxO_vA&usqp=CAU";
const image2 =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmFye0k_xBn9pSVBKV59xEuHW0ykD1F4_Svw&usqp=CAU";
const image3 =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwt5vXkvYWJaR_kUT4WZQDYf3lox5FHNRF9g&usqp=CAU";

const sitesData = [
  {
    name: "AI TECH BASIC",
    image: image1,
    template_no: 1,
  },
  // {
  //     name: "LightSpeed",
  //     image: image2,
  //     template_no: 2
  // },
  // {
  //     name: "Tinker",
  //     image: image3,
  //     template_no: 3
  // }
];

const Themes = () => {
  const [selectTheme, setSelectTheme] = useState(1);
  const { storeInfo } = useContext(storeInformation);
  const { customdomain } = useContext(domainProvider);

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {sitesData.map((site, idx) => (
          <div key={idx} className="rounded-md">
            <div className="group relative overflow-hidden">
              <figure>
                <img
                  src={site.image}
                  className="w-full h-[200px] rounded-t-md"
                  alt="car!"
                />
              </figure>
              {site.template_no === selectTheme && (
                <div class="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-indigo-700 bg-opacity-75 group-hover:h-full group-hover:opacity-100 duration-500">
                  <p class="text-2xl mt-[-35px] text-white">View your site</p>
                  <Link
                    to={`${
                      customdomain === "aitech.com.sa"
                        ? `/${storeInfo?.store_name}`
                        : `/`
                    }`}
                    class="mt-5 px-8 py-3  duration-300 text-white"
                  >
                    <span className="text-xl hover:underline flex justify-center items-center mt-[-30px]">
                      Preview Store
                      <span className="!hover:text-white ml-2 ">
                        <BiStore size={20} />
                      </span>
                    </span>
                  </Link>
                </div>
              )}
            </div>

            <div
              className={` border-t-2 rounded-b-md ${
                site.template_no === selectTheme
                  ? "bg-black text-white"
                  : "bg-white"
              } flex justify-between items-center p-3`}
            >
              <div>
                <p className="text-xl ">
                  <span className="font-bold">
                    {site.template_no === selectTheme && "Active : "}
                  </span>{" "}
                  <span>{site.name}</span>
                </p>
              </div>
              <div>
                {site.template_no === selectTheme ? (
                  <Link to={"/vendor/settings/store-settings"}>
                    <button className="btn btn-sm bg-primary hover:bg-primary text-white hover:text-[#236260]">
                      Customize
                    </button>
                  </Link>
                ) : (
                  <>
                    <p className="btn btn-sm border-1 hover:border-none hover:px-[13px] border-blue-400 btn-outline hover:bg-primary hover:text-white mr-3">
                      Preview
                    </p>
                    <button className="btn btn-sm bg-[#236260] hover:bg-[#236260] text-white hover:text-primary">
                      Apply
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Themes;
