import React from 'react';
import SectionTitle from '../utils/SectionTitle';
import { useTranslation } from 'react-i18next';
import Div from '../utils/Div';
import './service.css'
import { FaUserClock } from 'react-icons/fa';
import { GrSecure } from 'react-icons/gr';
import { AiOutlineClockCircle } from 'react-icons/ai';
import clock from '../../assets/icons/services/clock.png';
import hours from '../../assets/icons/services/hours.png';
import secure from '../../assets/icons/services/secure.png';

const Services = () => {
    const { t } = useTranslation()

    const contents = [
        {
            id:1,
            icon: FaUserClock,
            title: t("service_title1"),
            description: t("service_desc1")
        },
        {
            id:2,
            icon: GrSecure,
            title: t("service_title2"),
            description: t("service_desc2")
        },
        {
            id:3,
            icon: AiOutlineClockCircle,
            title: t("service_title3"),
            description: t("service_desc3")
        }
    ]

    return (
        <section id="services" className="px-2 ">
            <Div>
                <div className="">
                    <div className='mb-12'>
                        <SectionTitle page={'feather'} />
                    </div>
                    <p className='py-2'></p>
                    <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-16 gap-x-16 py-10 md:px-32 mx-auto">
                        {
                            contents?.map((service, index) =>
                                <div data-aos="zoom-in" key={index} className="flex bg-[#236260] rounded-lg shadow-xl flex-col items-center gap-y-4 w-60 mx-auto mb-10 border px-3 py-5 hover:border-primary duration-300 relative">
                                    <div className='w-[90px] h-[90px] p-5 rounded-lg absolute top-[-70px] bg-primary'>
                                        {
                                            service.id === 1 &&
                                            <img src={clock} className=" mx-auto" alt="" />
                                        }
                                        {
                                            service.id === 2 &&
                                            <img src={hours} className=" mx-auto" alt="" />
                                        }
                                        {
                                            service.id === 3 &&
                                            <img src={secure} className=" mx-auto" alt="" />
                                        }
                                    </div>
                                    <p className="mt-8 mb-[-13px] text-[18px] font-semibold font-poppin text-white text-center">{service.title}</p>
                                    <p className="text-sm text-white text-center">{service.description}</p>
                                </div>
                            )
                        }
                    </div>
                </div>
            </Div>
        </section>
    );
};

export default Services;