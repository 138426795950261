import React, { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import { useTranslation } from "react-i18next";
import getAllAdmin from "../../../hooks/getAllAdmin";

const GeneralSettings = () => {
  const { t } = useTranslation();
  const [logoinput, setLogoInput] = useState();
  const [iconinput, setIconInput] = useState();
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState({});
  const user = JSON.parse(localStorage.getItem("admin"));
  const {
    control,
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const getGeneralSettings = async () => {
      const res = await getAllAdmin("admin/getfulldetails");

      if (res.status_code === 200) {
        setSettings(res.data[0]);
        setValue("id", res.data[0].id);
        setValue("name", res.data[0].name);
        setValue("email", res.data[0].email);
        setValue("description", res.data[0].description);
        setValue("contact_no", res.data[0].contact_no);
        setValue("alternate_contact_no", res.data[0].alternate_contact_no);
        setValue("alternate_email", res.data[0].alternate_email);
        setValue("address", res.data[0].address);
        setValue("facebook_url", res.data[0].facebook_url);
        setValue("instagram_url", res.data[0].instagram_url);
        setValue("twitter_url", res.data[0].twitter_url);
        setValue("youtube_url", res.data[0].youtube_url);
        setValue(
          "moyasar_publishable_key",
          res.data[0].moyasar_publishable_key
        );
        setValue("moyasar_secret_key", res.data[0].moyasar_secret_key);
      }
      setLoading(false);
    };

    getGeneralSettings();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const form = new FormData();

    form.append("id", data.id);
    form.append("name", data.name);
    form.append("email", data.email);
    form.append("description", data.description);
    form.append("contact_no", data.contact_no);
    form.append("alternate_contact_no", data.alternate_contact_no);
    form.append("alternate_email", data.alternate_email);
    form.append("address", data.address);
    form.append("facebook_url", data.facebook_url);
    form.append("instagram_url", data.instagram_url);
    form.append("twitter_url", data.twitter_url);
    form.append("youtube_url", data.youtube_url);
    form.append("moyasar_publishable_key", data.moyasar_publishable_key);
    form.append("moyasar_secret_key", data.moyasar_secret_key);
    if (logoinput) {
      form.append("logo", logoinput);
    }
    if (iconinput) {
      form.append("favicon", iconinput);
    }

    const res = await fetch(
      `${process.env.REACT_APP_APIURL}/admin/updategeneraldetails`,
      {
        method: "PATCH",
        body: form,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const response = await res.json();
    setLoading(false);
    if (response?.status_code === 200) {
      toast.success(response.message);
      window.location.reload();
    } else {
      toast.error(response?.message || response?.err);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("Admin")} - {t("Settings")}
        </title>
      </Helmet>
      <div className="mt-2 mb-16">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="max-w-3xl mx-auto">
            <div className="flex gap-x-4 bg-white border p-4">
              <h2 className="text-title text-lg md:text-xl uppercase">
                {t("Settings")}
              </h2>
            </div>
            <div className="md:col-span-2 mx-auto">
              <div className="border bg-white">
                <div className="py-5 px-5">
                  <div className="form-group mb-5">
                    <div className="grid md:grid-cols-2 gap-y-3">
                      <div>
                        <label className="text-[13px]" htmlFor="planName">
                          {t("Site_name")}
                          <span className="text-error">*</span>
                        </label>
                      </div>
                      <div>
                        <input {...register("id")} hidden />
                        <input
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={t("Site_name")}
                          {...register("name")}
                        />
                      </div>

                      <div className="mt-2">
                        <label className="text-[13px]" htmlFor="logo">
                          {t("Logo")}{" "}
                        </label>
                      </div>
                      <div>
                        <input
                          onChange={(e) => setLogoInput(e.target.files[0])}
                          type="file"
                          className="w-full h-[40px] input -ml-3 rounded"
                        />

                        {logoinput ? (
                          <small className="text-xs text-success">
                            {t("Site_logo_preview")}
                          </small>
                        ) : (
                          <img
                            src={settings?.logo}
                            alt=""
                            className="w-20 h-20 object-contain"
                          />
                        )}
                      </div>
                      <div className="mt-2">
                        <label className="text-[13px]" htmlFor="logo">
                          {t("Favicon")}
                        </label>
                      </div>
                      <div>
                        <input
                          onChange={(e) => setIconInput(e.target.files[0])}
                          type="file"
                          className="w-full h-[40px] input -ml-3 rounded"
                        />

                        {iconinput ? (
                          <small className="text-xs text-success">
                            {t("Site_icon_preview")}
                          </small>
                        ) : (
                          <img
                            src={settings?.favicon}
                            alt=""
                            className="w-20 h-20 object-contain"
                          />
                        )}
                      </div>

                      {/* contact no */}
                      <div>
                        <label className="text-[13px]" htmlFor="planName">
                          {t("l_phone")}
                        </label>
                      </div>
                      <div>
                        <input
                          type="number"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={t("l_phone")}
                          {...register("contact_no")}
                        />
                      </div>

                      <div>
                        <label className="text-[13px]" htmlFor="planName">
                          {t("Alternate_Contact_no")}
                        </label>
                      </div>
                      <div>
                        <input
                          type="number"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={t("Alternate_Contact_no")}
                          {...register("alternate_contact_no")}
                        />
                      </div>

                      {/* Email no */}
                      <div>
                        <label className="text-[13px]">{t("form_email")}</label>
                      </div>
                      <div>
                        <input
                          type="email"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={t("form_email")}
                          {...register("email")}
                        />
                      </div>

                      <div>
                        <label className="text-[13px]" htmlFor="planName">
                          {t("Alternate_Email")}
                        </label>
                      </div>
                      <div>
                        <input
                          type="email"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={t("Alternate_Email")}
                          {...register("alternate_email")}
                        />
                      </div>

                      <div>
                        <label className="text-[13px]" htmlFor="planName">
                          {t("Address")}
                        </label>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={t("Address")}
                          {...register("address")}
                        />
                      </div>

                      {/* description */}
                      <div>
                        <label className="text-[13px]" htmlFor="planName">
                          {t("Description")}
                        </label>
                      </div>
                      <div>
                        <textarea
                          className="w-full h-[80px] input input-bordered rounded"
                          {...register("description")}
                        ></textarea>
                      </div>
                      {/* social */}
                      <div>
                        <label className="text-[13px]" htmlFor="planName">
                          Facebook URL
                        </label>
                      </div>
                      <div>
                        <input
                          type="url"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder="https://"
                          {...register("facebook_url")}
                        />
                      </div>
                      <div>
                        <label className="text-[13px]" htmlFor="planName">
                          Instagram URL
                        </label>
                      </div>
                      <div>
                        <input
                          type="url"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder="https://"
                          {...register("instagram_url")}
                        />
                      </div>
                      <div>
                        <label className="text-[13px]" htmlFor="planName">
                          Twitter URL
                        </label>
                      </div>
                      <div>
                        <input
                          type="url"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder="https://"
                          {...register("twitter_url")}
                        />
                      </div>
                      <div>
                        <label className="text-[13px]" htmlFor="planName">
                          Youtube URL
                        </label>
                      </div>
                      <div>
                        <input
                          type="url"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder="https://"
                          {...register("youtube_url")}
                        />
                      </div>
                      <div>
                        <label className="text-[13px]" htmlFor="planName">
                          {" "}
                          Moyasar Publishable Key
                        </label>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder=""
                          {...register("moyasar_publishable_key")}
                        />
                      </div>
                      <div>
                        <label className="text-[13px]" htmlFor="planName">
                          {" "}
                          Moyasar Secret Key
                        </label>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder=""
                          {...register("moyasar_secret_key")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <input
                className="py-1 mt-2 px-3 bg-primary text-white rounded cursor-pointer"
                type="submit"
                value={t("Save")}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default GeneralSettings;
