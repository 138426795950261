import React, { useContext, useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./Dashboard.css";
import { useTranslation } from "react-i18next";
import { domainProvider } from "../../contexts/DomainContext";
import axios from "../../components/utils/Interceptors"

const TopProductsSlider = ({ type, user, storeName, dashboard }) => {
  const [topProducts, setTopProducts] = useState([]);
  const { t } = useTranslation();

  const {customdomain} = useContext(domainProvider);

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_APIURL;
    if (type === "vender") {
      const api = `${baseUrl}/vendor/gettopproducts`;
      const fetchTopProducts = async () => {
        const res = await axios.get(api, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });
        const response = res.data;

        if (response.status_code === 200) {
          if (response.data.length > 0) {
            setTopProducts(response.data);
          }
        }
      };
      fetchTopProducts();
    } else {
      if (customdomain === "aitech.com.sa") {
        const api = `${baseUrl}/vendor/store/getalltopproducts/${storeName}`;
        const fetchTopProducts = async () => {
          const res = await axios.get(api);
          const response = await res.data;
          if (response.status_code === 200) {
            if (response.data.length > 0) {
              setTopProducts(response.data);
            }
          }
        };
        fetchTopProducts();
      } else {
        const fetchTopProducts = async () => {
          const res = await axios.get(
            `${process.env.REACT_APP_APIURL}/vendor/store/getallproducts`,
            {
              headers: {
                "Content-Type": "application/json",
                domain_name: customdomain,
              },
            }
          );
          const response = await res.data;
          if (response.status_code === 200) {
            if (response.data.length > 0) {
              setTopProducts(response.data);
            }
          }
        };
        fetchTopProducts();
      }
    }
  }, [type]);

  return (
    <>
      {topProducts.length > 0 && dashboard === "Vendor" && (
        <p className="text-black text-base font-bold self-start">
          {t("top_product")}
        </p>
      )}
      {topProducts.length > 0 && (
        <div className="Container"  >
          <div className="px-5 py-16 font-domain">
            <h2 className="text-2xl md:text-4xl font-medium text-pink-500 text-center mb-6">
              {t("top_product_title")}
            </h2>
            {/* <Slide /> */}

            <div className="mt-8" >
              <div className="main-swiper" >
                <Swiper
                  effect={"coverflow"}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  grabCursor={true}
                  centeredSlides={true}
                  slidesPerView={"auto"}
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                  }}
                  pagination={true}
                  className="mySwiper"
                >
                  {/* using array */}
                  {topProducts.map(
                    ({
                      id,
                      thumbnail_image,
                      product_price,
                      rating,
                      product_name,
                    }) => {
                      return (
                        <SwiperSlide key={id}>
                          <div key={id} className="px-3 ">
                            <div className="border bg-slate-100 rounded cursor-pointer min-h-[300px]">
                              <div className="w-72 h-48 ">
                                <img
                                  className="w-full object-contain p-2"
                                  src={thumbnail_image}
                                  alt=""
                                />
                              </div>
                              <div className="details flex flex-col p-3">
                                <h4 className="text-md font-semibold text-orange-400">
                                  ${product_price}
                                </h4>
                                <Rating
                                  readonly={true}
                                  initialValue={rating}
                                  size={14}
                                />
                                <h3 className="font-semibold text-[13px] text-truncate-2 lh-1-4 mb-0">
                                  <a
                                    href="/dashboard"
                                    className="d-block text-reset"
                                    target="_blank"
                                    tabIndex="0"
                                  >
                                    {product_name}
                                  </a>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    }
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopProductsSlider;
