import React, { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";

export const customerProvider = createContext();
const CustomerContext = ({ children }) => {
  const [customer, setCustomer] = useState({});
  const [isCustomer, setIsCustomer] = useState(false);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("customer"));
    setCustomer(userInfo);
  }, [isCustomer]);
  const provide = { customer, setCustomer, isCustomer, setIsCustomer };
  return (
    <customerProvider.Provider value={provide}>
      {children}
    </customerProvider.Provider>
  );
};

export default CustomerContext;
