import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import { Helmet } from "react-helmet-async";
import getDataById from "../../../hooks/admin/getDataById";
import ImageUploading from "react-images-uploading";
import { useTranslation } from "react-i18next";

const EditServices = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("admin"));
  const [images, setImages] = useState([]);
  const {id} = useParams();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    setLoading(true);

    const getServiceData = async () => {
      const res = await getDataById("admin/getbyidservice", id);

      if (res.status_code === 200) {
        setValue("title", res.data.title);
        setValue("description", res.data.description);
        setValue(
          "url",
          (res.data.url && res.data.url != undefined && res.data.url != null) ? res.data.url : ""
        );
      }
      setLoading(false);
    };

    getServiceData();
  }, [id]);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const handleUpdateService = (data) => {
    setLoading(true);
    const userInfo = new FormData();
    if (images[0]?.file) {
      userInfo.append("image", images[0]?.file);
    }
    userInfo.append("id", id);
    userInfo.append("title", data.title);
    userInfo.append("description", data.description);
    if (data.url) {
      userInfo.append("url", data.url);
    }

    fetch(`${process.env.REACT_APP_APIURL}/admin/updateservice`, {
      method: "PATCH",
      headers: {
        // 'Content-type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: userInfo,
    })
      .then((res) => res.json())
      .then((info) => {
        setLoading(false);
        if (info.status_code === 200) {
          toast.success(`${t("update_Service_success")}`);
          window.history.go(-1);
        } else {
          toast.error(info?.message || info?.err);
        }
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>
          {t("Admin")} - {t("Edit_Service")}
        </title>
      </Helmet>
      <div className="flex justify-between">
        <h1 className="text-md font-medium">{t("Edit_Service")}</h1>
        <Link to={"/admin/settings/general_services"}>
          <button className="btn btn-primary text-white btn-sm">
            {t("Back_Service")}
          </button>
        </Link>
      </div>
      <>
        <div className="Container">
          <div className=" ">
            <div>
              <form
                className="border-dashed border p-8 flex flex-col justify-center items-start"
                onSubmit={handleSubmit(handleUpdateService)}
              >
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      {t("Service_Title")}
                      <span className="text-red-600">*</span>
                    </span>
                  </label>
                  <input
                    {...register("title", { required: true })}
                    type="text"
                    placeholder={t("Service_Title")}
                    className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
                  />
                  <label className="label">
                    {errors.cust_fullname?.type === "required" && (
                      <span className="label-text-alt text-red-500 font-Roboto">
                        {t("Service_Title_req")}
                      </span>
                    )}
                  </label>
                </div>
          
                <div className="border w-full bg-white my-5">
                  <div className="card-title border-b p-4">
                    <h3 className="text-sm font-medium">
                      {t("Service_Image")}{" "}
                    </h3>
                  </div>
                  <div className="py-5 px-5">
                    <div className="col-span-8">
                      <ImageUploading
                        value={images}
                        onChange={onChange}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          <div className="upload__image-wrapper">
                            <p
                              className="text-xs cursor-pointer py-2 px-3"
                              style={isDragging ? { color: "red" } : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              {t("add_new_product_T_img_info")}
                            </p>
                            &nbsp;
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <div className="image-item__btn-wrapper">
                                  <button
                                    className="text-red-600"
                                    onClick={() => onImageRemove(index)}
                                  >
                                    X
                                  </button>
                                </div>
                                <img
                                  className="w-20 h-20"
                                  src={image["data_url"]}
                                  alt=""
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                  </div>
                </div>

                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      {t("Services_Description")}{" "}
                      <span className="text-red-600">*</span>
                    </span>
                  </label>
                  <input
                    {...register("description", { required: true })}
                    type="text"
                    placeholder={t("Services_Description")}
                    className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
                  />
                  <label className="label">
                    {errors.cust_email?.type === "required" && (
                      <span className="label-text-alt text-red-500 font-Roboto">
                        {t("Services_Description_req")}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">{t("Url")}</span>
                  </label>
                  <input
                    {...register("url")}
                    type="text"
                    placeholder={t("Url")}
                    className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
                  />
                </div>

                <button className="px-5 py-2 bg-primary text-white rounded-md my-3">
                  {t("Service_Update")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default EditServices;
