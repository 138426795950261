import { useState } from "react";
import useUser from "./useUser";
import axios from "../components/utils/Interceptors";

const updateData = async (url, data) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const baseUrl = process.env.REACT_APP_APIURL;

  const res = await axios.patch(`${baseUrl}/${url}`, JSON.stringify(data), {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user?.token}`,
    },
  });

  const response = await res.data;

  return response;
};

export default updateData;
