import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { confirmAlert } from "react-confirm-alert";
import { RiCloseCircleLine } from "react-icons/ri";
import { storeInformation } from "../../../contexts/StoreInformationContext";
import { useForm } from "react-hook-form";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "../../../components/utils/Interceptors";
import i18next from "i18next";

const DomainSettings = () => {
  const { storeInfo } = useContext(storeInformation);
  const [customDomain, setCustomDomain] = useState(null);
  const [status, setStatus] = useState(0);
  const [showFrom, setShowFrom] = useState(false);
  const [fatch, setFatch] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const selectLanguage = i18next.language
  // const {setDomainstatus, setDomainName} = useContext(domainProvider)

  useEffect(() => {
    const getAllCustomDomain = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallcustomdomainrequest`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const res = await response.data;
      if (res.status_code === 200) {
        setCustomDomain(res.data);
        setStatus(res.data.is_active);
        if (res.data.is_active === 1) {
          // setDomainstatus(res.data.is_active)
          // setDomainName(res.data.full_domain_name)
        }
      } else {
        setCustomDomain(null);
      }
    };
    getAllCustomDomain();
  }, [fatch]);

  const handleRequestDelete = async (value) => {
    confirmAlert({
      title: <p className="text-2xl font-bold">Delete Confirmation</p>,
      message: "Are You Sure To Delete This Request",
      buttons: [
        {
          label: `yes`,
          onClick: async () => {
            setLoading(true);
            const res = await axios.delete(
              `${process.env.REACT_APP_APIURL}/vendor/deletecustomdomainrequest/${value}`,
              {
                headers: {
                  "Content-type": "application/json",
                  Authorization: `Bearer ${user.token}`,
                },
              }
            );
            const response = await res.data;
            if (response.status_code === 200) {
              toast.success(response.message);
              setCustomDomain(null);
              setFatch(!fatch);
            } else {
              toast.error(response.message);
            }

            setLoading(false);
          },
        },
        {
          label: `Cancel`,
          onClick: () => {},
        },
      ],
    });
  };

  const handleDomainRequest = async (data) => {
    const domainData = {
      store_id: storeInfo?.store_id,
      domain_name: data.domain_name,
      full_domain_name: data.full_domain_name,
    };
    const form = new FormData();
    // form.append("store_id", storeInfo?.store_id);
    form.append("domain_name", data.domain_name);
    form.append("full_domain_name", data.full_domain_name);

    setLoading(true);
    const res = await axios.post(
      `${process.env.REACT_APP_APIURL}/vendor/createcustomdomainrequest`,
      JSON.stringify(domainData),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );

    const response = await res.data;
    setLoading(false);
    if (response.status_code === 200) {
      toast.success(response.message);
      setShowFrom(false);
      setFatch(!fatch);
    } else {
      toast.error(response.message);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="mb-16">
      <h2 className="text-title text-lg md:text-2xl pb-2 uppercase">
        {t("Domain Settings")}
      </h2>
      <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 mt-5">
        <div className="grid grid-cols-1 gap-8">
          <div className="py-2 px-5">
            <h2 className="font-bold text-md text-primary">
              {t("Current Domain")}
            </h2>
            <p className="text-sm text-gray-400 my-4">
              {status === 1
                ? `https://${customDomain?.full_domain_name}`
                : `https://aitech.com.sa/${storeInfo.store_name}`}
            </p>
          </div>
        </div>
      </div>
      <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 mt-5 ">
        <div className="grid grid-cols-1 gap-8">
          <div className="py-2 px-5">
            <div className="flex justify-between items-center">
              <h2 className="font-bold text-primary text-md">
                {t("Domain Request")}
              </h2>
              {!customDomain && (
                <label
                  onClick={() => setShowFrom(true)}
                  className="p-2 flex items-center border border-secondary hover:bg-secondary rounded-3xl text-secondary hover:text-white cursor-pointer duration-300 modal-button"
                  title="Edit"
                >
                  {t("Add Request")}
                </label>
              )}
            </div>
            {/* customDomain.domain_name */}

            {customDomain && (
              <div className="overflow-x-auto mt-2">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>{t("Domain Name")}</th>
                      <th>{t("Full Domain Name")}</th>
                      <th>{t("Add Request")}</th>
                      <th>{t("Action")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>{customDomain?.domain_name}</td>
                      <td>{customDomain?.full_domain_name}</td>
                      <td>{customDomain?.created_at?.slice(0, 10)}</td>
                      {/* <label
                          onClick={() => handleRequestDelete(customDomain.id)}
                          className={`p-2 flex justify-center w-9 items-center border border-info mr-3 hover:bg-info rounded-full text-info hover:text-white cursor-pointer duration-300`}
                          title="Delete Request"
                        >
                          <TiDelete className=" text-red-500 text-lg hover:text-white" />
                        </label> */}
                      {status === 0 ? (
                        <td className="text-red-600 font-semibold">Pending</td>
                      ) : status === 1 ? (
                        <td className="text-green-600 font-semibold">
                          Success
                        </td>
                      ) : (
                        <td className="text-yellow-700 font-semibold">
                          Not Acceptable
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            <div>
              {showFrom && (
                <>
                  <form
                    className="flex flex-col gap-y-2 py-5 relative justify-center p-3 mt-4 border-2  rounded-md border-[#236260] w-full"
                    onSubmit={handleSubmit(handleDomainRequest)}
                  >
                    <p className="text-2xl md:text-3xl text-center font-bold">
                      {t("Add Custom Domain")}
                    </p>
                    <div className="absolute top-2 right-2">
                      <label
                        onClick={() => setShowFrom(false)}
                        className="p-2 flex items-center border border-red-400 hover:bg-red-400 rounded-full text-[#236260] hover:text-white cursor-pointer duration-300 modal-button"
                        title="Cancel"
                      >
                        <RiCloseCircleLine size={20} />
                      </label>
                    </div>

                    <div className=" pt-5">
                      <label className="text-lg text-black">
                        {t("Domain Name Without Protocol")}{" "}
                        <span className="text-sm text-[#236260]">
                          {" "}
                          (example.com)
                        </span>
                      </label>
                      <input
                        defaultValue={
                          customDomain && customDomain.requested_domain_name
                            ? customDomain.requested_domain_name
                            : ""
                        }
                        type="link"
                        className="input input-bordered w-full rounded my-2"
                        placeholder="example.com"
                        {...register("domain_name", { required: true })}
                      />
                    </div>

                    <div className="my-2">
                      <label className="text-lg text-black">
                        {t("Full Domain Name With Protocol")}{" "}
                        <span className="text-sm text-red-400">
                          {" "}
                          (https://example.com)
                        </span>
                      </label>
                      <input
                        defaultValue={
                          customDomain && customDomain.requested_domain_name
                            ? customDomain.requested_domain_name
                            : ""
                        }
                        type="link"
                        className="input input-bordered w-full rounded my-2"
                        placeholder="https://example.com"
                        {...register("full_domain_name", { required: true })}
                      />
                    </div>

                    <div className="my-2">
                      <label className="text-sm text-gray-500">
                        {selectLanguage === "ar" ? "تكوين سجل DNS الخاص بك" : "Configure Your DNS Record" }
                      </label>
                      <p className="text-2xs my-2 text-gray-400">
                        {selectLanguage === "ar" ? " ستحتاج إلى إعداد سجل DNS للإشارة إلى متجرك على الخادم الخاص بنا. يمكن إعداد سجلات DNS من خلال لوحة تحكم مسجلي النطاق الخاص بك. وبما أن كل مسجل لديه إعداد مختلف، فاتصل به للحصول على المساعدة إذا لم تكن متأكدًا." : " You'll need to setup a DNS record to point to your store on our server. DNS records can be setup through your domain registrars control panel. Since every registrar has a different setup, contact them for assistance if you're unsure." }
                      </p>

                      <div className="my-6">
                        <label className="text-sm text-gray-500 mb-2">
                         {selectLanguage === "ar" ? "خوادم الأسماء:" : "Nameservers:"} &nbsp;
                        </label>
                        <ul className="list-disc px-4">
                          <li>http://ns1.serverhostgroup.com</li>
                          <li>http://ns2.serverhostgroup.com</li>
                          <li>http://ns3.serverhostgroup.com</li>
                        </ul>
                      </div>

                      {/* <div className="overflow-x-auto">
                        <table className="table w-full">
                          <thead>
                            <tr>
                              <th>{t("coupon_type")}</th>
                              <th>{t("RECORD")}</th>
                              <th>{t("VALUE")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>A</td>
                              <td></td>
                              <td></td>
                            </tr>

                            <tr>
                              <td>CNAME</td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div> */}

                      <p className="text-2xs my-2 text-gray-400">
                        {selectLanguage === "ar" ? "قد تستغرق تغييرات نظام أسماء النطاقات ما يصل إلى 48-72 ساعة حتى تدخل حيز التنفيذ، على الرغم من أنها عادة ما تكون أسرع بكثير من ذلك. ستتلقى ردًا عندما يتم تنشيط المجال المخصص الخاص بك. يرجى الانتظار لمدة 1-2 أيام عمل حتى تكتمل هذه العملية. " : "DNS changes may take up to 48-72 hours to take effect, although it's normally a lot faster than that. You will receive a reply when your custom domain has been activated. Please allow 1-2 business days for this process to complete."}
                      </p>
                    </div>

                    <div className="text-right">
                      <button
                        onClick={() => setShowFrom(false)}
                        className={`btn btn-primary btn-sm bg-red-400 rounded text-white ${selectLanguage === "ar" ? "ml-4" :"mr-4"}`}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary rounded text-white"
                      >
                        {t("Send Request")}
                      </button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomainSettings;
