import React, { useEffect } from "react";
import axios from "../../../components/utils/Interceptors";
import useRazorpay from "react-razorpay";

const RazorpayPayment = ({ amount = 10, order_id = 1, currency = "INR" }) => {
  const Razorpay = useRazorpay();

  const handlePayment = async () => {
    const options = {
      // key: "rzp_test_r6FiJfddJh76SI",
      key: "rzp_test_JC7uJ7pz5I8Tfv",
      amount: amount.toString(),
      currency: currency,
      name: "Soumya Corp.",
      description: "Test Transaction",
      // image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await axios.post(
          "http://localhost:5000/payment/success",
          data
        );

        alert(result.data.msg);
      },
      prefill: {
        name: "Soumya Dey",
        email: "SoumyaDey@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Soumya Dey Corporate Office",
      },
      theme: {
        color: "#61dafb",
      },
    };
    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  };

  return (
    <div>
      <button onClick={handlePayment}>Click</button>
    </div>
  );
};

export default RazorpayPayment;
