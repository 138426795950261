import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import postData from "../../../hooks/postData";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axios from "../../../components/utils/Interceptors"

const animatedComponents = makeAnimated();
const AddOrderCheckout = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);

  const {
    control,
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { state } = useLocation();
  const { cart } = state || [];
  const [customersid, setCustomersid] = useState([]);
  const [customers, setCustomers] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!cart) {
      return navigate("/vendor/dashboard");
    }
  }, []);

  useEffect(() => {
    const getAllCustomers = async () => {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallcustomer`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.status_code === 200 && response.data) {
        const data = response.data.map(
          ({ cust_id, cust_fullname, cust_email }) => ({
            label: cust_fullname,
            value: cust_id,
          })
        );
        setCustomers(data);
      }
      setLoading(false);
    };
    getAllCustomers();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    const product_id = cart.map((item) => item.product_id);
    const product_quantity = cart.map((item) => item.qty);
    const product_color = cart.map((item) => item.color);
    const product_size = cart.map((item) => item.size);
    const order_price = cart.reduce(
      (prev, item) => prev + item.product_price * item.qty,
      0
    );

    data["product_id"] = product_id;
    data["product_quantity"] = product_quantity;
    data["product_color"] = product_color;
    data["product_size"] = product_size;
    data["order_price"] = order_price;
    data["customer_id"] = customersid;
    // data["transaction_id"]=transaction_id

    const response = await postData("/vendor/createorder", data);

    if (response.order_id) {
      toast.success("Order created successfully");
      reset();
      navigate("/vendor/orders");
    } else {
      toast.error("something went wrong");
    }
    setLoading(false);
  };

  return (
    <div className="mb-16">
      <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
        <div className="md:col-span-4">
          <div className="p-4 bg-white border">
            <h1 className="text-lg md:text-2xl font-bold">Customer Details</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-3">
                  <div>
                    <div>
                      <label className="text-[13px]" htmlFor="name">
                        Select Customer <span className="text-error">*</span>
                      </label>
                    </div>
                    <div>
                      {customers?.length > 0 && (
                        <Select
                          onChange={(val) =>
                            setCustomersid(
                              Object.keys(val).map((item) => item.value)
                            )
                          }
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          options={customers}
                          required={true}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3">
                  <div>
                    <h1 className="text-lg md:text-2xl font-bold">
                      Payment Status
                    </h1>

                    <div className="form-control my-2">
                      <label className="flex items-center gap-2  cursor-pointer">
                        <input
                          type="radio"
                          name="payment_status"
                          {...register("payment_status")}
                          value="1"
                          className="radio radio-sm checked:bg-primary"
                        />
                        <span className="label-text">Paid</span>

                        <input
                          type="radio"
                          name="payment_status"
                          value="0"
                          {...register("payment_status")}
                          className="radio radio-sm checked:bg-primary"
                        />
                        <span className="label-text">Pending</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-2 mt-4 mb-3">
                  <h1 className="text-lg md:text-2xl font-bold">
                    Other Details
                  </h1>
                  <div>
                    <label className="text-[13px]" htmlFor="name">
                      Transaction Id
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="w-full h-[40px] input input-bordered rounded"
                      placeholder=""
                      {...register("transaction_id")}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-2 my-3">
                  <div>
                    <label className="text-[13px]" htmlFor="name">
                      Order note
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="w-full h-[40px] input input-bordered rounded"
                      placeholder=""
                      {...register("order_note")}
                    />
                  </div>
                </div>

                <input
                  type="submit"
                  className="btn btn-primary w-full rounded text-white"
                  value="Make Order"
                />
              </div>
            </form>
          </div>
        </div>

        <div className="md:col-span-2">
          <div className="p-4 bg-white border">
            <div className="flex justify-between items-center">
              <h1 className="text-lg md:text-2xl font-medium">Cart Items</h1>
              <span className="badge badge-primary text-white border-none">
                {cart.reduce((prev, item) => prev + item.qty, 0)}
              </span>
            </div>
            <div className="overflow-x-auto my-2">
              {cart.map((item, index) => (
                <div
                  key={index}
                  className="border p-2 flex justify-between items-end gap-2 "
                >
                  <h2 className="">
                    {item.product_name}
                    <span className="text-sm text-gray-400 ml-2">
                      x {item.qty}
                    </span>
                  </h2>
                  <span className="text-gray-400">
                    {item.qty * item.product_price}
                  </span>
                </div>
              ))}
              <div className="border p-2 flex justify-between items-end">
                <h2 className="font-semibold">Total </h2>
                <span className="text-gray-800 font-bold">
                  {cart.reduce(
                    (prev, item) => prev + item.product_price * item.qty,
                    0
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOrderCheckout;
