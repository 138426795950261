import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import {
  useNavigate,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { cartProvider } from "../../../contexts/CartContext";
import { customerProvider } from "../../../contexts/CustomerContext";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { domainProvider } from "../../../contexts/DomainContext";
import axios from "../../../components/utils/CustomerInterceptor";

const AddToCart = ({
  id,
  colors,
  sizes,
  tags,
  product,
  p_price,
  maxQuantity,
}) => {
  const storeName = window.location.pathname.split("/")[1];
  const selectLanguage = i18next.language;
  const { t } = useTranslation();

  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(p_price);
  const [currentColor, setCurrentColor] = useState(colors[0]?.color_name ?? "");
  const [currentSize, setCurrentSize] = useState(sizes[0]?.size_name);
  const { customer } = useContext(customerProvider);
  const { cartRefetch } = useContext(cartProvider);
  const location = useLocation();
  const navigate = useNavigate();

  // changes start
  const [maxQuant, setMaxQuant] = useState(1);
  const [addToCart, setAddToCart] = useState(1);
  const { customdomain } = useContext(domainProvider);

  useEffect(() => {
    setCurrentSize(
      selectLanguage == "ar" && sizes[0]?.size_name_ar
        ? sizes[0]?.size_name_ar
        : sizes[0]?.size_name
    );
    setCurrentColor(
      selectLanguage == "ar" && colors[0]?.color_name_ar
        ? colors[0]?.color_name_ar
        : colors[0]?.color_name
    );
  }, [selectLanguage]);

  useEffect(() => {
    if (maxQuantity) {
      setAddToCart(maxQuantity);
      setMaxQuant(maxQuantity);
    } else {
      setAddToCart(product.product_stock);
      setMaxQuant(product.product_stock);
    }
  }, [maxQuantity, product]);

  // changes end

  const handleIncrease = () => {
    maxQuant <= quantity
      ? setQuantity(maxQuant)
      : setQuantity((prev) => prev + 1);
  };

  const handleDecrease = () => {
    if (maxQuant == 0) setQuantity(0);
    else quantity <= 1 ? setQuantity(1) : setQuantity((prev) => prev - 1);
  };

  useEffect(() => {
    setPrice(p_price * quantity);
  }, [quantity]);

  const handleCart = () => {
    if (
      id === 89 ||
      id === 90 ||
      id === 91 ||
      id === 92 ||
      id === 93 ||
      id === 94 ||
      id === 95 ||
      id === 96
    )
      return toast.info("It is a sample products. You can't add it to cart");

    const sizeInfo = sizes.filter((size) => size.size_name === currentSize);
    const colorInfo = colors.filter(
      (color) => color.color_name === currentColor
    );
    const newCartItem = {
      product_id: id,
      size: sizeInfo[0]?.size_id,
      color: colorInfo[0]?.color_id,
      quantity,
    };

    if (maxQuant == 0 || quantity < 1 || maxQuant - quantity < 0)
      return toast.info("Product out of stock");

    if (maxQuant - quantity >= 0 && quantity >= 1) {
      setMaxQuant((prev) => prev - quantity);
      if (customer) {
        axios
          .post(
            `${process.env.REACT_APP_APIURL}/vendor/store/customer/addtocart`,
            JSON.stringify(newCartItem),
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${customer?.token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status_code === 200) {
              toast.success(res.data.message);
              cartRefetch();
            }
          });
      } else {
        if (customdomain === "aitech.com.sa")
          navigate(`/${storeName}/register-login`, {
            state: window.location.pathname,
          });
        else {
          navigate(`/register-login`, {
            state: window.location.pathname,
          });
        }
      }
    }
  };

  return (
    <div className="my-4">
      <div className="mb-2 flex flex-row items-center">
        <p className="text-base font-semibold">
          {t("add_new_product_Colors")} :{" "}
          <span className="text-gray-600 text-lg font-semibold capitalize">
            {currentColor}
          </span>
        </p>
        <div className="flex items-center ml-2">
          {colors.map((color, idx) => (
            <button
              key={idx}
              className="w-[20px] h-[20px] border-black mr-2 rounded flex justify-center items-center"
              style={{ backgroundColor: `${color?.color_name}` }}
              onClick={() => setCurrentColor(color?.color_name)}
            >
              {(
                selectLanguage === "ar" && color.color_name_ar
                  ? color.color_name_ar === currentColor
                  : color.color_name === currentColor
              ) ? (
                <FaCheck className="text-gray-300" size={12} />
              ) : null}
            </button>
          ))}
        </div>
      </div>
      <div className="my-2 mb-3 flex flex-row items-center">
        <p className="text-base font-semibold">
          {t("add_new_product_Sizes")} :{" "}
          <span className="text-gray-600 text-lg font-semibold capitalize">
            {currentSize}
          </span>
        </p>
        <div className="flex items-center ml-2">
          {sizes.map((size) => (
            <button
              className={`w-[20px] h-[20px]
              bg-black text-white
               mr-2 flex justify-center items-center rounded`}
              key={size?.size_id}
              onClick={() => setCurrentSize(size.size_name)}
            >
              {(
                selectLanguage === "ar" && size?.size_name_ar
                  ? size?.size_name_ar === currentSize
                  : size?.size_name === currentSize
              ) ? (
                <FaCheck className="text-gray-300" size={12} />
              ) : null}
              {/* {selectLanguage==="ar" && size?.size_name_ar ? size?.size_name_ar : size?.size_name} */}
            </button>
          ))}
        </div>
      </div>
      <div
        style={{
          direction: `${selectLanguage === "ar" ? "rtl" : "ltr"}`,
        }}
        className="mt-2 mb-3"
      >
        <p className="text-base font-semibold">
          {t("vd_pc_Category")}: &nbsp;
          <span className="text-gray-800 capitalize">
            {selectLanguage === "ar" && product && product.category_name_ar
              ? product.category_name_ar
              : product
              ? product.category_name
              : ""}
          </span>
        </p>
      </div>
      <div className="mt-2 mb-3">
        <p className="text-base font-semibold">
          {t("vd_pt_Tags")}: &nbsp;
          <span className="text-gray-800 capitalize">
            {tags &&
              tags.map(
                (tag) =>
                  `${
                    selectLanguage == "ar" && tag.tag_name_ar
                      ? tag.tag_name_ar
                      : tag.tag_name
                  }, `
              )}
          </span>
        </p>
      </div>
      <div>
        <p className="text-lg my-8 font-semibold">
          {t("Total_Price")} : <span>{price}</span>
        </p>
      </div>
      <div className="mb-6 flex items-center">
        <div
          className={`flex items-center justify-between bg-gray-100 ${
            selectLanguage == "ar" ? "ml-5" : "mr-5"
          } py-3 rounded-full px-3 w-[140px]`}
        >
          <AiOutlineMinus
            size={20}
            className="text-gray-400 hover:text-black cursor-pointer"
            onClick={handleDecrease}
          />
          <p className="mx-3">{quantity}</p>
          <AiOutlinePlus
            size={20}
            className="text-gray-400 hover:text-black cursor-pointer"
            onClick={handleIncrease}
          />
        </div>
        <div>
          <button
            className="cursor-pointer py-3 px-3 w-[140px] bg-blue-600 hover:bg-blue-700 text-white rounded-full ease-in duration-300"
            onClick={handleCart}
          >
            {selectLanguage == "ar" ? "إضافة إلى السلة" : "Add to cart"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddToCart;
