import React, { useState } from "react";
import i18next from "i18next";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/DashboardPartials/Sidebar/Sidebar";
import DashboardHeader from "../../components/DashboardPartials/DashboardHeader/DashboardHeader";
import "./adminStyles.css";
import { Helmet } from "react-helmet-async";
import logo from "../../assets/logo.png";

const AdminLayout = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  let selectLanguage = i18next.language;
  return (
    <>
      <Helmet>
        <link rel="icon" href={logo} />
      </Helmet>

      <div className="container mx-auto">
        <div className="h-[120px] pt-[26px]">
          <DashboardHeader
            sidebarToggle={sidebarToggle}
            setSidebarToggle={setSidebarToggle}
            role="Admin"
          />
        </div>

        <div id="dashboard">
          <div
            className={`drawer ${
              selectLanguage === "ar" && "drawer-end"
            } h-[85vh] drawer-mobile`}
          >
            <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />

            <div className={`drawer-content flex flex-col bg-[#F6F9FC] `}>
              <div className={`p-5 md:p-5 md:px-12 duration-200`}>
                <Outlet />
              </div>
            </div>

            <Sidebar sidebarToggle={sidebarToggle} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
