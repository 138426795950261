import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../../../components/utils/LoadingSpinner";
import axios from "../../../../components/utils/Interceptors";

const GeneralTab = ({ storeInfo }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { register, setValue, handleSubmit } = useForm();
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (storeInfo) {
      setValue("store_name", storeInfo.store_name);
      setValue(
        "store_description",
        storeInfo.store_description ? storeInfo.store_description : ""
      );
      setValue(
        "store_name_ar",
        storeInfo.store_name_ar ? storeInfo.store_name_ar : storeInfo.store_name
      );
      setValue(
        "store_description_ar",
        storeInfo.store_description_ar ? storeInfo.store_description_ar : ""
      );
      setValue("orderid_format", storeInfo.orderid_format);
      setValue("logo", storeInfo.store_logo);
      setValue("favicon", storeInfo.store_favicon);
    }
  }, [storeInfo]);

  const onSubmit = async (data, event) => {
    setLoading(true);
    const newData = {
      store_name: data.store_name,
      store_description: data.store_description,
      store_name_ar: data.store_name_ar,
      store_description_ar: data.store_description_ar,
      orderid_format: data.orderid_format,
    };
    const formdata = new FormData();

    for (let key in newData) {
      formdata.append(key, data[key]);
    }

    if (data?.logo?.length > 0) {
      formdata.append("logo", data.logo[0]);
    }
    if (data?.favicon?.length > 0) {
      formdata.append("favicon", data.favicon[0]);
    }

    try {
      const baseUrl = process.env.REACT_APP_APIURL;

      const res = await axios.patch(
        `${baseUrl}/vendor/updatestoredetails`,
        formdata,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setLoading(false);
      const response = await res.data;
      if (response.status_code === 200) {
        toast.success("Updated Successfully!");
        window.location.reload();
      } else {
        return toast.error(response.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  // count character
  function countChar(event) {
    var len = event.target.value.length;
    if (len > 100) {
      event.target.value = event.target.value.substring(0, 100);
    } else {
      const ch = document.getElementById("charNum");
      if (ch) {
        ch.innerText = 100 - len+"/"+100;
      }
    }
  }

  function countCharAr(event) {
    var len = [...event.target.value].length;  // Properly count Unicode characters
    if (len > 100) {
      event.target.value = event.target.value.substring(0, 100);
    } else {
      const ch = document.getElementById("charAr");
      if (ch) {
        ch.innerText = 100 - len + "/100";
      }
    }
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        enctype="multipart/form-data"
        className="flex flex-col gap-4"
      >
        <div className="form-control w-full max-w-lg">
          <label className="label">
            <span className="label-text">{t("Store_Name")}</span>
          </label>
          <input
            {...register("store_name")}
            type="text"
            className="input input-bordered w-full max-w-lg rounded-none shadow"
            required
          />
        </div>

        <div className="form-control w-full max-w-lg">
          <label className="label">
            <span className="label-text">
              {t("Store_Name")} {t("in Arabic")}
            </span>
          </label>
          <input
            {...register("store_name_ar")}
            type="text"
            className="input input-bordered w-full max-w-lg rounded-none shadow"
            required
          />
        </div>
        <div className="form-control w-full max-w-lg">
          <label className="label">
            <span className="label-text">{t("Store_Description")}</span>
          </label>
          <textarea
            {...register("store_description")}
            className="textarea textarea-bordered w-full max-w-lg rounded-none shadow"
            maxLength={100}
            onChange={countChar}
          ></textarea>
          <div id="charNum" className="w-full flex justify-end text-xs mt-1">/100</div>
        </div>

        <div className="form-control w-full max-w-lg">
          <label className="label">
            <span className="label-text">
              {t("Store_Description")} {t("in Arabic")}{" "}
            </span>
          </label>
          <textarea
            {...register("store_description_ar")}
            className="textarea textarea-bordered w-full max-w-lg rounded-none shadow"
            maxLength={100}
            onChange={countCharAr}
          ></textarea>
          <div id="charAr" className="w-full flex justify-end text-xs mt-1">/100</div>
        </div>
        <div className="form-control w-full max-w-lg">
          <label className="label">
            <span className="label-text">{t("Store_order_id")}</span>
          </label>
          <input
            {...register("orderid_format")}
            type="text"
            className="input input-bordered w-full max-w-lg rounded-none shadow"
            required
          />
        </div>

        <div className="form-control w-full max-w-lg">
          <label className="label">
            <span className="label-text">
              {t("Logo")} (Size must be 80 X 56)
            </span>
          </label>
          <input
            {...register("logo")}
            type="file"
            className="input w-full max-w-lg rounded-none -ml-4"
          />
          {storeInfo?.store_logo && (
            <div>
              <img
                src={storeInfo?.store_logo}
                alt="logo"
                className="w-12 h-12 border p-2 object-contain"
              />
            </div>
          )}
        </div>
        <div className="form-control w-full max-w-lg">
          <label className="label">
            <span className="label-text">
              {t("Favicon")} (Size must be 32 X 32)
            </span>
          </label>
          <input
            {...register("favicon")}
            type="file"
            className="input w-full max-w-lg rounded-none -ml-4"
          />
          {storeInfo?.store_favicon && (
            <div>
              <img
                src={storeInfo?.store_favicon}
                alt="favicon"
                className="w-12 h-12 border p-2 object-contain"
              />
            </div>
          )}
        </div>

        <input
          type="submit"
          className="btn btn-sm rounded btn-primary text-white inline max-w-fit px-4"
          value={`${t("vd_pc_btn")}`}
        />
      </form>
    </div>
  );
};

export default GeneralTab;
