import React from 'react';

const FilterComponent2 = ({ filterText, setFilterText, onFilter, onClear, placeholder }) => {

    const handleClear = () => {
        setFilterText("")
        let component = document.getElementById("search")
        component.value = ""
    }
    return (
        <>
            <input
                className="input input-bordered rounded-none input-sm"
                id="search"
                type="text"
                placeholder={placeholder || 'Filter by Name'}
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
            />
            <button className="btn btn-sm btn-error rounded-none text-white" type="button" onClick={() => handleClear()}>
                X
            </button>
        </>
    );
};

export default FilterComponent2;