import axios from "axios";
import { toast } from "react-toastify";

const customdomain = localStorage.getItem("customdomain");
// custom instance to make HTTP request anywhere in the code
const customInstance = axios.create({
  baseURL: `${process.env.REACT_APP_APIURL}`,
  headers: {
    "Content-type": "application/json",
  },
});

//  to access token from localstorage and include in all apis header
customInstance.interceptors.request.use(
  async (config) => {
    const user = JSON.parse(localStorage.getItem("customer"));
    const token = user.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    if (config.data instanceof FormData) {
      // If it is, set the content type header to 'multipart/form-data'
      config.headers['Content-Type'] = 'multipart/form-data';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

customInstance.interceptors.response.use(
  (response) => {
    const originalRequest = response.config;

    if (response.data.status_code === 403) {
      localStorage.clear();
      window.location.href =
        customdomain === "aitech.com.sa"
          ? "/:store_username/register-login"
          : "/register-login";
      return;
    }

    //  calling refresh token api
    if (response.data.status_code === 401) {
      originalRequest._retry = true;

      const user = JSON.parse(localStorage.getItem("customer"));
      const refreshToken = user.refreshToken;
      if (refreshToken) {
        console.log("calling request");
        return axios
          .get(`${process.env.REACT_APP_APIURL}/store/customer/refreshtoken`, {
            headers: {
              refreshtoken: refreshToken,
            },
          })
          .then((res) => {
            if (res.status === 201 || res.status === 200) {
              var user = JSON.parse(localStorage.getItem("customer"));
              user.token = res.data.data.accessToken;
              user.refreshToken = res.data.data.refToken;
              localStorage.setItem("customer", JSON.stringify(user));

              originalRequest.headers["Authorization"] = `Bearer ${user.token}`;
              return axios(originalRequest);
            }
          })
          .catch((err) => {
            localStorage.clear();
            window.location.href =
              customdomain === "aitech.com.sa"
                ? "/:store_username/register-login"
                : "/register-login";
          });
      } else {
        console.log("No ref token was found");
        localStorage.clear();
        return (window.location.href =
          customdomain === "aitech.com.sa"
            ? "/:store_username/register-login"
            : "/register-login");
      }
    }
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (error) {
      console.warn(error);
      // if refresh token is not provided
      if (
        error.response.status === 401 &&
        originalRequest.url ===
          `${process.env.REACT_APP_APIURL}/store/customer/refreshtoken`
      ) {
        return toast.error("Refresh token is not provided");
      }

      //   if refresh token expired
      if (error.response.status === 403) {
        localStorage.clear();
        window.location.href =
          customdomain === "aitech.com.sa"
            ? "/:store_username/register-login"
            : "/register-login";
        return Promise.reject(error);
      }

      //   calling refresh token api
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const user = JSON.parse(localStorage.getItem("customer"));
        const refreshToken = user.refreshToken;
        // const refreshToken = localStorage.getItem("refresh token");
        if (refreshToken) {
          console.log("calling request");
          return axios
            .get(
              `${process.env.REACT_APP_APIURL}/store/customer/refreshtoken`,
              {
                headers: {
                  refreshtoken: refreshToken,
                },
              }
            )
            .then((res) => {
              if (res.status === 201 || res.status === 200) {
                var user = JSON.parse(localStorage.getItem("customer"));
                user.token = res.data.data.accessToken;
                user.refreshToken = res.data.data.refToken;
                localStorage.setItem("customer", JSON.stringify(user));

                originalRequest.headers[
                  "Authorization"
                ] = `Bearer ${user.token}`;
                return axios(originalRequest);
              }
            })
            .catch((err) => {
              localStorage.clear();
              window.location.href =
                customdomain === "aitech.com.sa"
                  ? "/:store_username/register-login"
                  : "/register-login";
            });
        } else {
          console.log("No ref token was found");
          localStorage.clear();
          return (window.location.href =
            customdomain === "aitech.com.sa"
              ? "/:store_username/register-login"
              : "/register-login");
        }
      }
    }
    return Promise.reject(error);
  }
);

export default customInstance;
