import React, { useContext, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useEffect } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { domainProvider } from "../../../contexts/DomainContext";
import axios from "axios";

const UserResetPass = () => {
  const storeName = window.location.pathname.split("/")[1];
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { register, reset, handleSubmit } = useForm();
  const { customdomain } = useContext(domainProvider);

  if (!token) {
    navigate("/");
  }

  useEffect(() => {
    var user = localStorage.getItem("customer");
    user = JSON.parse(user);
    if (user?.data?.cust_id) {
      if (customdomain === "aitech.com.sa") navigate(`/${storeName}`);
      else navigate("/");
    }
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    if (data.password1 !== data.password2) {
      toast.error("Both Field Should Be Same");
      setLoading(false);
      return;
    }
    const formData = {
      password: data.password1,
    };
    const res = await fetch(
      `${process.env.REACT_APP_APIURL}/vendor/store/customer/update_password`,
      {
        method: "PATCH",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const response = await res.json();
    if (
      response.status_code === 200 ||
      response.message === "password updated successfully"
    ) {
      toast.success(response.message);
      reset();
      if (customdomain === "aitech.com.sa")
        navigate(`/${storeName}/register-login`);
      else navigate(`/register-login`);
    } else {
      toast.error(response.message);
    }

    setLoading(false);
  };

  const selectLanguage = i18next.language;
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Forget Password</title>
      </Helmet>
      <section className="px-2 md:px-5 lg:px-20 py-5">
        <div className="container place-content-center mx-auto">
          <div className="registration-form max-w-md mx-auto  px-2 md:px-8">
            <form
              className="flex flex-col gap-y-2 bg-white rounded-2xl p-5 border border-gray-200 shadow-xl py-10 justify-center w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h2 className="text-center text-xl md:text-2xl mb-10">
                {t("Reset")} {t("New")} {t("l_p")}
              </h2>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">
                    {t("New")} {t("l_p")}
                  </span>
                </label>

                <input
                  {...register("password1", { required: true })}
                  type="password"
                  placeholder={t("l_p")}
                  className="input input-sm input-bordered py-4 focus:outline-none rounded-none w-full "
                  required
                />
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">{t("l_c_p_p")}</span>
                </label>

                <input
                  {...register("password2", { required: true })}
                  type="password"
                  placeholder={t("l_c_p_p")}
                  className="input input-sm input-bordered py-4 focus:outline-none rounded-none w-full "
                  required
                />
              </div>

              <div className="form-control w-full">
                <button
                  type="submit"
                  className={`btn cursor-pointer mt-4 btn-primary text-white w-full  rounded-none ${
                    loading && "loading"
                  }`}
                >
                  {t("vd_pc_btn2")}
                </button>

                {/* <input className="py-2 cursor-pointer btn-primary text-white w-full  rounded-none" type="submit" value="Login" /> */}
              </div>
            </form>

            <div className="flex flex-col w-full">
              <Link
                to={`${
                  customdomain === "aitech.com.sa"
                    ? `/${storeName}/register-login`
                    : `/register-login`
                }`}
                className="divider text-primary"
              >
                {selectLanguage === "ar"
                  ? "العودة إلى تسجيل الدخول"
                  : "Back to Login"}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserResetPass;
