import React from "react";
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import { SlPeople } from "react-icons/sl";
import { BsCartCheck, BsHandbag } from "react-icons/bs";
import { FiShoppingBag } from "react-icons/fi";
import "swiper/swiper.min.css";
import "./Dashboard.css";
import { useTranslation } from "react-i18next";
import orderCancelImage from "../../assets/icons/dashboard/order_cancelled.png";
import deleveryImage from "../../assets/icons/dashboard/order_delivered.png";
import placeOrderImage from "../../assets/icons/dashboard/order_placed.png";
import shipOrderImage from "../../assets/icons/dashboard/order_shipped.png";
import newDbImg from "../../assets/dashboard/image1.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { AiOutlineFundView, AiFillEye } from "react-icons/ai";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import TopProductsSlider from "./TopProductsSlider";
import i18next from "i18next";
import axios from "../../components/utils/Interceptors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const { t } = useTranslation();
  SwiperCore.use([EffectCoverflow, Autoplay, Pagination]);
  const [latestOrders, setLatestOrders] = useState([]);
  const [dashboardData, setdashboardData] = useState(null);
  const [topProducts, setTopProducts] = useState([]);
  const [currentPlan, setCurrentPlan] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [orderCount, setOrderCount] = useState(0);
  const [earnings, setEarnings] = useState(0);
  const [date, setDate] = useState("");

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_APIURL;

    const fetchLatestOrders = async () => {
      const res = await axios.get(`${baseUrl}/vendor/getallorder`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const response = res.data;
      if (response.status_code === 200) {
        setLatestOrders(response.data.slice(-3));

        let order = 0;
        let total = 0;
        response.data.map((d) => {
          if (d.order_status === 2) {
            order += 1;
            total += d.order_price;
          }
        });
        setOrderCount(order);
        setEarnings(total);
      }
    };

    const fetchDashboardDetails = async () => {
      const res = await axios.get(`${baseUrl}/vendor/getdashboarddetails`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const response = res.data;
      if (response.status_code === 200) {
        setdashboardData(response);
        setGraphData(response.monthWiseSales);
      }
    };

    const fetchTopProducts = async () => {
      const res = await axios.get(`${baseUrl}/vendor/gettopproducts`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const response = res.data;
      if (response.status_code === 200) {
        if (response.data.length > 0) {
          setTopProducts(response.data);
        }
      }
    };

    const fetchCurrentPlan = async () => {
      const res = await axios.get(`${baseUrl}/vendor/getcurrentplan`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const response = res.data;
      if (response.data[0].to_date) {
        var x = new Date(response.data[0].to_date);
        setDate(x.getDate() + "/" + x.getMonth() + "/" + x.getFullYear());
      }

      if (response.status_code === 200) {
        if (response.data.length > 0) {
          setCurrentPlan(response.data[0]);
        }
      }
    };

    fetchLatestOrders();
    fetchDashboardDetails();
    fetchTopProducts();
    fetchCurrentPlan();
  }, []);

  const options221 = {
    legend: {
      display: false,
    },

    maintainAspectRatio: false,
  };

  const data221 = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "SEPT",
      "OCT",
      "NOV",
      "DEC",
    ],
    datasets: [
      {
        label: `Sales status`,
        backgroundColor: "#F4900C",
        borderColor: "#F4900C",
        borderWidth: 1,
        hoverBackgroundColor: "#F4900C",
        hoverBorderColor: "[#236260]",
        data: graphData,
      },
    ],
  };

  const selectLanguage = i18next.language;

  return (
    <>
      <Helmet>
        <title>{t("vender_dash")}</title>
      </Helmet>
      <h2 className="text-title text-lg md:text-2xl pb-2">
        {t("v_dash_title")}
      </h2>

      <div>
        <p className="alert bg-[#8EDDD6] text-black text-[15px] mb-3 rounded justify-start gap-2 py-3">
          {selectLanguage == "ar"
            ? `أنت تستخدم حاليا ${currentPlan?.plan_name} الحزمة التي ستنتهي صلاحيتها في ${date} (dd/mm/yyyy)`
            : `You are currently using ${currentPlan?.plan_name} Package which will Expire on ${date} (dd/mm/yyyy)`}
        </p>

        {/* First ROW */}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mb-5">
          <div className="p-4 dashboard-box border border-gray-200 bg-white shadow-sm md:col-span-2">
            <p className="text-gray-500 text-sm pl-6 text-center">
              {t("v_dash_row1_title")}
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 h-full">
              <div className="justify-center items-center flex">
                <img src={newDbImg} className="w-64" alt="dashboard image" />
              </div>
              <div className="reports pb-10 md:p-5 grid grid-cols-2 gap-4 h-full">
                <Link
                  to="/vendor/orders"
                  className="report-icons flex items-center justify-start gap-x-2 roboto font-medium"
                >
                  <div className="p-2 bg-primary rounded-full border border-primary">
                    <BsCartCheck size={30} className="text-white" />
                  </div>
                  <p className="text-xs">
                    {orderCount || 0} <br />
                    {t("v_dash_row1_totalOr")}
                  </p>
                </Link>
                <Link
                  to="/vendor/products"
                  className="report-icons flex items-center justify-start gap-x-2 roboto font-medium"
                >
                  <div className="p-2 bg-primary rounded-full border border-primary">
                    <FiShoppingBag size={30} className="text-white" />
                  </div>

                  <p className="text-xs">
                    {dashboardData?.products?.count || 0} <br />
                    {t("v_dash_row1_totalPro")}
                  </p>
                </Link>

                <Link
                  to="/vendor/payouts"
                  className="report-icons flex items-center justify-start gap-x-2 roboto font-medium"
                >
                  <div className="p-2 bg-primary rounded-full border border-primary">
                    <AiOutlineFundView size={30} className="text-white" />
                  </div>

                  <p className="text-xs">
                    {earnings.toFixed(2) || 0} <br />
                    {t("v_dash_row1_totalEarn")}
                  </p>
                </Link>

                <Link
                  to="/vendor/customers"
                  className="report-icons flex items-center justify-start gap-x-2 roboto font-medium"
                >
                  {/* bg-primary p-2 */}
                  <div className="p-2 bg-primary rounded-full border border-primary">
                    <SlPeople size={30} className="text-white" />
                  </div>
                  <p className="text-xs">
                    {dashboardData?.customers?.count || 0} <br />
                    {t("v_dash_row1_totalCust")}
                  </p>
                </Link>
              </div>
            </div>
          </div>

          {/* current package */}
          <div className="p-4 dashboard-box rounded flex flex-col gap-y-3 items-center justify-center border border-gray-200 bg-white shadow-sm">
            <p className="text-gray-500 text-md text-center font-semibold">
              {t("v_dash_current_p")}
            </p>

            <div className="mt-2 flex justify-center items-center flex-col">
              <img
                className="w-20"
                src={
                  currentPlan && currentPlan.plan_logo
                    ? currentPlan.plan_logo
                    : "https://shop.activeitzone.com/public/uploads/all/OW97HwmGEl8khZJRzGIfIVOg2hut6q6cc8bZ2fXk.png"
                }
                alt="package"
              />
              <p className="text-sm md:text-lg text-center font-bold mt-2">
                {currentPlan?.plan_name}
              </p>
            </div>
            <div className="divider my-0"></div>
            <div className="flex text-[15px] flex-col gap-y-2">
              <p>
                {t("v_dash_current_p_e")} : <span>{currentPlan?.to_date}</span>{" "}
              </p>
              <p>
                {t("v_dash_ramain_Pro")} :{" "}
                <span>
                  {" "}
                  {dashboardData?.products?.count} /{" "}
                  {currentPlan?.product_limit}
                </span>{" "}
              </p>
              <p>
                {t("v_dash_ramain_Cus")} :{" "}
                <span>
                  {dashboardData?.customers?.count} /{" "}
                  {currentPlan?.product_limit}
                </span>{" "}
              </p>
            </div>
            <div className="text-center mx-auto my-2">
              <Link
                to="/vendor/packages"
                className="py-2 px-3 bg-primary text-white hover:!text-white rounded cursor-pointer"
                title="upgrade Button"
              >
                {t("v_dash_upgratePack")}
              </Link>
            </div>
          </div>
        </div>

        {/* second ROW */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          {/* Latest Orders */}
          <div className="p-4 dashboard-box  flex flex-col border border-gray-200 bg-white shadow-sm md:col-span-2">
            <div className="flex justify-between items-center">
              <p className="text-black text-base font-bold self-start">
                {t("latest_order")}
              </p>
              <Link
                to="/vendor/orders"
                className="btn bg-primary hover:bg-primary btn-sm rounded "
              >
                <span className="!text-white">{t("view_all")}</span>
              </Link>
            </div>

            <div className="w-full py-5">
              <div className="overflow-x-auto">
                <table className="table w-full roboto font-bold text-13 text-sm">
                  <thead>
                    <tr>
                      <th>{t("order_table1")}</th>
                      <th>{t("order_table2")}</th>
                      <th>{t("order_table3")}</th>
                      <th>{t("order_table4")}</th>
                      <th>{t("order_table5")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {latestOrders &&
                      latestOrders.map((lOrder, id) => (
                        <tr key={id}>
                          <th>{lOrder?.order_number}</th>
                          <td>{lOrder?.customer_email}</td>
                          <td>{lOrder?.order_price} ر.س</td>
                          <td>
                            {moment(lOrder?.created_on).format("DD-MMM-YYYY")}
                          </td>
                          <td>
                            {lOrder.order_status === 0
                              ? "Undelivered"
                              : lOrder.order_status === 1
                              ? "Shipped"
                              : lOrder.order_status === 2
                              ? "delivered"
                              : lOrder.order_status === 4
                              ? "return requested"
                              : lOrder.order_status === 5
                              ? "requested Accepted"
                              : lOrder.order_status === 6
                              ? "Out for pickup"
                              : lOrder.order_status === 7
                              ? "Pickup completed"
                              : lOrder.order_status === 8
                              ? "Refund successful"
                              : lOrder.order_status === 9
                              ? "cancelled"
                              : "NA"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* order reports */}
          {dashboardData && (
            <div className="flex flex-col justify-between">
              <div className="rounded-lg p-4 dashboard-box border mb-4 bg-white text-sm">
                <Link
                  to={"/vendor/orders?status=0"}
                  className="py-1 flex items-center justify-between"
                >
                  <img
                    className="w-6"
                    src={placeOrderImage}
                    alt="placeOrderImage"
                  />
                  <div className="flex-grow-1 font-bold text-13 mx-4">
                    {t("order_place")}
                  </div>
                  <div className="fs-20 fw-600" style={{ color: "#FF8264" }}>
                    {dashboardData?.statuswiseorders[0]?.count || 0}
                  </div>
                </Link>
              </div>

              <div className="rounded-lg p-4 dashboard-box bg-white border mb-4  text-sm">
                <Link
                  to={"/vendor/orders?status=1"}
                  className="py-1 flex items-center justify-between"
                >
                  <img
                    className="w-6"
                    src={shipOrderImage}
                    alt="shipOrderImage"
                  />
                  <div className="flex-grow-1 mx-4 font-bold text-13">
                    {t("shipped_Order")}
                  </div>
                  <div className="fs-20 fw-600" style={{ color: "#7BC4C4" }}>
                    {dashboardData?.statuswiseorders[1]?.count || 0}
                  </div>
                </Link>
              </div>
              <div className="rounded-lg p-4 dashboard-box bg-white border mb-4 bg-light text-sm">
                <Link
                  to={"/vendor/orders?status=2"}
                  className="py-1 flex items-center justify-between"
                >
                  <img
                    className="w-6"
                    src={deleveryImage}
                    alt="deleveryImage"
                  />
                  <div className="flex-grow-1 font-bold text-13 mx-4">
                    {t("deliver_Order")}
                  </div>
                  <div className="fs-20 fw-600" style={{ color: "#7BC4C4" }}>
                    {dashboardData?.statuswiseorders[2]?.count || 0}
                  </div>
                </Link>
              </div>
              <div className="rounded-lg p-4 dashboard-box bg-white border bg-light text-sm">
                <Link
                  to={"/vendor/orders?status=9"}
                  className="py-1 flex items-center justify-between"
                >
                  <img
                    className="w-6"
                    src={orderCancelImage}
                    alt="orderCancelImage"
                  />

                  <div className="flex-grow-1 font-bold text-13 mx-4">
                    {t("cancel_Order")}
                  </div>
                  <div className="fs-20 fw-600" style={{ color: "#FF6F61" }}>
                    {dashboardData?.statuswiseorders[9]?.count || 0}
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>

        {/* Third Row */}
        <div className="mt-10 grid grid-cols-1 md:grid-cols-2  gap-5 mb-8">
          <div className="p-4 dashboard-box flex flex-col justify-center items-center border border-gray-200 bg-white shadow-sm col-span-2">
            <p className="text-gray-500 text-sm md:text-md self-start uppercase">
              {selectLanguage == "ar" ? "حالة المبيعات" : "Sales status"}
            </p>

            <div className="chart w-full md:p-5">
              <Bar data={data221} height={350} options={options221} />
            </div>
          </div>
        </div>

        {/* Top Products */}
        <div className="my-12 mb-16">
          <TopProductsSlider type={"vender"} user={user} dashboard={"Vendor"} />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
