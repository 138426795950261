import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { GoArrowSmallDown } from "react-icons/go";
import axios from "axios";

const ChangeLanguage = () => {
  const [languages, setLanguages] = useState([]);
  const [show, setShow] = useState(false);
  const [language, setLanguage] = useState();
  let selectLanguage = i18next.language;

  useEffect(() => {
    const getAllLanguage = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/admin/getalllanguage`
      );
      const response = await res.data;
      if (response.status_code === 200 && response.data) {
        const language = response.data.map((lng) => {
          const obj = {
            image: lng.image,
            full_name: lng.language_full_name,
            short_name: lng.language_short_name,
          };
          return obj;
        });
        const lan = language.find(
          (langu) => langu.short_name === selectLanguage
        );
        setLanguage(lan);
        setLanguages(language);
      }
    };
    getAllLanguage();
  }, [selectLanguage]);

  const changeLanguage = (value) => {
    i18next.changeLanguage(value);
    setShow(false);
  };

  return (
    <>
      <div className="dropdown dropdown-end">
        <label
          onClick={() => setShow(true)}
          tabIndex="0"
          className="px-2 cursor-pointer text-[16px] text-gray-600 m-1 hover:text-primary flex items-center"
        >
          <span className={`${selectLanguage === "ar" ? "ml-2" : "mr-2"}`}>
            <i className={`menu-icon fa fa-earth fa-lg mr-1`}></i>
          </span>
          <span className="">
            <p className="text-base mr-0">{language?.full_name}</p>
          </span>
          <span className="">
            <GoArrowSmallDown className="text-[1.7rem] mr-0 ml-[-6px]" />
          </span>
        </label>
        {show && (
          <ul
            tabIndex="0"
            className="dropdown-content menu shadow bg-base-100 text-black rounded w-48 text-xs"
          >
            {languages.map((lng, idx) => (
              <li key={idx} onClick={() => changeLanguage(lng.short_name)}>
                <div className="flex items-center gap-x-2">
                  <img src={lng.image} className="w-4" alt="" />
                  {lng.full_name}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default ChangeLanguage;
