import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { FaEdit } from "react-icons/fa";
import FilterComponent from "../../../components/utils/FilterComponent";

const columns = [
  {
    name: "Domain Name",
    selector: (row) => row.domainName,
    sortable: true,
  },

  {
    name: "Full Domain",
    selector: (row) => row.fullDomain,
    sortable: true,
  },

  {
    name: "User",
    selector: (row) => row.user,
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
  },

  {
    name: "Created At",
    selector: (row) => row.created_at,
    sortable: true,
  },
  {
    name: "Action",
    key: "action",
    text: "Action",
    className: "action",
    width: 100,
    align: "left",
    sortable: false,
    cell: (record) => {
      return (
        <>
          <div className="flex gap-2">
            <span
              className="p-2 flex items-center border border-primary bg-primary rounded-full text-white hover:bg-white hover:text-primary cursor-pointer duration-300 shadow-md"
              title="View"
            >
              <FaEdit />
            </span>
          </div>
        </>
      );
    },
  },
];

const data = [
  {
    id: 2,
    domainName: "rihan.aitech.sa",
    fullDomain: "http://rihan.aitech.sa",
    user: "rihan",
    status: "Pending",
    created_at: "2 Days ago",
  },
];

const CustomDomain = () => {
  const [selectedData, setSelectedData] = useState();

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  let filteredItems = data.filter((item) =>
    item.domainName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="flex items-center  gap-2 flex-wrap my-5">
        <div className="justify-self-start flex items-center">
          <select className="select select-sm rounded select-bordered">
            <option disabled selected>
              Choose Action
            </option>
            <option>Publish</option>
            <option>Suspend</option>
            <option>Move to Trash</option>
          </select>
          <button className="ml-5 btn-xs btn btn-primary text-white font-normal md:btn-sm rounded">
            Process
          </button>
        </div>

        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="Filter by Domain name"
          setFilterText={setFilterText}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const handleChange = (state) => {
    setSelectedData(state.selectedRows);
  };

  return (
    <>
      <h2 className="text-title text-lg md:text-2xl pb-2">All Domains</h2>
      <div className="dashboard-box">
        <DataTable
          columns={columns}
          data={filteredItems}
          // selectableRows
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          onSelectedRowsChange={handleChange}
        />
      </div>
    </>
  );
};

export default CustomDomain;
