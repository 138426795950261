import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

const useAdmin = () => {
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);

  useEffect(() => {
    setUserLoading(true);
    const _user = JSON.parse(localStorage.getItem("admin"));

    if (_user) {
      setUserLoading(false);
      setUser(_user);
    } else {
      setUserLoading(false);
    }
  }, []);

  return [user, userLoading, setUser];
};

export default useAdmin;
