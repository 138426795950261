import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiFillDashboard } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { storeInformation } from "../../../contexts/StoreInformationContext";
import { toast } from "react-toastify";
import axios from "../../../components/utils/Interceptors";

const userInfo = JSON.parse(localStorage.getItem("user"));

const ShopHeader = () => {
  const { storeInfo } = useContext(storeInformation);
  const navigate = useNavigate();
  const logOutUser = async () => {
    const res2 = await axios.post(
      `${process.env.REACT_APP_APIURL}/vendor/logout`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );
    const response2 = await res2.data;
    if (response2.status_code != 200) {
      return toast.error(response2.message);
    }
    toast.success(response2.message);
    localStorage.clear();
    navigate("/");
  };
  return (
    <div className="py-1 px-5 bg-black mb-[-26px] text-gray-400">
      <div className="text-[13px] flex justify-between">
        <div>
          <Link
            to="/vendor/dashboard"
            className="flex items-center cursor-pointer hover:text-primary"
          >
            <AiFillDashboard size={15} />
            <p className="ml-1">Dashboard</p>
          </Link>
        </div>
        <div>
          <div className="dropdown dropdown-end flex items-center">
            <label tabIndex="0" className="">
              <div className="flex items-center">
                <p className="mr-1">{storeInfo?.store_name}</p>
                <FaUser size={10} />
              </div>
            </label>
            <ul
              tabIndex="0"
              className="menu menu-compact dropdown-content mt-20 p-2 shadow rounded-box w-52 text-white bg-black"
            >
              <li className="rounded-xl" onClick={logOutUser}>
                <p>Logout</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopHeader;
