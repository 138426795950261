import moment from "moment";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { FaEdit } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FilterComponent from "../../../components/utils/FilterComponent";
import getOrderStatus from "../../../components/utils/getOrderStatus";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import updateData from "../../../hooks/updateData";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import axios from "../../../components/utils/Interceptors"

const AllOrders = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("status");
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(true);
  let selectLanguage = i18next.language;
  const navigate = useNavigate();
  const [showLink, setShowLink] = useState(false);

  const getAllOrders = async (status_id) => {
    let url;
    if (status_id >= 0) {
      url = `${process.env.REACT_APP_APIURL}/vendor/getallorder?status=${status_id}`;
    } else {
      url = `${process.env.REACT_APP_APIURL}/vendor/getallorder`;
    }

    const res = await axios.get(url, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });
    const response = await res.data;

    if (response.status_code === 200) {
      setOrders(response.data.reverse());
    } else {
      setOrders([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  const handleActionClick = (value) => {
    if (value.type === "show") {
      setShowLink(value.value);
    }
    if (value.type === "unShow") {
      setShowLink(value.value);
    }
  };

  const handleReciptDownload = ({ triackingId, orderId }) => {
    const baseUrl = process.env.REACT_APP_APIURL;
    const fetchAllCustomerOrder = async () => {
      setLoading(true);
      const res = await axios.get(
        `${baseUrl}/vendor/trackorder?tracking_id=${triackingId}`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;

      if (response.tracking_data) {
        const link = document.createElement("a");
        link.download = "Example-PDF-File";

        link.href = response.aws_label;

        link.click();
      } else {
        toast.error("Tracking Id is not Correct");
      }
      setLoading(false);
    };
    fetchAllCustomerOrder();
  };

  const columns = [
    {
      name: `${t("V_allOrder_or_id")}`,
      selector: (row) => row.torod_order_id,
      sortable: true,
    },
    {
      name: `${t("V_allOrder_or_td")}`,
      selector: (row) => moment(row.created_on).format("DD-MMM-YYYY hh:mm a"),
      sortable: true,
    },

    {
      name: `${t("V_allOrder_or_c")}`,
      selector: (row) => row.customer_email,
    },
    {
      name: `${t("V_allOrder_or_a")}`,
      selector: (row) => row.order_price,
      cell: (row) => `${row.order_price} ر.س`,
      sortable: true,
    },
    {
      name: `${t("V_allOrder_or_st")}`,
      selector: (row) => row.order_status,
      cell: (row) => getOrderStatus(row.order_status, "", { t }),
    },

    {
      name: `${t("all_product_dataTable6")}`,
      cell: (item) => (
        <div className="flex gap-2">
          <div className="dropdown dropdown-left dropdown-end">
            <label tabIndex="2" className="btn btn-ghost btn-circle avatar">
              <span
                onClick={() => handleActionClick({ type: "show", value: true })}
                className="p-2 flex items-center border border-primary bg-primary rounded-full text-white hover:bg-white hover:text-primary cursor-pointer duration-300 shadow-md"
                title="Actions"
              >
                <FaEdit />
              </span>
            </label>
            {showLink && (
              <ul
                tabIndex="2"
                onClick={() =>
                  handleActionClick({ type: "unShow", value: false })
                }
                className={` menu menu-compact bg-slate-100 dropdown-content mt-40 p-2 shadow rounded-md w-52 text-black`}
              >
                <li>
                  <Link
                    to={`/vendor/orders/order_details/${item.order_id}`}
                    className="justify-between "
                  >
                    <p className="text-[15px]"> {`${t("View_Details")}`}</p>
                  </Link>
                </li>
                {item.torod_tracking_id && (
                  <>
                    <li>
                      <p
                        onClick={() => handleToTrack(item.torod_tracking_id)}
                        className="text-[15px]"
                      >
                        {`${t("Track")}`}
                      </p>
                    </li>
                    <li>
                      <p
                        onClick={() =>
                          handleReciptDownload({
                            triackingId: item.torod_tracking_id,
                            orderId: item.torod_order_id,
                          })
                        }
                        className="text-[15px]"
                      >
                        Download Receipt
                      </p>
                    </li>
                  </>
                )}
                {item.order_status === 0 && (
                  <>
                    <li className="">
                      <label
                        onClick={() => updateStatus({ item, value: 1 })}
                        htmlFor="changeStatusModal"
                        className="text-[15px] hover:bg-[#236260]"
                      >
                        <p className="text-[15px]">Confirm Shipped</p>
                      </label>
                    </li>
                    <li className="">
                      <label
                        onClick={() => updateStatus({ item, value: 9 })}
                        htmlFor="changeStatusModal"
                        className="text-[15px] hover:bg-red-400"
                      >
                        <p className="text-[15px]">{`${t(
                          "V_allOrder_or_cl"
                        )}`}</p>
                      </label>
                    </li>
                  </>
                )}
              </ul>
            )}
          </div>
        </div>
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  let filteredItems = orders?.filter(
    (item) =>
      item.order_number?.includes(filterText) ||
      item.customer_email?.includes(filterText)
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="flex items-center gap-2 flex-wrap my-5">
        <button
          onClick={() => filterByStatus(-1)}
          className="btn btn-sm rounded"
        >
          {t("all")}{" "}
        </button>
        <button
          onClick={() => filterByStatus(0)}
          className="btn btn-sm btn-warning text-white rounded"
        >
          {t("pending")}
        </button>
        <button
          onClick={() => filterByStatus(1)}
          className="btn btn-sm btn-info text-white rounded"
        >
          {t("shipped")}
        </button>
        <button
          onClick={() => filterByStatus(2)}
          className="btn btn-sm btn-success text-white rounded"
        >
          {t("delievered")}
        </button>
        <button
          onClick={() => filterByStatus(9)}
          className="btn btn-sm btn-error text-white rounded"
        >
          {t("cancelled")}
        </button>

        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle, selectLanguage]);

  const filterByStatus = (status_id) => {
    setLoading(true);

    if (status_id >= 0) {
      filteredItems = orders?.filter((item) => item.order_status === status_id);
    } else {
      filteredItems = orders?.filter(
        (item) =>
          item.order_number?.includes(filterText) ||
          item.customer_email?.includes(filterText)
      );
    }
    getAllOrders(status_id);
    setLoading(false);
  };

  const updateStatus = async ({ item, value }) => {
    let status = value;
    setLoading(true);
    const data = {
      order_id: item.order_id,
      order_status: status,
    };

    const res = await updateData("vendor/updateorder", data);
    if (res.status_code === 200) {
      toast.success("Updated Status!");
      const newOrders = orders.map((order) => {
        if (order.order_id === item.order_id) {
          return {
            ...order,
            order_status: status,
          };
        }
        return order;
      });
      setOrders(newOrders);
      setLoading(false);
    } else {
      return toast.error(res.message || res.err);
    }
    setLoading(false);
  };

  const handleToTrack = async (id) => {
    if (id) {
      navigate(`/vendor/orders/track-order`, {
        state: { id: id, token: user?.token },
      });
    } else {
      toast.error("tracking not possible");
    }
  };

  const customStyles = {
    headCells: {
      style: {
        marginTop: "25px", // override the cell padding for head cells
        marginBottom: "25px",
      },
    },
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="text-title text-lg md:text-2xl pb-2">
          {t("All_Orders")}
        </h2>
        <div className="hidden">
          <Link
            to="/vendor/add-order"
            className="py-2 text-sm px-4 bg-primary text-white rounded font-normal"
          >
            {t("V_allOrder_add")}
          </Link>
        </div>
      </div>
      <div className="py-7">
        <DataTable
          className="pt-9"
          columns={columns}
          data={filteredItems}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          // selectableRows
          pagination
          customStyles={customStyles}
          noDataComponent={
            <h4 className="py-8 text-gray-400 text-center">
              {t("No records")}
            </h4>
          }
        />
      </div>
    </>
  );
};

export default AllOrders;
