import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import {
  AiFillCheckCircle,
  AiFillDelete,
  AiFillMinusCircle,
  AiTwotoneEdit,
} from "react-icons/ai";
import FilterComponent from "../../../components/utils/FilterComponent";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import updateData from "../../../hooks/admin/updateData";
import { toast } from "react-toastify";
import deleteItem from "../../../hooks/admin/deleteItem";

export default function CustomerTable(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { customerData, isSuspended } = props;
  const [selectedData, setSelectedData] = useState(customerData);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  let filteredItems = selectedData?.filter((item) =>
    item.cust_fullname.toLowerCase().includes(filterText.toLowerCase())
  );

  const columns = [
    {
      name: "Name",
      selector: (row) => row.cust_fullname,
      sortable: true,
    },

    {
      name: "Email",
      selector: (row) => row.cust_email,
      sortable: true,
    },

    {
      name: "Phone number",
      selector: (row) => row.cust_phone,
      sortable: true,
    },
    {
      name: "Domain",
      selector: (row) => row.joined_at,
      sortable: true,
    },
    {
      name: "Action",
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <>
            <div className="flex gap-2">
              <Link
                to={`/admin/customer/edit/${record.cust_id}`}
                className="p-2 flex items-center border border-success hover:bg-success rounded-full text-success hover:text-white cursor-pointer duration-300"
                title={`${t("Edit")}`}
              >
                <AiTwotoneEdit />
              </Link>
              <span
                onClick={() =>
                  deleteItem(
                    record.cust_id,
                    selectedData,
                    setSelectedData,
                    filteredItems,
                    "cust_id",
                    "admin/deletecustomer"
                  )
                }
                className="p-2 flex items-center border border-primary hover:bg-primary rounded-full text-primary hover:text-white cursor-pointer duration-300"
                title={`${t("Delete")}`}
              >
                <AiFillDelete />
              </span>
              {record.status === 0 ? (
                <span
                  onClick={() =>
                    changeCustStatus(record.cust_id, record.status)
                  }
                  className="p-2 flex items-center border border-green-700 hover:bg-green-700 rounded-full text-green-700 hover:text-white cursor-pointer duration-300"
                  title={`${t("unsuspend")}`}
                >
                  <AiFillCheckCircle />
                </span>
              ) : (
                <span
                  onClick={() =>
                    changeCustStatus(record.cust_id, record.status)
                  }
                  className="p-2 flex items-center border border-red-700 hover:bg-red-700 rounded-full text-red-700 hover:text-white cursor-pointer duration-300"
                  title={`${t("Suspend")}`}
                >
                  <AiFillMinusCircle />
                </span>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const changeCustStatus = async (id, status) => {
    setLoading(true);
    const statusValue = status ? 0 : 1;
    const res = await updateData("admin/updatecustomer", {
      cust_id: id,
      status: statusValue,
    });

    if (res.status_code === 200) {
      toast.success(res.message);
      if (isSuspended) {
        const data = selectedData.filter((c)=> c.cust_id != id)
        setSelectedData(data)
      } else {
        const newVendors = selectedData.map((v) => {
          if (v.cust_id === id) {
            return { ...v, status: statusValue };
          }
          return v;
        });

        setSelectedData(newVendors);
        filteredItems = filteredItems.map((v) => {
          if (v.cust_id === id) {
            return { ...v, status: statusValue };
          }
          return v;
        });
      }
      setLoading(false);
    }
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        placeholder="Filter by name"
        setFilterText={setFilterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleChange = (state) => {
    setSelectedData(state.selectedRows);
  };

  return (
    <>
      <div className="dashboard-box">
        <DataTable
          columns={columns}
          data={filteredItems}
          // selectableRows
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          onSelectedRowsChange={handleChange}
        />
      </div>
    </>
  );
}
