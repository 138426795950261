import React from "react";
import { BsCheckLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "../../../components/utils/Interceptors";

const Packages = () => {
  const { t } = useTranslation();
  const [perMonth, setPerMonth] = useState([]);
  const [peryear, setPeryear] = useState([]);
  const [plans, setplans] = useState([]);
  const [btnClick, setBtnClink] = useState(1);
  const [currentPlan, setCurrentPlan] = useState([]);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const selectLanguage = i18next.language;

  useEffect(() => {
    const getAllPlans = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallplan`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.status_code === 200) {
        const filterItem = response.data.filter(
          (item) => !(item.plan_id === 9)
        );
        const perMonth = filterItem.filter((item) => item.no_of_days === 30);
        setPerMonth(perMonth);
        const peryear = filterItem.filter((item) => !(item.no_of_days === 30));
        setPeryear(peryear);
        setplans(perMonth);
      }
    };

    const fetchCurrentPlan = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getcurrentplan`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;

      if (response.status_code === 200) {
        if (response.data.length > 0) {
          setCurrentPlan(response.data[0]);
        }
      }
    };

    fetchCurrentPlan();
    getAllPlans();
  }, []);

  const handleToUp = (data) => {
    const infoData = {
      plan_id: data.plan_id,
      plan_price: data.plan_price,
      payment_mode: 2,
    };
    localStorage.setItem("vendorPackagesInfo", JSON.stringify(infoData));
    navigate("/vendor/packages/upgrade-package");
  };

  const handleBtnClick = (value) => {
    setBtnClink(value);
    if (value === 1) {
      setplans(perMonth);
    }
    if (value === 2) {
      setplans(peryear);
    }
  };

  return (
    <div className="my-5">
      <p className="alert bg-[#61c7c1] text-black text-[15px] mb-3 rounded justify-start gap-2 py-3">
        {t("v_dash_package_line")} {currentPlan?.plan_name}{" "}
        {t("v_dash_package_line2")} {currentPlan?.to_date}{" "}
      </p>
      <div>
        <div className="flex justify-center my-5">
          <div className="btn-group border-4 rounded-xl border-[#236260]">
            <span
              onClick={() => handleBtnClick(1)}
              className={`btn border-none ${
                btnClick === 1
                  ? "text-white hover:text-primary bg-[#236260] hover:bg-[#236260] disabled"
                  : "bg-white text-[#236260] hover:bg-primary hover:text-white"
              }`}
            >
              {t("Monthly")}
            </span>
            <span
              onClick={() => handleBtnClick(2)}
              className={`btn border-none ${
                btnClick === 2
                  ? "text-white hover:text-primary bg-[#236260] hover:bg-[#236260] disabled"
                  : "bg-white text-[#236260] hover:bg-primary hover:text-white"
              }`}
            >
              {t("Yearly")}
            </span>
          </div>
        </div>

        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-6 ">
            {plans.length > 0 ? (
              <>
                {plans.map((plan, idx) => (
                  <div
                    key={plan.plan_id}
                    className="p-3 border shadow-md rounded bg-white"
                  >
                    <div className="my-5 flex justify-center">
                      <img
                        className="w-20 text-center"
                        src="https://aitech-images.s3.ap-south-1.amazonaws.com/1684738343623icons8-crown-100.png"
                        alt="package"
                      />
                    </div>
                    <h2 className="text-xl font-bold text-center">
                      {plan.plan_name}
                    </h2>
                    <div className="pt-10 pb-3 w-11/12 mx-auto">
                      <ul className="text-sm flex flex-col gap-4">
                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />{" "}
                          {idx == 2
                            ? selectLanguage === "ar"
                              ? "عدد غير محدود من المنتجات"
                              : "Unlimited number of products"
                            : `${plan.customer_limit} ${t("cus_limit")}`}
                        </li>
                        <li className="flex gap-x-2 items-center ">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {idx == 2
                              ? selectLanguage === "ar"
                                ? "حد غير محدود للعملاء"
                                : "Unlimited Customer Limit"
                              : `${plan.product_limit} ${t("product_limit")}`}
                          </span>
                        </li>
                        {/* <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {plan.storage_limit} {t("storage_limit")}
                          </span>
                        </li> */}

                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {selectLanguage === "ar"
                              ? "لا عمولة على المبيعات"
                              : "No commission on sales"}{" "}
                          </span>
                        </li>
                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {selectLanguage === "ar"
                              ? "دعم جميع أنواع المنتجات"
                              : "Support all kinds of products"}
                          </span>
                        </li>
                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {selectLanguage === "ar"
                              ? "شركات الشحن المتعددة"
                              : "Multiple shipping companies"}
                          </span>
                        </li>

                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {selectLanguage === "ar"
                              ? "تفعيل الدفع الإلكتروني"
                              : "Activate electronic payment"}
                          </span>
                        </li>
                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {selectLanguage === "ar"
                              ? "كوبونات خصم"
                              : "Discount coupons"}
                          </span>
                        </li>
                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {idx === 2
                              ? selectLanguage === "ar"
                                ? "تحسين محركات البحث غير محدود"
                                : "Unlimited SEO"
                              : selectLanguage === "ar"
                              ? "تحسين محركات البحث المحدودة"
                              : "Limited SEO"}
                          </span>
                        </li>
                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {idx === 0
                              ? selectLanguage === "ar"
                                ? "الشحن - تورود"
                                : "SHIPPING - TOROD"
                              : selectLanguage === "ar"
                              ? "الشحن - TOROD - التنشيط"
                              : "SHIPPING - TOROD - Activate"}
                          </span>
                        </li>
                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {idx === 0
                              ? selectLanguage === "ar"
                                ? "طريقة الدفع - مويسر"
                                : "PAYMENT METHOD - MOYASAR"
                              : selectLanguage === "ar"
                              ? "طريقة الدفع - الميسر - تابي وتمارا"
                              : "PAYMENT METHOD - MOYASAR - Tabby and Tamara"}
                          </span>
                        </li>
                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {idx === 2
                              ? selectLanguage === "ar"
                                ? "تخصيص التصميم من خلال Custom CSS & JS"
                                : "Customize the design through Custom CSS & JS"
                              : selectLanguage === "ar"
                              ? "لا يمكنك تخصيص التصميم من خلال Custom CSS & JS"
                              : "No Customize the design through Custom CSS & JS"}
                          </span>
                        </li>
                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {selectLanguage === "ar"
                              ? "شهادة SSL مجانية"
                              : "Free SSL certificate"}{" "}
                          </span>
                        </li>
                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {selectLanguage === "ar" ? "التقارير" : "Reports"}{" "}
                          </span>
                        </li>
                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {idx === 2
                              ? selectLanguage === "ar"
                                ? "حجز رابط خاص (نطاق)"
                                : "Reserve a private link (domain)"
                              : selectLanguage === "ar"
                              ? "لا يوجد حجز رابط خاص (نطاق)"
                              : "No Reserve a private link (domain)"}
                          </span>
                        </li>
                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {selectLanguage === "ar"
                              ? "تصدير واستيراد المنتجات"
                              : "Export and import of products"}{" "}
                          </span>
                        </li>
                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {selectLanguage === "ar"
                              ? "تحديث المنتجات باستخدام Excel"
                              : "Updating products using Excel"}{" "}
                          </span>
                        </li>
                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {idx === 0
                              ? selectLanguage === "ar"
                                ? "لا إدارة العملاء"
                                : "No Customer Management"
                              : selectLanguage === "ar"
                              ? "ادارة الزبائن"
                              : "Customer Management"}
                          </span>
                        </li>
                        <li className="flex gap-x-2 items-center">
                          <BsCheckLg className="text-success" size={15} />
                          <span>
                            {idx === 0
                              ? selectLanguage === "ar"
                                ? "لا يوجد دعم لتثبيت أعمالي في Google"
                                : "NO Google MY BUSINESS INSTALL SUPPORT"
                              : selectLanguage === "ar"
                              ? "جوجل دعم تثبيت أعمالي"
                              : "Google MY BUSINESS INSTALL SUPPORT"}
                          </span>
                        </li>
                      </ul>

                      <div className="price my-8 flex gap-x-1 items-center justify-center">
                        <h1 className="text-xl md:text-3xl font-bold text-center">
                          {plan.plan_price} ر.س
                        </h1>
                        {/* <span className="text-sm py-1 px-2 border-l text-gray-400 border-gray-200">{plan.no_of_days} {t("days_limit")}</span> */}
                      </div>
                      {
                        // ${plan.plan_id == 1 && "btn-disabled cursor-not-allowed "}
                        !(plan.plan_id === currentPlan?.plan_id) ? (
                          <div className="text-center">
                            <button
                              onClick={() => handleToUp(plan)}
                              className={` py-2 text-sm px-4 bg-primary cursor-pointer text-white rounded font-medium`}
                            >
                              {t("Purchase_btn")}
                            </button>
                          </div>
                        ) : (
                          <div className="text-center">
                            <button
                              onClick={() => handleToUp(plan)}
                              className={`btn-disabled py-2 cursor-pointer text-sm px-4 !bg-[#236260] text-white rounded font-medium`}
                            >
                              {t("Purchase_btn")}
                            </button>
                          </div>
                        )
                      }
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <> No Plans available right now </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages;
