import React, { useEffect, useState } from "react";
import { createContext } from "react";

export const domainProvider = createContext();
const DomainContext = ({ children }) => {
  const [customdomain, setCustomdomain] = useState("");
  const [domainstatus, setDomainstatus] = useState("");
  const [titleName, setTitleName] = useState("");
  const [orgDomain, setOrgDomain] = useState("");
  var url = window.location.href;

  const urlArray = url.split("/");

  useEffect(() => {
    const domain = urlArray[2];
    if (domain.startsWith("www.")) {
      // If it starts with "www.", extract the domain after "www."
      const customDomain = domain.slice(4);
      setCustomdomain(customDomain);
      setTitleName(customDomain.split(".")[0]); // Extract title name excluding "www."
      localStorage.setItem("customdomain", customDomain);
    } else {
      // Otherwise, use the domain as is
      setCustomdomain(domain);
      setTitleName(domain.split(".")[0]); // Extract title name
      localStorage.setItem("customdomain", domain);
    }

    // setCustomdomain("aitech.com.sa")
  }, []);

  const info = {
    customdomain,
    setCustomdomain,
    titleName,
    domainstatus,
    setDomainstatus,
    orgDomain,
    setOrgDomain,
  };
  return (
    <domainProvider.Provider value={info}>{children}</domainProvider.Provider>
  );
};

export default DomainContext;
