import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import { AiFillEdit } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import { toast } from "react-toastify";
import FilterComponent from "../../../components/utils/FilterComponent";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import deleteDataById from "../../../hooks/deleteDataById";
import getDataById from "../../../hooks/getDataById";
import postData from "../../../hooks/postData";
import updateData from "../../../hooks/updateData";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "../../../components/utils/Interceptors";
import { Box, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
  maxHeight: "calc(100vh - 5em)",
};

const Color = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [modal, setModal] = useState(false);
  const [colors, setColors] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const selectLanguage = i18next.language;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const navigate = useNavigate();
  let filteredItems = colors.filter(
    (item) =>
      item.color_name &&
      item.color_name.toLowerCase().includes(filterText.toLowerCase())
  );

  const columns = [
    {
      name: `${t("vd_pc_C_ID")}`,
      selector: (row, i) => i + 1,
      sortable: true,
    },
    {
      name: `Sample`,
      sortable: true,
      cell: (item) => (
        <div
          style={{ backgroundColor: item.color_code }}
          className={`w-8 h-4 border border-primary`}
        ></div>
      ),
    },

    {
      name: `${t("vd_pc_Color_n")}`,
      selector: (row) =>
        selectLanguage == "ar" && row.color_name_ar
          ? row.color_name_ar
          : row.color_name,
      sortable: true,
    },
    {
      name: `${t("vd_pc_Color_c")}`,
      selector: (row) => row.color_code,
      sortable: true,
    },
    {
      name: `${t("vd_pc_C_Options")}`,
      sortable: true,
      cell: (item) => (
        <div className="flex gap-2">
          <label
            onClick={() => selectItemToEdit(item.color_id)}
            htmlFor="my-modal-4"
            className="p-2 flex items-center border border-secondary hover:bg-secondary rounded-full text-secondary hover:text-white cursor-pointer duration-300"
            title={`${t("Edit")}`}
          >
            <AiFillEdit />
          </label>

          <span
            onClick={() => deleteItem(item.color_id)}
            className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300"
            title={`${t("Delete")}`}
          >
            <TiDelete />
          </span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);

    const getAllSizes = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallcolor`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        setColors(response.data);
      } else {
        setColors([]);
      }

      setLoading(false);
    };

    getAllSizes();
  }, [refresh]);

  // handlers
  const onSubmit = async (data) => {
    setLoading(true);
    const response = await postData("/vendor/createcolor", data);

    if (response !== 0) {
      if (filteredItems) {
        filteredItems.push(response);
      } else {
        filteredItems = [response];
      }

      setColors([...colors, response]);
      setRefresh(!refresh);
    }
    setLoading(false);
    reset();
  };

  const deleteItem = async (id) => {
    confirmAlert({
      title: `${t("delete_alert_title")}`,
      message: `${t("delete_alert_des")}`,
      buttons: [
        {
          label: `${t("yes")}`,
          onClick: async () => {
            setLoading(true);

            const response = await deleteDataById("vendor/deletecolor", id);

            if (response.status_code === 200) {
              const newColorList = colors.filter(
                (item) => item.color_id !== id
              );
              setColors(newColorList);
              filteredItems = filteredItems.filter(
                (item) => item.color_id !== id
              );
            }

            setLoading(false);
            setRefresh(!refresh);
          },
        },
        {
          label: `${t("no")}`,
          onClick: () => {},
        },
      ],
    });
  };

  const [showEdit, setShowEdit] = useState(false);
  const selectItemToEdit = async (id) => {
    const response = await getDataById("vendor/getbyidcolor", id);
    if (response.data) {
      setSelectedItem(response.data);
      setModal(true);
      setShowEdit(true);
    } else {
      toast.error(response?.message);
    }
  };

  // validations in edit size form
  const [editErr, setEditErr] = useState("");
  const [editErr2, setEditErr2] = useState("");
  useEffect(() => {
    const regexName = /^(?=.{1,15}$)[a-zA-Z ,.'-]+$/i;

    if (regexName.test(selectedItem.color_name)) {
      setEditErr("");
    } else {
      setEditErr(
        "Color name must only contain alphabet letters with a maximum of 15 characters"
      );
    }
  }, [selectedItem]);

  const [editArabicErr, setEditArabicErr] = useState("");
  useEffect(() => {
    const regexName = /^[\u0600-\u06FF ,.'-]+$/i;
    if (selectedItem.color_name_ar) {
      if (regexName.test(selectedItem.color_name_ar)) {
        setEditArabicErr("");
      } else {
        setEditArabicErr("Color name must only contain arabic letters");
      }
    } else setEditArabicErr("");
  }, [selectedItem]);

  useEffect(() => {
    if (Object.keys(selectedItem).length != 0) {
      const length = selectedItem.color_code.length;

      if (length > 7) {
        setEditErr2("Color code must only contain six letters");
      } else {
        setEditErr2("");
      }
    } else {
    }
  }, [selectedItem]);

  const submitEditForm = async (e) => {
    e.preventDefault();

    const data = {
      id: selectedItem.color_id,
      name: selectedItem.color_name,
      name_ar: selectedItem.color_name_ar,
      color_code: selectedItem.color_code,
    };

    if (editErr || editErr2 || editArabicErr) {
      console.log("no");
    } else {
      const response = await updateData("vendor/updatecolor", data);

      if (response.status_code === 200) {
        const updatedList = colors.map((c) => {
          if (c.color_id === selectedItem.color_id) {
            return response.data;
          }
          return c;
        });

        setColors(updatedList);

        filteredItems = filteredItems.map((c) => {
          if (c.color_id === selectedItem.color_id) {
            return response.data;
          }
          return c;
        });

        setSelectedItem("");
        setShowEdit(false);
        toast.success("Updated Successfully");
      } else {
        toast.error(response?.error?.message);
      }
      setModal(false);
    }
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  function handleGoBack() {
    navigate(-1);
  }

  return (
    <>
      <div className="flex gap-x-4">
        <h2 className="text-title text-lg md:text-2xl uppercase">
          {t("vd_pc_Colors")}
        </h2>
        <button
          onClick={handleGoBack}
          className="mt-1 btn btn-primary btn-xs text-white flex justify-center items-center gap-x-2"
        >
          <FaArrowCircleLeft size={15} />
          {t("add_new_product_back")}
        </button>
      </div>
      <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 mt-5">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          <div>
            <form
              className="flex flex-col gap-4 mt-5"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text">{t("vd_pc_Color_n")}</span>
                </label>
                <input
                  {...register("color_name", {
                    required: true,
                    pattern: /^(?=.{1,15}$)[a-zA-Z ,.'-]+$/i,
                  })}
                  type="text"
                  className="input input-bordered w-full max-w-lg rounded-none shadow"
                />
                {errors.color_name?.type === "required" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Color name is required
                  </span>
                )}
                {errors.color_name?.type === "pattern" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Color name must only contain alphabet letters with a maximum
                    of 15 characters
                  </span>
                )}
              </div>
              <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text">{t("color_arabic")}</span>
                </label>
                <input
                  {...register("color_name_ar", {
                    pattern: /^[\u0600-\u06FF ,.'-]+$/i,
                  })}
                  type="text"
                  className="input input-bordered w-full max-w-lg rounded-none shadow"
                  placeholder={t("color_place")}
                />
                {errors.color_name_ar?.type === "pattern" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Color name must only contain arabic letters
                  </span>
                )}
              </div>
              <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text">{t("vd_pc_Color_c")}</span>
                </label>
                <input
                  {...register("color_code", { required: true })}
                  type="color"
                  className="input input-bordered w-full max-w-lg rounded-none shadow"
                />
                {errors.color_code?.type === "required" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Color code is required
                  </span>
                )}
              </div>

              <button className="btn btn-primary max-w-lg rounded-none text-white">
                {t("vd_pc_btn")}
              </button>
            </form>
          </div>
          <div className="col-span-2 md:p-5">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <DataTable
                columns={columns}
                data={filteredItems}
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                // selectableRows
                pagination
                noDataComponent={
                  <p className="py-8 text-gray-400 text-center">
                    {t("No records")}
                  </p>
                }
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        open={showEdit}
        onClose={() => setShowEdit(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="my-4"
      >
        <Box sx={style} className="w-11/12 max-w-[32rem] overflow-y-scroll">
          {modal && (
            <div className="z-50">
              <h3 className="text-lg font-bold">{t("vd_pc_e_Color")}</h3>

              {loading ? (
                <LoadingSpinner />
              ) : (
                <form className="flex flex-col gap-4" onSubmit={submitEditForm}>
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text">{t("vd_pc_c")}</span>
                    </label>
                    <input
                      value={selectedItem?.color_name}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          color_name: e.target.value,
                        })
                      }
                      type="text"
                      className="input input-bordered w-full max-w-lg rounded-none shadow"
                      required
                    />
                    <span className="label-text-alt text-red-500 font-Roboto my-1">
                      {editErr}
                    </span>
                  </div>
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text">{t("color_arabic")}</span>
                    </label>
                    <input
                      value={selectedItem?.color_name_ar}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          color_name_ar: e.target.value,
                        })
                      }
                      type="text"
                      className="input input-bordered w-full max-w-lg rounded-none shadow"
                    />
                    <span className="label-text-alt text-red-500 font-Roboto my-1">
                      {editArabicErr}
                    </span>
                  </div>
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text">
                        {t("vd_pc_c")} {t("coupon_code")}{" "}
                      </span>
                    </label>
                    <input
                      value={selectedItem?.color_code}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          color_code: e.target.value,
                        })
                      }
                      type="text"
                      className="input input-bordered w-full max-w-lg rounded-none shadow"
                      required
                    />
                    <span className="label-text-alt text-red-500 font-Roboto my-1">
                      {editErr2}
                    </span>
                  </div>
                  <button
                    type="submit"
                    className={`btn btn-primary max-w-lg rounded-none text-white ${
                      loading && "loading"
                    }`}
                  >
                    {t("vd_pc_btn2")}
                  </button>
                </form>
              )}
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Color;
