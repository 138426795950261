import React from "react";

import DataTable from "react-data-table-component";

import moment from "moment"; // Example for onSort prop
import { TiDelete } from "react-icons/ti";
import { AiFillEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import deleteDataById from "../../../hooks/deleteDataById";
import axios from "../../../components/utils/Interceptors";

const AllStuffs = () => {
  const [loading, setLoading] = useState();
  const [staffs, setStaffs] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const getAllStaff = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallstaff`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        setStaffs(response.data);
      }
    };

    getAllStaff();
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.vendor_name,
      sortable: true,
    },

    {
      name: "Email",
      selector: (row) => row.vendor_email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.vendor_contact,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.vendor_role,
      sortable: true,
    },
    {
      name: "Options",
      sortable: true,
      cell: (item) => (
        <div className="flex gap-2">
          <Link
            to={`/vendor/edit-staff/${item.vendor_id}`}
            className="p-2 flex items-center border border-blue-500 hover:bg-blue-500 rounded-full text-blue-500 hover:text-white cursor-pointer duration-300 modal-button"
            title="Edit"
          >
            <AiFillEdit />
          </Link>
          <span
            onClick={() => deleteItem(item.vendor_id)}
            className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300"
            title="Delete"
          >
            <TiDelete />
          </span>
        </div>
      ),
    },
  ];

  const deleteItem = async (id) => {
    confirmAlert({
      title: "Click Yes to Delete",
      message: "Are you sure to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setLoading(true);

            const response = await deleteDataById("vendor/deletestaff", id);

            if (response.status_code === 200) {
              const newvendorList = staffs.filter(
                (item) => item.vendor_id !== id
              );
              setStaffs(newvendorList);
              filteredItems = filteredItems.filter(
                (item) => item.vendor_id !== id
              );
            }

            setLoading(false);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const [filterText, setFilterText] = useState("");
  let filteredItems = staffs?.filter(
    (item) =>
      item.vendor_name &&
      item.vendor_name.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="text-title text-lg md:text-2xl pb-2">All Staffs</h2>
        <Link
          to="/vendor/add-staff"
          className="py-2 text-sm px-4 bg-primary text-white rounded font-normal"
        >
          Add New Staff
        </Link>
      </div>
      <div className="my-5">
        <DataTable columns={columns} data={filteredItems} pagination />
      </div>
    </>
  );
};

export default AllStuffs;
