import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  AiFillDelete,
  AiFillEye,
  AiFillMail,
  AiTwotoneEdit,
} from "react-icons/ai";

import { FaEdit, FaUserEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

import FilterComponent from "../../../components/utils/FilterComponent";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import deleteItem from "../../../hooks/admin/deleteItem";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Helmet } from "react-helmet-async";
import getAllAdmin from "../../../hooks/getAllAdmin";

const AllPages = () => {
  const { t } = useTranslation();
  const [pages, setPages] = useState([]);

  const [selectedData, setSelectedData] = useState();
  const [loading, setLoading] = useState(false);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  let filteredItems = [];
  if (pages && pages.length > 0) {
    filteredItems = pages.filter((item) =>
      item.page_title.toLowerCase().includes(filterText.toLowerCase())
    );
  }

  const columns = [
    {
      name: `${t("Title")}`,
      selector: (row) => <div className="py-4">{row.page_title}</div>,
      sortable: true,
    },

    {
      name: `${t("order_table5")}`,
      selector: (row) => (row.published_status ? "Published" : "Draft"),
      sortable: true,
    },

    {
      name: `${t("Action")}`,
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (item) => {
        return (
          <>
            <div className="flex gap-2">
              <Link
                to={`/admin/page/edit/${item.page_id}`}
                className="p-2 flex items-center border border-success hover:bg-success rounded-full text-success hover:text-white cursor-pointer duration-300"
                title={t("Edit")}
              >
                <AiTwotoneEdit />
              </Link>
              <span
                onClick={() =>
                  deleteItem(
                    item.page_id,
                    pages,
                    setPages,
                    filteredItems,
                    "page_id",
                    "admin/deletepage"
                  )
                }
                className="p-2 flex items-center border border-primary hover:bg-primary rounded-full text-primary hover:text-white cursor-pointer duration-300"
                title={t("Delete")}
              >
                <AiFillDelete />
              </span>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);

    const getAllData = async () => {
      const res = await getAllAdmin("admin/getallpage");
      if (res.status_code === 200) {
        setPages(res.data);
      }
      setLoading(false);
    };
    getAllData();
  }, []);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="flex items-center  gap-2 flex-wrap my-2">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder="Filter by name"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const handleChange = (state) => {
    setSelectedData(state.selectedRows);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("Admin")} - {t("All_Pages")}
        </title>
      </Helmet>
      <div className="flex justify-between items-center mb-5">
        <h2 className="text-title text-lg md:text-2xl pb-2">
          {t("All_Pages")}
        </h2>
        <Link
          to="/admin/page/create"
          className="py-2 text-sm px-4 bg-primary text-white rounded font-normal"
        >
          {t("Add_Page")}
        </Link>
      </div>
      <div className="dashboard-box">
        <DataTable
          columns={columns}
          data={filteredItems}
          // selectableRows
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          onSelectedRowsChange={handleChange}
        />
      </div>
    </>
  );
};

export default AllPages;
