import React, { useContext, useEffect } from "react";
import ContactUs from "../components/HomeComponents/ContactUs";
import HeroSection from "../components/HomeComponents/HeroSection";
import HowItWorks from "../components/HomeComponents/HowItWorks";
import Pricing from "../components/HomeComponents/Pricing";
import Services from "../components/HomeComponents/Services";
import "aos/dist/aos.css";
import Aos from "aos";
import Header from "../components/Header/Header";
import { Helmet } from "react-helmet-async";
import Footer from "../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import HomeVideo from "../components/HomeComponents/HomeVideo";
import News from "../components/HomeComponents/News";
import About from "../components/HomeComponents/About";
import Secure from "../components/Secure";
import { domainProvider } from "../contexts/DomainContext";
import axios from "axios";
import Whatsapp from "../components/utils/WhatsappIcon";

const HomePage = () => {
  const { t } = useTranslation();
  const [ganarel, setGanarel] = useState([]);
  useEffect(() => {
    Aos.init();
    Aos.refresh();
    axios
      .get(`${process.env.REACT_APP_APIURL}/admin/getgeneraldetails`, {})
      .then((res) => {
        setGanarel(res.data.data[0]);
      });
  }, []);

  const { customdomain, titleName } = useContext(domainProvider);

  return (
    <section className="bg-[#F7F7F7]">
      <Helmet>
        <title>Homepage - {titleName}</title>
        {ganarel?.favicon && <link rel="icon" href={ganarel.favicon} />}
      </Helmet>
      <Header navigation={true} ganarel={ganarel} />
      <div>
        <HeroSection />
        <Secure />
        <HowItWorks />
        <Services />
        <About />
        <Pricing />
        {/* <HomeVideo /> */}
        <News />
        <ContactUs ganarel={ganarel} />
        <Whatsapp />
      </div>
      <Footer />
    </section>
  );
};

export default HomePage;
