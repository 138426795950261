import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next'

const SectionTitle = ({ page }) => {
    const { t } = useTranslation()
    let selectLanguage = i18next.language;
    if (page === "contact") {
        return (
            <>
                <h2 className="uppercase text-[30px] font-bold text-[#236260] mt-[-4px] font-poppin">
                   {t("ST_head1")}
                </h2>
                <div className="border-t-2 font-bold mb-2 w-20 border-primary"></div>
            </>
        );
    }
    if (page === "howtoworks") {
        return (
            <>
                <h2 className="uppercase text-[30px] font-bold text-[#236260] mt-[-4px] font-poppin text-center">
                {t("ST_head2")}
                </h2>
                <div className="border-t-2 font-bold mb-2 w-20 mx-auto border-primary"></div>
            </>
        );
    }
    if (page === "feather") {
        // this is return 
        return (
            <>
                <div className="text-center uppercase text-[16px] font-semibold text-[#236260]">
                {t("ST_head3")}
                   </div>
                <h2 className="uppercase text-[30px] font-bold text-[#236260] mt-[-4px] font-poppin text-center">
                {t("ST_head4")}
                </h2>
                <div className="border-t-2 font-bold mb-2 w-20 mx-auto border-primary"></div>
            </>
        );
    }
    if (page === "pricing") {
        return (
            <>
                <h2 className="uppercase text-[30px] font-bold text-[#236260] font-poppin text-center">
                {t("ST_head5")}
                </h2>
                <div className="border-t-2 font-bold mb-2 w-20 mx-auto border-primary"></div>
            </>
        );
    }
    if (page === "video") {
        return (
            <>
                <div className="text-center uppercase text-[16px] font-semibold text-[#236260]">
                {t("ST_head6")}
                    </div>
                <h2 className="uppercase text-[30px] font-bold text-[#236260] mt-[-4px] font-poppin text-center">
                {t("ST_head7")}
                </h2>
                <div className="border-t-2 font-bold mb-2 w-20 mx-auto border-primary"></div>
            </>
        );
    }
    if (page === "Reviews") {
        return (
            <>
                <div className="text-center uppercase text-[16px] font-semibold text-[#236260]">
                {t("ST_head8")}
                    </div>
                <h2 className="uppercase text-[30px] font-bold text-[#236260] mt-[-4px] font-poppin text-center">
                {t("ST_head9")}
                </h2>
                <div className="border-t-2 font-bold mb-2 w-20 mx-auto border-primary"></div>
            </>
        );
    }
    if (page === "news") {
        return (
            <>
                <div className="text-center uppercase text-[16px] font-semibold text-[#236260]">
                {t("ST_head10")}
                    </div>
                <h2 className="uppercase text-[30px] font-bold text-[#236260] mt-[-4px] font-poppin text-center">
                {t("ST_head11")}
                </h2>
                <div className="border-t-2 font-bold mb-2 w-20 mx-auto border-primary"></div>
            </>
        );
    }
    if (page === "about") {
        return (
            <>
              <h2 className="uppercase text-[30px] font-bold text-[#236260] mt-[-4px] font-poppin text-center">
              {t("ST_head12")}
              
                </h2>
                <div className="border-t-2 font-bold mb-2 w-20 mx-auto border-primary"></div>
            </>
        );
    }
    if (page === "policy") {

        return (
            <>
                <h2 className="uppercase text-[30px] font-bold text-[#236260] pt-3 font-poppin text-center">
                    {selectLanguage === "ar" ? "سياسة الخصوصية" : "Privacy & Policy"}
                </h2>
                <div className="border-t-2 font-bold mb-2 w-20 mx-auto border-primary"></div>
            </>
        );

    }
    if (page === "agrement") {

        return (
            <>
                <h2 className="uppercase text-[30px] font-bold text-[#236260] pt-3 font-poppin text-center">
                    {selectLanguage === "ar" ? "سياسة الخصوصية" : "Usage Agreement"}
                </h2>
                <div className="border-t-2 font-bold mb-2 w-20 mx-auto border-primary"></div>
            </>
        );

    }
    if (page === "aboutUs") {
        return (
            <>
                {/* <div className="text-center uppercase text-[16px] pt-4 font-semibold text-[#236260]">AI TECH PLATFORM</div> */}
                <h2 className="uppercase text-[30px] font-bold text-[#236260] mt-[-4px] font-poppin text-center">
                    {t("NavAbout")}
                </h2>
                <div className="border-t-2 font-bold mb-2 w-20 mx-auto border-primary"></div>
            </>
        );
    }
    if (page === "help") {
        return (
            <>
                {/* <div className="text-center uppercase text-[16px] pt-4 font-semibold text-[#236260]">AI TECH PLATFORM</div> */}
                <h2 className="uppercase text-[30px] font-bold text-[#236260] mt-[-4px] font-poppin text-center">
                    {t("ST_head13")}
                </h2>
                <div className="border-t-2 font-bold mb-2 w-20 mx-auto border-primary"></div>
            </>
        );
    }
    if (page === "aboutUsTitle") {
        return (
            <>
                {/* <h2 className="uppercase text-[30px] font-bold text-[#236260] mt-[-4px] font-poppin">
                    About US
                </h2>
                <div className="border-t-2 font-bold mb-2 w-20 border-primary"></div> */}
            </>
        );
    }
};

export default SectionTitle;