import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Footer2 from "../components/Footer/Footer2";
import Header from "../components/Header/Header";
import Div from "../components/utils/Div";
import AgrementPage from "./AgrementPage";
import { domainProvider } from "../contexts/DomainContext";
import Whatsapp from "../components/utils/WhatsappIcon";
import axios from "axios";
import Aos from "aos";

const Agrement = () => {
  const [ganarel, setGanarel] = useState([]);
  useEffect(() => {
    Aos.init();
    Aos.refresh();
    axios
      .get(`${process.env.REACT_APP_APIURL}/admin/getgeneraldetails`, {})
      .then((res) => {
        setGanarel(res.data.data[0]);
      });
  }, []);

  const { customdomain, titleName } = useContext(domainProvider);

  return (
    <>
      <Helmet>
        <title>Usage Agreement - {titleName}</title>
        {ganarel?.favicon && <link rel="icon" href={ganarel.favicon} />}
      </Helmet>
      <Header navigation={true} />
      <Div>
        <AgrementPage />
        <Whatsapp />
      </Div>
      <Footer2 />
    </>
  );
};

export default Agrement;
