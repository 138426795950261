import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";
import ImageUploading from "react-images-uploading";
import Select from "react-select";
import { useEffect } from "react";
import { toast } from "react-toastify";
import ApiLoadingSpinner from "../../../components/utils/ApiLoadingSpinner";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import i18next from "i18next";
import axios from "../../../components/utils/Interceptors";
import Modal from "react-responsive-modal";

const AddProducts = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [dbTags, setdbTags] = useState([]);
  const [apiLoading, setapiLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState("");

  const selectLanguage = i18next.language;
  const [attribute, setAttribute] = useState({
    colors: [],
    sizes: [],
  });

  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [description_ar, setDescriptionAr] = useState("");
  const [images, setImages] = useState([]);
  const [galleryimages, setGalleryimages] = useState([]);

  useEffect(() => {
    const getAllCategories = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallcategory`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        const categoryData = response.data.map((category) => {
          const obj = {
            name:
              selectLanguage == "ar" && category.category_name_ar
                ? category.category_name_ar
                : category.category_name,
            value: category.category_id,
          };
          return obj;
        });

        setCategories(categoryData);
      } else {
        setCategories([]);
      }
    };

    const getAllColors = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallcolor`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        const colorData = response.data.map((color) => {
          const label = `<div className="items-center" style="
                    display: flex;
                ">${
                  selectLanguage == "ar" && color.color_name_ar
                    ? color.color_name_ar
                    : color.color_name
                }  <span className="ml-2 p-2 w-4 rounded-full border border-b" style="background-color:${
            color.color_code
          };width: 2px;height: 2px;"></span> </div>`;

          const obj = { label: label, value: color.color_id };
          return obj;
        });
        setColors(colorData);
      }
    };
    const getAllSizes = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallsize`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        const sizeData = response.data.map((size) => {
          const obj = {
            label:
              selectLanguage == "ar" && size.size_name_ar
                ? size.size_name_ar
                : size.size_name,
            value: size.size_id,
          };
          return obj;
        });
        setSizes(sizeData);
      }
    };
    const getAllTags = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getalltag`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        const tagData = response.data.map((tag) => {
          const obj = {
            label:
              selectLanguage == "ar" && tag.tag_name_ar
                ? tag.tag_name_ar
                : tag.tag_name,
            value: tag.tag_id,
          };
          return obj;
        });

        setdbTags(tagData);
      }
    };

    getAllCategories();
    getAllColors();
    getAllSizes();
    getAllTags();
    // getAllAttributes()
  }, [selectLanguage]);

  useEffect(() => {
    if (
      sizes.length == 0 ||
      dbTags.length == 0 ||
      categories.length == 0 ||
      colors.length == 0
    )
      setOpen(true);
    else setOpen(false);

    if (sizes.length == 0) return setAlert("size");
    else if (dbTags.length == 0) return setAlert("tag");
    else if (colors.length == 0) return setAlert("color");
    else if (categories.length == 0) return setAlert("category");
  }, [sizes, dbTags, colors, categories, open]);

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  const onChangeGalleryImages = (imageList, addUpdateIndex) => {
    // data for submit
    setGalleryimages(imageList);
  };

  const [tags, setTags] = useState([]);
  const [category, setCategory] = useState(null);

  const {
    control,
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (!category) {
      toast.error("Please select any category first");
      return;
    }
    if (!(attribute.colors.length > 0)) {
      toast.error("Please select any color first");
      return;
    }
    if (!(attribute.sizes.length > 0)) {
      toast.error("Please select any size first");
      return;
    }
    if (!(tags.length > 0)) {
      toast.error("Please select any tag first");
      return;
    }
    if (!data.product_weight) {
      toast.error("Add your Product Weight");
      return;
    }

    if (galleryimages?.length > 4) {
      toast.error("You can upload a max of 4 gallery images");
      return;
    }
    setLoading(true);

    data["product_description"] = description || "";
    data["product_description_ar"] = description_ar || "";

    if (attribute.colors.length > 0) {
      data["colors"] = JSON.stringify(attribute.colors);
    } else {
      data["colors"] = JSON.stringify(data.colors);
    }

    if (attribute.sizes.length > 0) {
      data["sizes"] = JSON.stringify(attribute.sizes);
    } else {
      data["sizes"] = JSON.stringify(data.sizes);
    }

    var tagArray = tags.map((tag) => tag.value);
    data["tags"] = JSON.stringify(tagArray);

    data["product_category"] = category;
    if (images[0]) {
      data["thumbnail_image"] = images[0].file;
    }

    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }

    for (let i of galleryimages) {
      formData.append("gallery_images", i.file);
    }

    setDisabled(true);
    const res = await axios.post(
      `${process.env.REACT_APP_APIURL}/vendor/createproduct`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );

    const response = await res.data;
    if (response?.status_code == 200) {
      toast.success("Product added Successfully");
      reset();
      setDisabled(false);
      navigate("/vendor/products");
    } else {
      setDisabled(false);
      toast.error("Something went Wrong");
    }
    setapiLoading(false);
    setLoading(false);
  };

  const setColorAttribute = (val) => {
    const colors = val.map((item) => item.value);
    setAttribute({ ...attribute, colors: colors });
  };
  const setSizeAttribute = (val) => {
    const sizes = val.map((item) => item.value);
    setAttribute({ ...attribute, sizes: sizes });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {apiLoading && <ApiLoadingSpinner />}

      <div className="flex gap-x-4">
        <h2 className="text-title text-lg md:text-2xl pb-2 uppercase">
          {t("add_new_product_title")}
        </h2>
        <Link to={"/vendor/products"}>
          <button className="btn btn-primary btn-xs text-white mb-5 flex justify-center items-center gap-x-2 mt-1">
            <FaArrowCircleLeft size={15} />
            {t("add_new_product_back")}
          </button>
        </Link>
      </div>

      <div className="mt-2 mb-16">
        {/* <p className="alert bg-[#61c7c1] text-black text-[15px] mb-3 rounded justify-start gap-2 py-3">
          {selectLanguage === "ar"
            ? "يرجى التأكد من إضافة العلامات والألوان والأحجام والفئات قبل إضافة المنتج"
            : "Please make sure that tags, colors, sizes and categories are added before adding a product"}
        </p> */}
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="md:col-span-2">
              <div className="border bg-white">
                <div className="card-title border-b p-4">
                  <h3 className="text-base font-medium">
                    {t("add_new_product_info")}
                  </h3>
                </div>
                <div className="py-5 px-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="name">
                          {t("add_new_product_name")}{" "}
                          <span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-8">
                        <input
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={`${t("add_new_product_name")}`}
                          {...register("product_name", {
                            required: true,
                            pattern: /^[a-zA-Z ,.'-]+$/i,
                          })}
                          maxLength={50}
                        />
                        {errors.product_name?.type === "required" && (
                          <small className="text-[12px] text-error">
                            {t("add_new_product_name_req")}
                          </small>
                        )}
                        {errors.product_name?.type === "pattern" && (
                          <small className="text-[12px] text-error">
                            Enter only english characters{" "}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* product name in arabic */}
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="name">
                          {t("add_new_product_name")} {t("in Arabic")}
                        </label>
                      </div>
                      <div className="col-span-8">
                        <input
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={` ${t("add_new_product_name")} ${t(
                            "in Arabic"
                          )}`}
                          {...register("product_name_ar", {
                            pattern: /^[\u0600-\u06FF ,.'-]+$/i,
                          })}
                        />
                        {errors.product_name_ar?.type === "pattern" && (
                          <small className="text-[12px] text-error">
                            Enter only arabic characters{" "}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="price">
                          {t("add_new_product_price")}{" "}
                          <span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-8">
                        <input
                          type="number"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={`${t("add_new_product_price_place")}`}
                          {...register("product_price", { required: true })}
                        />
                        {errors.price?.type === "required" && (
                          <small className="text-[12px] text-error">
                            {t("add_new_product_price_req")}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="sku">
                          {t("add_new_product_SKU")}
                          <span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-8">
                        <input
                          type="text"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={`${t("add_new_product_SKU_plc")}`}
                          {...register("product_sku")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="stock">
                          {t("add_new_product_stock")}{" "}
                          <span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-8">
                        <input
                          type="number"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={`${t("add_new_product_price_place")}`}
                          {...register("product_stock", { required: true })}
                        />
                        {errors.stock?.type === "required" && (
                          <small className="text-[12px] text-error">
                            {t("add_new_product_stock_req")}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Product Description */}
              <div className="border bg-white my-5">
                <div className="card-title border-b p-4">
                  <h3 className="text-base font-medium capitalize">
                    {t("Description")}
                  </h3>
                </div>

                <div className="py-5 px-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="description">
                          {t("Description")}
                          <span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-8">
                        <div className="form-control h-60">
                          <ReactQuill
                            className="h-48"
                            theme="snow"
                            value={description}
                            onChange={setDescription}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* descriptioin in arabic */}
                <div className="py-5 px-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="description">
                          {t("Description")} {t("in Arabic")}
                        </label>
                      </div>
                      <div className="col-span-8">
                        <div className="form-control h-60">
                          <ReactQuill
                            className="h-48"
                            theme="snow"
                            value={description_ar}
                            onChange={setDescriptionAr}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Product Attributes */}
              <div className="border bg-white my-5">
                <div className="card-title border-b p-4 flex w-full justify-between">
                  <h3 className="text-base font-medium capitalize">
                    {t("add_new_product_atrtibutes")}
                  </h3>
                </div>

                <div className="py-5 px-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12 gap-x-5 mb-2">
                      <div className="col-span-3 h-[40px]">
                        <label className="text-[13px]" htmlFor="colors">
                          {t("add_new_product_Colors")}
                        </label>
                      </div>

                      <div className="col-span-8">
                        <Select
                          isMulti
                          options={colors}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          formatOptionLabel={function (data) {
                            return (
                              <span
                                dangerouslySetInnerHTML={{ __html: data.label }}
                              />
                            );
                          }}
                          onChange={(val) => setColorAttribute(val)}
                        />
                        <p className="text-primary text-[12px] my-1 opacity-90">
                          +{" "}
                          <Link to="/vendor/color" className="text-primary">
                            {t("Add new color")}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12 gap-x-5 mb-2">
                      <div className="col-span-3 h-[40px]">
                        <label className="text-[13px]" htmlFor="sizes">
                          {t("add_new_product_Sizes")}
                        </label>
                      </div>

                      <div className="col-span-8">
                        <Select
                          isMulti
                          options={sizes}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(val) => setSizeAttribute(val)}
                        />
                        <p className="text-primary text-[12px] my-1 opacity-90">
                          +{" "}
                          <Link to="/vendor/size" className="text-primary">
                            {t("Add new size")}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Shipping Information */}
              <div className="border bg-white my-5">
                <div className="card-title border-b p-4">
                  <h3 className="text-base font-medium">
                    {t("add_new_product_Information")}
                  </h3>
                </div>

                <div className="py-5 px-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="weight">
                          {t("add_new_product_Weight")}
                        </label>
                      </div>
                      <div className="col-span-8">
                        <div className="form-control">
                          <label className="input-group rounded">
                            <input
                              type="number"
                              className="w-full h-[40px] input input-bordered rounded"
                              placeholder="0"
                              {...register("product_weight")}
                            />
                            <span className="bg-gray-200">gm</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label
                          className="text-[13px] capitalize"
                          htmlFor="product_height"
                        >
                          {t("add_new_product_Height")}
                        </label>
                      </div>
                      <div className="col-span-8">
                        <div className="form-control">
                          <label className="input-group rounded">
                            <input
                              type="number"
                              className="w-full h-[40px] input input-bordered rounded"
                              placeholder="0"
                              {...register("product_height")}
                            />
                            <span className="bg-gray-200">cm</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label
                          className="text-[13px] capitalize"
                          htmlFor="length"
                        >
                          {t("add_new_product_length")}
                        </label>
                      </div>
                      <div className="col-span-8">
                        <div className="form-control">
                          <label className="input-group rounded">
                            <input
                              type="number"
                              className="w-full h-[40px] input input-bordered rounded"
                              placeholder="0"
                              {...register("product_length")}
                            />
                            <span className="bg-gray-200">cm</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label
                          className="text-[13px] capitalize"
                          htmlFor="width"
                        >
                          {t("add_new_product_width")}
                        </label>
                      </div>
                      <div className="col-span-8">
                        <div className="form-control">
                          <label className="input-group rounded">
                            <input
                              type="number"
                              className="w-full h-[40px] input input-bordered rounded"
                              placeholder="0"
                              {...register("product_width")}
                            />
                            <span className="bg-gray-200">cm</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-1">
              {/* product stats */}
              <div className="border bg-white">
                <div className="card-title border-b p-4 flex w-full justify-between">
                  <h3 className="text-base font-medium capitalize">
                    {t("add_new_product_Status")}
                  </h3>
                </div>

                <div className="pt-5 pb-3 px-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 gap-x-5 mb-2">
                      <div className="h-[40px]">
                        <select
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder="0"
                          {...register("status")}
                        >
                          <option defaultValue="">Select Status</option>
                          <option defaultValue="1">
                            {t("add_new_product_Status_2")}
                          </option>
                          <option defaultValue="0">
                            {t("add_new_product_Status_3")}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* product Category */}
              <div className="border bg-white my-5">
                <div className="card-title border-b p-4 flex w-full justify-between">
                  <h3 className="text-base font-medium capitalize">
                    {t("add_new_product_Category")}
                  </h3>
                </div>
                <div className="pt-5 pb-2 px-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 gap-x-5 mb-2">
                      {/* <select className="w-full h-[40px] input input-bordered rounded" onChange={(val) => setCategory(val)}>
                        <option value="">Select Category</option>
                        {categories.length > 0
                          ? categories.map((c) => {
                              return <option value={c.value} key={c.value}>{c.name}</option>;
                            })
                          : null}
                      </select> */}
                      {categories.length > 0 && (
                        <SelectSearch
                          onChange={(val) => setCategory(val)}
                          options={categories}
                          name="category"
                          placeholder={`${t("add_new_product_Category_c")}`}
                          search={true}
                          required
                        />
                      )}
                      <p className="text-primary text-[12px] my-1 opacity-90">
                        +{" "}
                        <Link to="/vendor/categories" className="text-primary">
                          {t("Add new category")}
                        </Link>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* product Tags */}
              <div className="border bg-white my-5">
                <div className="card-title border-b p-4 flex w-full justify-between">
                  <h3 className="text-base font-medium capitalize">
                    {t("add_new_product_Tags")}
                  </h3>
                </div>

                <div className="pt-5 pb-3 px-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 gap-x-5 mb-2">
                      <Select
                        name="tags"
                        onChange={(val) => setTags(val)}
                        isClearable
                        isMulti
                        options={dbTags}
                      />
                      <em>{t("add_new_product_Tags_info")}</em>
                      <p className="text-primary text-[12px] mt-2 opacity-90">
                        +{" "}
                        <Link to="/vendor/tags" className="text-primary">
                          {t("Add new tag")}
                        </Link>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* product Thumbnail Images */}
              <div className="border bg-white my-5">
                <div className="card-title border-b p-4">
                  <h3 className="text-base font-medium">
                    {t("add_new_product_T_img")}{" "}
                    <span className="text-2xs text-gray-400"> ( 312x364 )</span>
                  </h3>
                </div>
                <div className="pb-2 ">
                  <div className="col-span-8">
                    <ImageUploading
                      value={images}
                      onChange={onChange}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <p
                            className="text-xs cursor-pointer py-12 px-8"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            {t("add_new_product_T_img_info")}
                          </p>
                          &nbsp;
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <div className="image-item__btn-wrapper">
                                {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                                <button
                                  className="text-red-600"
                                  onClick={() => onImageRemove(index)}
                                >
                                  X
                                </button>
                              </div>
                              <img
                                className="w-20 h-20"
                                src={image["data_url"]}
                                alt=""
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                  <p className="text-[12px] mt-[-20px] px-5 pb-2 text-gray-400">
                    {t("add_new_product_T_img_info2")}
                  </p>
                </div>
              </div>

              {/* Gallery Images */}
              <div className="border bg-white my-5">
                <div className="card-title border-b p-4">
                  <h3 className="text-base font-medium">
                    {t("add_new_product_G_img")}{" "}
                    <span className="text-2xs text-gray-400"> ( 600x600 )</span>
                  </h3>
                </div>
                <div className="pb-2">
                  <div className="col-span-8">
                    <ImageUploading
                      multiple
                      value={galleryimages}
                      onChange={onChangeGalleryImages}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,

                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <p
                            className="text-xs cursor-pointer py-12 px-8"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            {t("add_new_product_T_img_info")}
                          </p>
                          &nbsp;
                          {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                          <div className="flex gap-2">
                            {imageList.map((image, index) => (
                              <div
                                key={index}
                                className="image-item max-w-[5rem]"
                              >
                                <div className="image-item__btn-wrapper">
                                  {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                                  <button
                                    className="text-red-600"
                                    onClick={() => onImageRemove(index)}
                                  >
                                    X
                                  </button>
                                </div>
                                <img
                                  className="w-20 h-20 object-contain"
                                  src={image["data_url"]}
                                  alt=""
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </ImageUploading>
                  </div>

                  <p className="text-[12px] mt-[-20px] px-5 pb-2 text-gray-400">
                    {t("add_new_product_G_img_info2")}
                  </p>
                </div>
              </div>

              {/* product Videos */}
              {/* <div className="border bg-white my-5">
                <div className="card-title border-b p-4">
                  <h3 className="text-base font-medium">
                    {t("add_new_product_video")}
                  </h3>
                </div>
                <div className="py-5 px-5">
                  <div className="col-span-8">
                    <label htmlFor="videoType">
                      {t("add_new_product_video_p")}
                    </label>
                    <select
                      className="w-full h-[40px] input input-bordered rounded mt-2"
                      {...register("product_video_provider")}
                    >
                      <option defaultValue="youtube" selected>
                        {t("add_new_product_video_Youtube")}
                      </option>
                      <option defaultValue="draft">
                        {t("add_new_product_video_Dailymotion")}
                      </option>
                    </select>
                  </div>
                  <div className="col-span-8 mt-5">
                    <label htmlFor="video_link">
                      {t("add_new_product_video_link")}
                    </label>
                    <input
                      {...register("product_video_link")}
                      type="url"
                      className="w-full border border-gray-300 input-bordered rounded mt-2 py-2 px-3"
                    />
                    <small className="text-2xs text-gray-400">
                      Use proper embed link without extra parameter (copy the
                      src of iframe tag). Don't use short share link/embeded
                      iframe code.
                    </small>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <input
            className={`${loading && "loading"} ${
              disabled && "disabled"
            } py-1 px-3 bg-primary text-white rounded cursor-pointer `}
            type="submit"
            value={`${t("add_new_product_btn")}`}
          />
        </form>
      </div>

      {/* popup alert */}
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        center
        className=""
      >
        <div>
          <h4 className="mb-4 text-[#28716c]">
            {selectLanguage === "ar"
              ? "يرجى التأكد من إضافة العلامات والألوان والأحجام والفئات قبل إضافة المنتج"
              : "Please make sure that tags, colors, sizes and categories are added before adding a product."}
          </h4>
          <pre>
            {t("Navigate here to add new category")}:{" "}
            <Link to="/vendor/categories" className="text-primary">
              {t("Add new category")}
            </Link>
            <br />
            {t("Navigate here to add new size")}:{" "}
            <Link to="/vendor/size" className="text-primary">
              {t("Add new size")}
            </Link>
            <br />
            {t("Navigate here to add new tags")}:{" "}
            <Link to="/vendor/tags" className="text-primary">
              {t("Add new tag")}
            </Link>
            <br />
            {t("Navigate here to add new colors")}:{" "}
            <Link to="/vendor/color" className="text-primary">
              {t("Add new color")}
            </Link>
            <br />
          </pre>
          <p className="flex w-full justify-end text-xs text-gray-500">
            {t("Add atleast 1")} {alert}
          </p>
        </div>
      </Modal>
    </>
  );
};

export default AddProducts;
