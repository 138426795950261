import React from "react";
import Button from "../utils/Button";
import { useTranslation } from 'react-i18next';
import heroBanner2 from "../../assets/main-image.png";
import './hero.css'
import Div from "../utils/Div";
import i18next from "i18next";

const HeroSection = () => {

  const { t } = useTranslation()
  let selectLanguage = i18next.language;
  return (
    <section className="py-5 bg-controller-hero">
      <Div>
        <div className="grid grid-cols md:grid-cols-2 px-2 md:mt-0 mt-4 items-center gap-x-2 ">
          <div className="hero-text order-last md:order-first">
            <h1 className={`${selectLanguage === "ar" ?"md:text-right" :"md:text-left"} md:text-[64px] text-[48px] text-center text-white font-bold loading-3 `}>
              {t("HS_build")}<br></br> {t("HS_store")}<br></br> {t("HS_minute")}
            </h1>

            <p className={`${selectLanguage === "ar" ? "md:text-right" : "md:text-left"} text-[18px] text-white paragaraph-font py-5 text-center pr-5`}>
            {t("HS_line_two")}
            </p>
            <div className="flex md:justify-start justify-center">

              <div className={`${selectLanguage==="ar" ? "w-[145px]"  :" w-[262px]" } bg-white hover:bg-transparent p-2 rounded-md`}>
                <Button link="/vendor/register" bgColor="bg-primary">
                {t("HS_button_text")}
                </Button>
              </div>
            </div>
          </div>

          <div className="hero-image pt-8">
            <img
              style={{"height":'510px'}}
              src={heroBanner2}
              className="mx-auto w-fit h-fit"
              alt="hero-banner"
            />
          </div>
        </div>
      </Div>
    </section>
  );
};

export default HeroSection;
