import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import axios from "../../components/utils/Interceptors"

function MapPicker(props) {
  // npm hook to load google maps like we pass a script in usual HTML form
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  const [address, setAddress] = useState(null);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState("");
  const [alternate_contact, setContact] = useState("");
  const [click, setClick] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  const [errorTxtN, seterrorTxtN] = useState();
  const [storeDetails, setStoreDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const addressRef = useRef();
  const selectLanguage = i18next.language;
  const { t } = useTranslation();

  // on selcting address from dropdown
  const handlePlaceSelect = () => {
    const place = addressRef.current.getPlace(); // Get the selected place

    if (place) {
      setAddress(place.formatted_address);
      setLatitude(place.geometry.location.lat());
      setLongitude(place.geometry.location.lng());

      const city = place.address_components.find((component) =>
        component.types.includes("locality")
      );
      if (city) {
        setCity(city.long_name);
      }
      if (!city) {
        setCity("");
      }

      const zip = place.address_components.find((component) =>
        component.types.includes("postal_code")
      );
      if (zip) {
        setZip(zip.long_name);
      }
      if (!zip) {
        setZip("");
      }

      const country = place.address_components.find((component) =>
        component.types.includes("country")
      );
      if (country) {
        setCountry(country.long_name);
      }
      if (!country) {
        setCountry("");
      }

      const region = place.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      );
      if (region) {
        setRegion(region.long_name);
      }
      if (!region) {
        setRegion("");
      }
    }
  };

  // fetching store details
  const getStoreDetails = async () => {
    const url = `${process.env.REACT_APP_APIURL}/vendor/getstorefulldetails`;
    const res = await axios.get(url, {
      headers: {
        // 'Content-type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    const response = await res.data;
    setLoading(false);
    if (response.data) {
      setStoreDetails(response.data);
      setValue(response.data.store_phone.toString());
    } else {
      return toast.info(response.message || response.err);
    }
  };

  useEffect(() => {
    getStoreDetails();
  }, []);

  useEffect(() => {
    setAddress(props.address ? props.address : "");
    setCity(props?.city ? props.city : "");
    setCountry(props?.country ? props.country : "");
    setRegion(props?.region ? props.region : "");
    setZip(props?.zip ? props.zip : "");
    setEmail(props?.email ? props.email : "");
    setValue(props?.contact ? props.contact : "");
  }, [props]);

  const onSubmit = async (e) => {
    e.preventDefault();

    setClick(true);
    const phone = value ? value : storeDetails?.store_phone;

    if (!(phone?.length === 12)) {
      seterrorTxtN(
        "Please Enter your valid Number with your Country code. Is't must be 12 character"
      );
      return;
    } else {
      seterrorTxtN("");
    }

    var payloadValue = "";
    if (props?.alternate) {
      payloadValue = {
        store_email: email,
        store_phone: phone,
        store_address: address,
        store_latitude: latitude,
        store_longitude: longitude,
        store_alternate_contact: alternate_contact,
      };
    } else {
      payloadValue = {
        store_email: email,
        store_phone: phone,
        store_address: address,
        store_latitude: latitude,
        store_longitude: longitude,
        store_city: city,
        store_region: region,
        store_country: country,
        store_zip: zip,
      };
    }

    props.onSubmit(payloadValue);
  };

  // loader when map is loading
  if (!isLoaded)
    return (
      <div>
        <p>...Loading</p>
      </div>
    );

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="flex flex-col my-4">
          <span>{t("New Address")}</span>
          <Autocomplete
            onLoad={(autocomplete) => {
              addressRef.current = autocomplete;
            }}
            onPlaceChanged={handlePlaceSelect}
          >
            <input
              id="autocomplete"
              type="text"
              placeholder="Select address"
              className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
              ref={addressRef}
              required={props?.alternate ? true : false}
            />
          </Autocomplete>

          {props.alternate && click ? (
            <p className="label-text-alt text-red-500 font-Roboto">
              This field is required
            </p>
          ) : null}
        </div>

        {address == storeDetails?.store_address ? (
          <div className="flex flex-col w-full">
            <span>{t("Previous Address")}</span>
            <input
              value={storeDetails?.store_address}
              type="text"
              required
              readOnly
              className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
            />
          </div>
        ) : null}

        <div className="flex flex-col my-4">
          <span>{t("V_allOrder_or_choice_Regions")}</span>
          {
            <input
              // onChange={handleChnage}
              value={region}
              type="text"
              required
              className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
              readOnly
            />
          }
        </div>

        <div className="flex flex-col my-4">
          <span>{t("Cus_city")}</span>
          {
            <input
              // onChange={handleChnage}
              value={city}
              type="text"
              required
              className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
              readOnly
            />
          }
        </div>

        <div className="flex flex-col my-4">
          <span>{t("Cus_country")}</span>
          {
            <input
              // onChange={handleChnage}
              value={country}
              type="text"
              required
              className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
              readOnly
            />
          }
        </div>

        <div className="flex flex-col my-4">
          <span>{t("Postal Code")}</span>
          {
            <input
              // onChange={handleChnage}
              value={zip}
              type="text"
              required
              className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
              readOnly
            />
          }
        </div>

        <div className="flex flex-col my-4">
          <span>{t("form_email")}</span>
          <input
            required
            type="text"
            defaultValue={storeDetails?.store_email || ""}
            placeholder="Enter Your Email"
            className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>

        <div className="flex flex-col my-4">
          <span>{t("Phone Number")}</span>
          <PhoneInput
            country={"sa"}
            onlyCountries={["sa"]}
            value={value}
            inputClass="h-[40px] w-full"
            containerClass="h-[40px]"
            onChange={(phone) => setValue(phone)}
            countryCodeEditable={false}
            isValid={(value, country) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name;
              } else if (value.match(/1234/)) {
                return false;
              } else {
                return true;
              }
            }}
          />
          {errorTxtN && (
            <span className="label-text-alt text-red-500 font-Roboto">
              {errorTxtN}
            </span>
          )}
        </div>

        {props?.alternate ? (
          <div className="flex flex-col my-2">
            <span>{t("Alternate_Contact_no")}</span>
            <input
              type="text"
              value={alternate_contact}
              onChange={(e) => {
                setContact(e.target.value);
              }}
              className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
            />
          </div>
        ) : null}

        <input
          type="submit"
          className="btn btn-primary w-full rounded text-white"
          value="Save"
        />
      </form>
    </>
  );
}

export default MapPicker;
