// import { useTranslation } from "react-i18next"
// const {t} = useTranslation()

const getOrderStatus = (status_id, text, {t}) => {
    if (status_id === 0) {
        return <p className="btn w-[118px] btn-sm btn-warning text-white rounded cursor-default">{text ? text : "Undelivered"}</p>
    }
    else if (status_id === 1) {
        return <p className="btn btn-sm w-[118px] btn-info text-white rounded cursor-default">{t("Shipped")}</p>
    }
    else if (status_id === 2) {
        return <p className="btn btn-sm w-[118px] btn-success text-white rounded cursor-default">{t("Delivered")}</p>
    }
    else if (status_id === 9) {
        return <p className="btn btn-sm w-[118px] btn-error text-white rounded cursor-default">{t("cancelled")}</p>
    }
    else if (status_id === 4) {
        return <p className="btn btn-sm w-[118px] btn-accent text-red-400 rounded cursor-default">{t("Return Req")}</p>
    }
    else if (status_id === 5) {
        return <p className="btn btn-sm w-[118px] btn-accent text-red-400 rounded cursor-default">{t("Request accepted")}</p>
    }
    else if (status_id === 6) {
        return <p className="btn btn-sm w-[118px] btn-accent text-red-400 rounded cursor-default">{t("Out for pickup")}</p>
    }
    else if (status_id === 7) {
        return <p className="btn btn-sm w-[118px] btn-accent text-red-400 rounded cursor-default">{t("Pickup completed")}</p>
    }
    else if (status_id === 8) {
        return <p className="btn btn-sm w-[118px] btn-accent text-red-400 rounded cursor-default">{t("Refund successful")}</p>
    }
    else if (status_id === 10) {
        return <p className="btn btn-sm w-[118px] btn-accent text-red-400 rounded cursor-default">{t("Request Cancelled")}</p>
    }
}

export default getOrderStatus;