import React from "react";
import { Puff, ThreeDots } from "react-loader-spinner";

const LoadingSpinner = () => {
  return (
    <div className="flex items-center backdrop-blur-sm justify-center w-full h-full fixed top-0 left-0">
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#F4900C"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
};

export default LoadingSpinner;
