import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import postData from "../../../hooks/postData";
import { domainProvider } from "../../../contexts/DomainContext";
import axios from "../../../components/utils/Interceptors";

const AddStuff = () => {
  const [roles, setRoles] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getAllRole = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallrole`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;

      if (response.data) {
        setRoles(response.data);
      }
    };

    getAllRole();
  }, []);

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);

    const response = await postData("/vendor/createstaff", data);

    if (response) {
      reset();
      toast.success("Data Saved Successfully");
      navigate("vendor/staffs");
    } else {
      toast.error("Something Went Wrong");
    }

    setLoading(false);
  };

  const { titleName } = useContext(domainProvider);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>Add Staff - {titleName}</title>
      </Helmet>
      <h2 className="text-title text-lg md:text-2xl pb-2">Add new Staff</h2>
      <div className="mb-20">
        <div className="max-w-xl mx-auto border bg-white">
          <div className="card-title border-b p-4">
            <h3 className="text-base font-medium">Staff information</h3>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="py-5 px-4">
              <div>
                <div className="my-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="name">
                          Name<span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-9">
                        <input
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder="Name"
                          {...register("vendor_name", { required: true })}
                        />
                        {errors.couponCode?.type === "required" &&
                          "Name is required"}
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="email">
                          Email<span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-9">
                        <input
                          type="email"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder="Email"
                          {...register("vendor_email")}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="Phone">
                          Phone<span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-9">
                        <input
                          type="number"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder="Phone"
                          {...register("vendor_contact")}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="Password">
                          Password<span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-9">
                        <input
                          type="password"
                          className="w-full h-[40px] input input-bordered rounded"
                          minLength={6}
                          placeholder="Password"
                          {...register("vendor_password")}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="role">
                          Role<span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-9">
                        <select
                          className="w-full h-[40px] input input-bordered rounded"
                          {...register("role_id")}
                          required
                        >
                          <option value="">Select Role</option>
                          {roles.map((role, i) => (
                            <option key={i} value={role.role_id}>
                              {role.role_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center mx-auto pb-5">
              <input
                className="py-1 px-3 bg-primary text-white rounded cursor-pointer"
                type="submit"
                value="Save"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddStuff;
