import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import getDataById from "../../../hooks/admin/getDataById";

const UpdateQuery = () => {
  const user = JSON.parse(localStorage.getItem("admin"));
  const { state } = useLocation();
  const navigate = useNavigate();
  const { register, setValue, handleSubmit } = useForm();

  useEffect(() => {
    const getCoupon = async () => {
      const res = await getDataById("admin/getbyidgeneralquery", state);

      if (res.status_code === 200) {
        setValue("name", res.data?.name);
        setValue("email", res.data?.email);
        setValue("phone", res.data?.phone);
        setValue("id", res.data?.id);
        setValue("query", res.data?.query);
        setValue("resolution", res.data?.resolution);
      }
    };
    getCoupon();
  }, []);

  const onSubmit = async (data) => {
    const info = {
      id: data.id,
      resolution: data.resolution,
    };

    const res = await fetch(
      `${process.env.REACT_APP_APIURL}/admin/updategeneralquery`,
      {
        method: "PATCH",
        body: JSON.stringify(info),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const response = await res.json();
    if (response?.status_code === 200) {
      toast.success(response.message);
      navigate("/admin/general_query");
    } else {
      toast.error("Something went Wrong");
    }
  };

  const backQuery = () => {
    navigate("/admin/general_quer");
  };

  return (
    <div className="mt-2 mb-16">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="max-w-3xl mx-auto">
          <div className="flex gap-x-4 bg-white border p-4">
            <h2 className="text-title text-lg md:text-xl uppercase">
              Update Customer Query
            </h2>
          </div>
          <div className="md:col-span-2 mx-auto">
            <div className="border bg-white">
              <div className="py-5 px-5">
                <div className="form-group mb-5">
                  <div className="grid md:grid-cols-2 gap-y-3">
                    <div>
                      <label className="text-[13px]" htmlFor="planName">
                        Customer Name <span className="text-error">*</span>
                      </label>
                    </div>
                    <div>
                      <input
                        className="w-full h-[40px] input input-bordered rounded"
                        readOnly
                        placeholder=" Name"
                        {...register("name")}
                      />
                    </div>
                    {/* <div>
                                            <label className="text-[13px]" htmlFor="planName"> Query Id <span className="text-error">*</span></label>
                                        </div>
                                        <div>
                                            <input className="w-full h-[40px] input input-bordered rounded" readOnly placeholder="query id"{...register("id")} />

                                        </div> */}

                    <div>
                      <label className="text-[13px]" htmlFor="planName">
                        Email{" "}
                      </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        readOnly
                        className="w-full h-[40px] input input-bordered rounded"
                        placeholder="customer email "
                        {...register("email")}
                      />
                    </div>
                    <div>
                      <label className="text-[13px]" htmlFor="planName">
                        Phone{" "}
                      </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        readOnly
                        className="w-full h-[40px] input input-bordered rounded"
                        placeholder="customer email "
                        {...register("phone")}
                      />
                    </div>

                    <div>
                      <label className="text-[13px]" htmlFor="planName">
                        Query
                      </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        readOnly
                        className="w-full h-[40px] input input-bordered rounded"
                        placeholder="customer query "
                        {...register("query")}
                      />
                    </div>

                    <div>
                      <label className="text-[13px]" htmlFor="planName">
                        Resolution
                      </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        className="w-full h-[40px] input input-bordered rounded"
                        placeholder="customer query "
                        {...register("resolution")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <input
              className="py-1 mt-2 px-3 bg-primary text-white rounded cursor-pointer"
              type="submit"
              value="Update"
            />
          </div>
        </div>
      </form>
      <div className="flex justify-center">
        <button
          onClick={backQuery}
          className="py-[6px] ml-3 mt-2 px-3 bg-primary text-white rounded cursor-pointer"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default UpdateQuery;
