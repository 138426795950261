import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FaArrowCircleLeft } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import getDataById from "../../../hooks/getDataById";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Iframe from "react-iframe";
import i18next from "i18next";

const VendorViewSingleProduct = () => {
  const { t } = useTranslation();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [tags, setTags] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const selectLanguage = i18next.language;
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getSingleProduct = async () => {
      const response = await getDataById("vendor/getbyidproduct", id);
      if (response.status_code === 200) {
        setProduct(response.data.product_details[0]);
        setColors(response.data.colors);
        setSizes(response.data.sizes);
        setTags(response.data.tags);
        setGalleryImages(response.data.images);
      } else {
        toast.error("something went wrong");
        navigate("/vendor/products");
      }
    };
    setLoading(false);

    getSingleProduct();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <div className="flex gap-x-4">
        <h2 className="text-title text-lg md:text-2xl pb-2 uppercase">
          {t("all_product_dataTable1")} {t("Details")}
        </h2>
        <Link to={"/vendor/products"}>
          <button className="btn btn-primary btn-xs text-white mb-5 flex justify-center items-center gap-x-2 mt-1">
            <FaArrowCircleLeft size={15} />
            {t("add_new_product_back")}
          </button>
        </Link>
      </div>

      <div className="mb-20">
        <div className="items-center place-content-center">
          <div className="grid grid-cols-1 md:grid-cols-4 bg-white border p-4 ">
            <div className="product-image" align="center">
              <img
                className="w-28 h-36 object-contain"
                src={product.thumbnail_image_url}
                alt="thumbnail"
              />
            </div>
            <div className="md:col-span-3 py-4">
              <h2 className="title text-lg font-semibold md:text-2xl mb-2">
                {selectLanguage == "ar" && product?.product_name_ar
                  ? product?.product_name_ar
                  : product?.product_name}
              </h2>

              <Rating readonly={true} initialValue={5} size={16} />

              <div className="mt-5 flex items-center gap-4">
                <div className="rounded border p-2 w-24" align="center">
                  <p>0</p>
                  <span className="text-sm text-gray-400">{t("Reviews")}</span>
                </div>
                <div className="rounded border p-2 w-24" align="center">
                  <p>0</p>
                  <span className="text-sm text-gray-400">
                    {t("in WishList")}
                  </span>
                </div>
                <div className="rounded border p-2 w-24" align="center">
                  <p>0</p>
                  <span className="text-sm text-gray-400">{t("in Cart")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-5">
            <div className="product-image bg-white border p-4">
              <div className="flex justify-between text-sm">
                <label className="font-medium">{t("Status")} :</label>
                <span
                  className={`badge text-white ${
                    product?.status ? "badge-success" : "badge-error"
                  }`}
                >
                  {" "}
                  {product?.status ? "Published" : "Draft"}
                </span>
              </div>
              <div className="flex justify-between text-sm my-2">
                <label className="font-medium">{t("vd_pc_Category")} :</label>
                <span>
                  {selectLanguage == "ar" && product?.category_name_ar
                    ? product?.category_name_ar
                    : product?.category_name?.length > 5
                    ? product?.category_name?.slice(0, 5)
                    : product?.category_name}
                </span>
              </div>
              <div className="flex justify-between text-sm my-2">
                <label className="font-medium">{t("vd_pt_Tags")} :</label>
                <span>
                  {tags.map(
                    (item) =>
                      `${
                        selectLanguage == "ar" && item.tag_name_ar
                          ? item.tag_name_ar
                          : item.tag_name.length > 5
                          ? item.tag_name?.slice(0, 5)
                          : item.tag_name
                      },`
                  )}
                </span>
              </div>
              <div className="flex justify-between text-sm my-2">
                <label className="font-medium">{t("vd_pc_Colors")} :</label>
                <span>
                  {colors.map(
                    (item) =>
                      `${
                        selectLanguage == "ar" && item.color_name_ar
                          ? item.color_name_ar
                          : item.color_name
                      }, `
                  )}
                </span>
              </div>
              <div className="flex justify-between text-sm my-2">
                <label className="font-medium">{t("vd_ps_Sizes")} :</label>
                <span>
                  {sizes.map(
                    (item) =>
                      `${
                        selectLanguage == "ar" && item.size_name_ar
                          ? item.size_name_ar
                          : item.size_name
                      }, `
                  )}
                </span>
              </div>
            </div>
            <div className="md:col-span-3 bg-white border">
              <div className="border-b">
                <h3 className="text-base font-medium p-3">
                  {t("all_product_dataTable1")} {t("Price")},{" "}
                  {t("add_new_product_stock")}
                </h3>
              </div>
              <div className="card-body p-4">
                <div className="overflow-x-auto mx-8">
                  <table className="table table-compact w-full">
                    <tr className="bg-white border p-3">
                      <td className="border">{t("Price")}</td>
                      <td className="border">{t("add_new_product_stock")}</td>
                      <td className="border">{t("add_new_product_SKU")}</td>
                    </tr>

                    <tbody>
                      <tr className="bg-white border p-3">
                        <td className="border">{product?.product_price} ر.س</td>
                        <td className="border">{product?.product_stock}</td>
                        <td className="border">{product?.product_sku}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* <div className="product-videos bg-white border">
              <div className="border-b">
                <h3 className="text-base font-medium p-3">
                  {t("add_new_product_video")}{" "}
                </h3>
              </div>
              <div className="p-4">
                {product.product_video_link ? (
                  <Iframe
                    url={product.product_video_link}
                    // position="absolute"
                    width="100%"
                    id="myId"
                    className="myClassname"
                    height="100%"
                  />
                ) : (
                  "No Videos Updated yet"
                )}
              </div>
            </div> */}
            <div className="md:col-span-3">
              <div className="bg-white border">
                <div className="border-b">
                  <h3 className="text-base font-medium p-3">{t("Gallery")}</h3>
                </div>
                <div className="card-body p-4">
                  <div className="flex gap-4">
                    {galleryImages.map((image, i) => (
                      // eslint-disable-next-line jsx-a11y/img-redundant-alt
                      <img
                        key={i}
                        src={image?.image_url}
                        alt="image"
                        className="w-28 h-36 object-contain mx-2 border border-gray-300 p-2"
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-5 bg-white border">
                <div className="border-b">
                  <h3 className="text-base font-medium p-3">
                    {t("Description")}
                  </h3>
                </div>

                <div className="card-body p-4">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: product?.product_description,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorViewSingleProduct;
