import React from "react";
import category from "../../../assets/guide/category.jpg";
import categoryAr from "../../../assets/guide/categoryAr.jpg";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const ManageCategory = () => {
  const selectLanguage = i18next.language;
  const { t } = useTranslation();
  return (
    <>
      <div>
        <h1 className="capitalize text-[30px] font-semibold text-[#236260] mt-[-4px] font-poppin">
          {t("vd_pc_Category")}
        </h1>
        <p className="text-lg text-gray-600 my-3">
          {selectLanguage === "ar"
            ? 'يمكنك هنا إضافة اسم الفئة لأحد المنتجات ووصف الفئة وفقا للمنتج. بعد النقر فوق "حفظ" سيعرض "الفئات".'
            : "Here you can add the Category Name for a Product and Category description according to the product. After that click on the Save and it will display the Categories."}
        </p>
        <img
          src={selectLanguage === "ar" ? categoryAr : category}
          className="w-full md:w-[60%] m-auto my-2"
        />
      </div>
    </>
  );
};

export default ManageCategory;
