import "../../StoreTemplate/StoreStyle/StoreStyle.css";
import React, { useContext, useEffect, useState } from "react";
import { MdOutlinePreview } from "react-icons/md";
import { TbTruckReturn } from "react-icons/tb";
import { CgTrack } from "react-icons/cg";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import StoreFooter from "../../StoreTemplate/StoreComponents/StoreFooter";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import getOrderStatus from "../../../components/utils/getOrderStatus";
import updateData from "../../../hooks/updateData";
import { TiDelete } from "react-icons/ti";
import { Helmet } from "react-helmet-async";
import { domainProvider } from "../../../contexts/DomainContext";
import axios from "../../../components/utils/CustomerInterceptor";

const storeName = window.location.pathname.split("/")[1];

const Orders = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("customer"));
  const [data, setData] = useState([]);
  const [fatch, setFatch] = useState(false);
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_APIURL;
  const { customdomain } = useContext(domainProvider);

  useEffect(() => {
    if (!userInfo.token) {
      navigate("login");
    }
    const fetchAllCustomerOrder = async () => {
      const res = await axios.get(
        `${baseUrl}/vendor/store/customer/getallorder`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      const response = await res.data;

      if (response.status_code === 200) {
        setData(response.data.reverse());
      }
    };

    fetchAllCustomerOrder();
  }, [fatch]);

  const columns = [
    {
      name: `${t("order_table1")}`,
      selector: (row) => row.torod_order_id,
      sortable: true,
    },

    {
      name: `${t("create_mobile")}`,
      selector: (row) => row.customer_contact,
      sortable: true,
    },

    {
      name: `${t("Status")}`,
      selector: (row) => row.status,
      cell: (row) => getOrderStatus(row.order_status, "Pending", { t }),
      sortable: true,
    },
    {
      name: `${t("order_table4")}`,
      selector: (row) => row.created_on.slice(0, 10),
      sortable: true,
    },
    {
      name: `${t("Price")}`,
      selector: (row) => row.order_price,
      sortable: true,
    },
    {
      name: `${t("Action")}`,
      selector: (row) => (
        <div className="flex justify-center items-center">
          <Link
            className="p-2 flex items-center border border-info hover:bg-info  rounded-full cursor-pointer duration-300 mr-3"
            title={`${t("View_Details")}`}
            to={`${
              customdomain === "aitech.com.sa"
                ? `/${storeName}/user/orders/order_details/${row.order_id}`
                : `/user/orders/order_details/${row.order_id}`
            }`}
          >
            <MdOutlinePreview className="text-green-500 hover:text-white text-lg" />
          </Link>

          {row.order_status === 0 && (
            <>
              <label
                onClick={() => updateStatus(row)}
                htmlFor="changeStatusModal"
                className={`p-2 flex items-center border border-info mr-3 hover:bg-info rounded-full text-info hover:text-white cursor-pointer duration-300`}
                title={`${t("V_allOrder_or_cl")}`}
              >
                <TiDelete className=" text-red-500 text-lg hover:text-white" />
              </label>
            </>
          )}

          {row.torod_tracking_id && (
            <label
              onClick={() => handleToTrack(row.torod_tracking_id)}
              className="p-2 flex items-center border border-info hover:bg-info mr-3 rounded-full cursor-pointer duration-300"
              title={`${t("Track")}`}
            >
              <CgTrack className="text-green-500 hover:text-white text-lg" />
            </label>
          )}

          {row.order_status === 2 && (
            <label
              onClick={() => handleToReturn(row.order_id)}
              className="p-2 flex items-center border border-info hover:bg-info  rounded-full cursor-pointer duration-300"
              title={`Return Request`}
            >
              <TbTruckReturn className="text-green-500 hover:text-white text-lg" />
            </label>
          )}
        </div>
      ),
      sortable: true,
    },
  ];

  const handleToTrack = async (id) => {
    if (id) {
      if (customdomain === "aitech.com.sa")
        navigate(`/${storeName}/tracking_page`, {
          state: { id: id, token: userInfo.token },
        });
      else {
        navigate(`/tracking_page`, {
          state: { id: id, token: userInfo.token },
        });
      }
    } else {
      toast.error("tracking not possible");
    }
  };

  const handleToReturn = async (id) => {
    const data = {
      order_id: id,
      order_status: 4,
    };

    const handleReturnRequest = async () => {
      const res = await axios.patch(
        `${baseUrl}/vendor/store/customer/updateorder`,
        JSON.stringify(data),
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      const response = await res.data;

      if (response.status_code === 200) {
        toast.success(response.message);
        setFatch(!fatch);
      } else {
        toast.error(response.message);
      }
    };

    handleReturnRequest();
  };

  const updateStatus = async (item) => {
    setLoading(true);
    const status = 9;
    const data = {
      order_id: item.order_id,
      order_status: status,
    };

    const res = await updateData("vendor/store/customer/updateorder", data);
    if (res.status_code === 200) {
      toast.success("Order cancel successful");
      setLoading(false);
      setFatch(!fatch);
    } else {
      setLoading(false);
      return toast.error(res.message || res.err);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex flex-col min-h-[100vh]">
      <Helmet>
        <title>All Orders - Dashboard</title>
      </Helmet>

      <h2 className="text-title text-lg md:text-2xl pb-2">{t("My_Orders")}</h2>
      <div className="min-h-[50vh] pb-6" style={{ flexGrow: 1 }}>
        <DataTable
          columns={columns}
          data={data}
          // selectableRows
          pagination
        />
      </div>
      <StoreFooter />
    </div>
  );
};

export default Orders;
