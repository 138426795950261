import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../../components/utils/Interceptors";

const AddShippingMethods = () => {
  const navigate = useNavigate();
  const [methods, setMethods] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    const getAllShippingMethods = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallshipping`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.status_code === 200) {
        const res2 = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/getallshippingsetting`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        const response2 = await res2.data;
        let addedIds = [];
        if (response2.status_code === 200) {
          if (response2.data && response2.data.length > 0) {
            addedIds = response2.data.map((item) => item.shipping_id);
          }
        }
        const shippings = response.data.map((item) => {
          if (addedIds.includes(item.shipping_id)) {
            return {
              ...item,
              added: true,
            };
          } else {
            return {
              ...item,
              added: false,
            };
          }
        });
        setMethods(shippings);
      }
    };

    getAllShippingMethods();
  }, []);

  const addShippingSetting = async (id) => {
    const res = await axios.post(
      `${process.env.REACT_APP_APIURL}/vendor/createshippingsetting`,
      JSON.stringify({ shipping_id: id }),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const response = await res.data;
    if (response.status_code === 200) {
      const newshippings = methods.map((shipping) => {
        if (shipping.shipping_id === id) {
          return {
            ...shipping,
            added: true,
          };
        }
        return shipping;
      });
      setMethods(newshippings);
      toast.success("Shipping Successfully, Please Add Key");
      navigate("vendor/settings/shipping-methods");
    } else {
      toast.error(response.message || response.err);
      return navigate("vendor/settings/shipping-methods");
    }
  };

  return (
    <div>
      <div className="flex justify-between">
        <h1 className="text-lg">Add Shipping Method to your store</h1>

        <Link
          className="btn btn-sm rounded btn-primary text-white font-thin"
          to={"/vendor/settings/shipping-methods"}
        >
          Back
        </Link>
      </div>

      <div className="my-5">
        {methods.length > 0 ? (
          <>
            {methods.map((shipping) => (
              <div
                key={shipping.shipping_id}
                className="bg-white rounded p-4 flex justify-between mb-2 items-center"
              >
                <div className="shipping-info flex gap-2 items-center">
                  <img
                    src={shipping.image}
                    className="w-20 h-16 object-contain"
                    alt=""
                  />
                  <h3 className="text-lg font-medium">
                    {shipping.shipping_name}
                  </h3>
                </div>
                {shipping.added ? (
                  <span className="btn btn-sm rounded-none bg-danger text-white border-none btn-disabled">
                    Already Added
                  </span>
                ) : (
                  <span
                    onClick={() => addShippingSetting(shipping.shipping_id)}
                    className="btn btn-sm rounded-none bg-secondary text-white border-none "
                  >
                    ADD{" "}
                  </span>
                )}
              </div>
            ))}
          </>
        ) : (
          <>No shipping methods available right now</>
        )}
      </div>
    </div>
  );
};

export default AddShippingMethods;
