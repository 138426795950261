import { confirmAlert } from "react-confirm-alert";
import deleteDataById from "./deleteDataById";
import { toast } from "react-toastify";

const deleteItem = async (id, state, setState, filteredItems, id_key, url) => {
  confirmAlert({
    title: "Click Yes to Delete",
    message: "Are you sure to delete this?",
    buttons: [
      {
        label: "Yes",
        onClick: async () => {
          const response = await deleteDataById(url, id);

          if (response.status_code === 200) {
            const newState = state.filter((item) => item[id_key] !== id);
            setState(newState);
            filteredItems = filteredItems.filter((item) => item[id_key] !== id);
            toast.success(response.message)
          }
        },
      },
      {
        label: "No",
        onClick: () => {},
      },
    ],
  });
};

export default deleteItem;
