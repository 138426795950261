import React from 'react';
import { Link } from 'react-router-dom'
import { FaArrowCircleLeft } from 'react-icons/fa';
const BackButton = ({to}) => {
    return (
        <div>
            <Link to={to}>
                <button className="btn btn-primary btn-xs text-white mb-5 flex justify-center items-center gap-x-2">
                    <FaArrowCircleLeft size={15} />
                    Go Back
                </button>
            </Link>
        </div>
    );
};

export default BackButton;