import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import { userProvider } from "./UserContext";
import { domainProvider } from "./DomainContext";
import { toast } from "react-toastify";
import instance from "../components/utils/Interceptors";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const storeInformation = createContext();

const StoreInformationContext = ({ children }) => {
  const [storeInfo, setStoreInfo] = useState({});
  const [storeDetails, setStoreDetails] = useState({});

  const urlStoreName = window.location.pathname.split("/")[1];
  const [storeName, setStoreName] = useState("");
  const { isVendor, isCustomer, userLoading } = useContext(userProvider);
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(true);

  // custom domain
  const { customdomain } = useContext(domainProvider);
  const navigate = useNavigate();
  const store_name = user?.storename;

  useEffect(() => {
    if (isVendor) {
      const getStoreData = async () => {
        const baseUrl = `${process.env.REACT_APP_APIURL}/vendor/getstorefulldetails/${store_name}`;
        const res = await instance.get(`${baseUrl}`, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        });

        const response = await res.data;
        // if (
        //   response.status_code === 400 &&
        //   response.message === "This store is not registered"
        // ) {
        //   navigate("/");
        // }
        if (response.status_code === 200) {
          setStoreName(response.data.store_name);
          // using custom domain for api calling
          {
            customdomain === "aitech.com.sa"
              ? await axios
                  .get(
                    `${process.env.REACT_APP_APIURL}/vendor/store/getstoredetails/${response.data.store_name}`
                  )
                  .then((res) => {
                    setStoreInfo(res.data.data.storeDetails);
                    setStoreDetails(res.data);
                    setLoading(false);
                  })
                  .catch((err) => {
                    toast.error("Something went wrong");
                  })
              : await axios
                  .get(
                    `${process.env.REACT_APP_APIURL}/vendor/store/getstoredetails`,
                    {
                      headers: {
                        domain_name: customdomain,
                      },
                    }
                  )
                  .then((res) => {
                    setStoreInfo(res.data.data.storeDetails);
                    setStoreDetails(res.data);
                    setLoading(false);
                  })
                  .catch((err) => {
                    // toast.error("Something went wrong")
                  });
          }
        }
      };

      getStoreData();
    } else {
      // using custom domain for api calling
      async function getStoreData() {
        customdomain === "aitech.com.sa"
          ? await axios
              .get(
                `${process.env.REACT_APP_APIURL}/vendor/store/getstoredetails/${urlStoreName}`
              )
              .then((res) => {
                // if (
                //   res.data.status_code === 400 &&
                //   res.data.message === "This store is not registered"
                // ) {
                //   navigate("/");
                // }
                setStoreInfo(res.data.data.storeDetails);
                setStoreDetails(res.data);
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                // toast.error("Something went wrong")
              })
          : await axios
              .get(
                `${process.env.REACT_APP_APIURL}/vendor/store/getstoredetails`,
                {
                  headers: {
                    domain_name: customdomain,
                  },
                }
              )
              .then((res) => {
                // if (
                //   res.data.status_code === 400 &&
                //   res.data.message === "This store is not registered"
                // ) {
                //   navigate("/");
                // }
                setStoreInfo(res.data.data.storeDetails);
                setStoreDetails(res.data);
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
              });
      }

      getStoreData();
    }
  }, [storeName, isVendor, isCustomer, customdomain]);

  if (userLoading) {
    return;
  }

  const info = {
    storeInfo,
    setStoreInfo,
    setStoreDetails,
    storeDetails,
    loading,
  };

  return (
    <storeInformation.Provider value={info}>
      {children}
    </storeInformation.Provider>
  );
};

export default StoreInformationContext;

// `${process.env.REACT_APP_APIURL}/vendor/store/getstoredetails/${response.data.store_name}`
