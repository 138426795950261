import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import err from "../../assets/err.png";
import Div from "../../components/utils/Div";
import LoadingSpinner from "../../components/utils/LoadingSpinner";
import ShopNavbar from "./StoreComponents/ShopNavbar";
import StoreFooter from "./StoreComponents/StoreFooter";
import i18next from "i18next";
import { domainProvider } from "../../contexts/DomainContext";
import { storeInformation } from "../../contexts/StoreInformationContext";
import axios from "../../components/utils/CustomerInterceptor";

export default function StoreThankYou() {
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const user = JSON.parse(localStorage.getItem("customer"));
  const orderDetails = JSON.parse(localStorage.getItem("orderDetails"));
  const [status, setStatus] = useState();
  const selectLanguage = i18next.language;
  const { storeDetails } = useContext(storeInformation);
  const storeName = storeDetails?.data?.storeDetails?.store_name
    ? storeDetails?.data?.storeDetails?.store_name
    : window.location.href.split("/")[3];

  useEffect(() => {
    const id = searchParams.get("id");
    const status = searchParams.get("status");
    setStatus(status);

    if (status === "paid") {
      const updateOrder = async () => {
        const data = {
          order_id: orderDetails?.order_id,
          payment_method: orderDetails?.payment_method,
          payment_status: status,
          transaction_id: id,
        };
        const res = await axios.patch(
          `${process.env.REACT_APP_APIURL}/vendor/updateorder`,
          JSON.stringify(data),
          {
            headers: {
              "Content-type": "Application/json",
              Authorization: `Bearer ${user ? user.token : ""}`,
            },
          }
        );
        let response = await res.data;
        if (response.status_code === 200) {
          const shipData = {
            order_id: orderDetails?.order_id,
          };
          const res = await axios.post(
            `${process.env.REACT_APP_APIURL}/vendor/shipOrder`,
            JSON.stringify(shipData),
            {
              headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${user ? user.token : ""}`,
              },
            }
          );
          let response = await res.data;
          if (response.status_code === 200) {
            toast.success("Your Order successfully Placed");
            localStorage.removeItem("cart");
            localStorage.removeItem("delieveryPartner");
          } else if (response.status_code === 400) {
            toast.error(response.message.locate_address || response.message);
            setStatus("faild");
            setLoading(false);
            const deleteOrder = async () => {
              const res = await axios.delete(
                `${process.env.REACT_APP_APIURL}/vendor/deleteorder/${orderDetails?.order_id}`,
                {
                  headers: {
                    Authorization: `Bearer ${user ? user.token : ""}`,
                  },
                }
              );
              let response = await res.data;
              if (response.status_code === 200) {
                // toast.info(response.message)
              } else {
                toast.error(response.message);
              }
            };
            deleteOrder();
          } else {
            toast.error(response.err);
            setStatus("Failed");
          }
          setLoading(false);
        } else {
          toast.error(response.message);
          setStatus("faild");
          setLoading(false);
        }
      };
      updateOrder();
    } else {
      setStatus("failed");
      const deleteOrder = async () => {
        const res = await axios.delete(
          `${process.env.REACT_APP_APIURL}/vendor/deleteorder/${orderDetails?.order_id}`,
          {
            headers: {
              Authorization: `Bearer ${user ? user.token : ""}`,
            },
          }
        );
        let response = await res.data;
        setLoading(false);
        if (response.status_code === 200) {
          toast.info(response.message);
        } else {
          toast.error(response.message);
        }
      };
      deleteOrder();
    }
  }, []);

  // custom domain
  const { customdomain, titleName } = useContext(domainProvider);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <ShopNavbar />
      <Helmet>
        <title>Order - {titleName}</title>
      </Helmet>
      <Div>
        <div className="px-5 mx-auto md:px-16">
          <div className="py-4 container flex gap-3 items-center">
            <a
              href={storeName ? `/${storeName}` : "/"}
              className="text-primary text-base"
            >
              <i className="fas fa-home"></i>
            </a>
            <span className="text-sm text-gray-400">
              <i className="fas fa-chevron-right"></i>
            </span>
            {status === "paid" ? (
              <p className="text-gray-600 font-medium uppercase">
                {selectLanguage === "ar" ? "شكرا لك" : "Thankyou"}
              </p>
            ) : (
              <p className="text-gray-600 font-medium uppercase">
                {selectLanguage === "ar" ? "نحن آسفون" : "We Are Sorry"}
              </p>
            )}
          </div>
        </div>
        <div className="border p-10 my-20 mx-auto">
          {status === "paid" ? (
            <div className="flex justify-center my-5">
              <img
                src="https://megamart.pythonistindia.com/frontend/assets/images/thankyou-img.png"
                alt="img"
              />
            </div>
          ) : (
            <div className="flex justify-center my-5">
              <img src={err} alt="img" />
            </div>
          )}

          {status === "paid" ? (
            <div className="thankyou-content text-center">
              <div>
                <p className="text-xl font-bold">
                  {selectLanguage === "ar"
                    ? "شكرا على طلبك!"
                    : "Thank you for your order!"}
                </p>
              </div>
              <div>
                <p>
                  {selectLanguage === "ar"
                    ? "تم وضع طلبك وستتم معالجته في أقرب وقت ممكن."
                    : "Your order has been placed and will be processed as soon as possible."}
                </p>
                <p>
                  {selectLanguage === "ar"
                    ? "ستتلقى بريدا إلكترونيا بعد قليل مع تأكيد طلبك."
                    : "You will be receiving an email shortly with confirmation of your order."}
                </p>
              </div>
            </div>
          ) : (
            <div className="thankyou-content text-center">
              <div>
                <p className="text-xl font-bold">
                  {selectLanguage === "ar"
                    ? "طلبك غير مكتمل!"
                    : "Your Order Not Complete!"}
                </p>
              </div>
              <div>
                <p>
                  {selectLanguage === "ar"
                    ? "تم حذف طلبك. الرجاء إنشاء أمر جديد."
                    : "Your Order has been Deleted. Please Create A new Order."}
                </p>
                <p>
                  {selectLanguage === "ar"
                    ? "شكرا لك، شحن سعيد"
                    : "Thank You, Happy Shipping"}
                </p>
              </div>
            </div>
          )}

          <div className="my-4 flex justify-center">
            <Link
              to={`${
                customdomain === "aitech.com.sa"
                  ? `/${storeName}/shop`
                  : `/shop`
              }`}
              className="py-2 text-sm px-4 bg-primary text-white rounded font-normal thankBtn"
            >
              {selectLanguage === "ar"
                ? "عرض منتجاتنا مرة أخرى"
                : "View our products again"}
            </Link>
          </div>
        </div>
      </Div>
      <StoreFooter />
    </>
  );
}
