import React, { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import FilterComponent from "../../../components/utils/FilterComponent";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import deleteDataById from "../../../hooks/deleteDataById";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "../../../components/utils/Interceptors";

const AllCoupon = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  let filteredItems = coupons.filter(
    (item) =>
      item.coupon_code &&
      item.coupon_code.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    const getAllCoupons = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallcoupon`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const result = await response.data;
      if (result.status_code === 200 && result.data) {
        setCoupons(result.data);
      }
    };

    getAllCoupons();
  }, []);

  const columns = [
    {
      name: `${t("coupon_code")}`,
      selector: (row) => row.coupon_code,
    },
    {
      name: `${t("coupon_type")}`,
      selector: (row) => row.coupon_type,
    },
    {
      name: `${t("Minimum Purchase Amount")}`,
      selector: (row) => row.minimum_purchase_amount,
    },
    {
      name: `${t("Discount")}`,
      selector: (row) => row.maximum_discount,
    },
    {
      name: `${t("coupon_st_date")}`,
      selector: (row) => moment(row.from_date).format("DD-MMM-YYYY"),
    },
    {
      name: `${t("coupon_end_date")}`,
      selector: (row) => moment(row.to_date).format("DD-MMM-YYYY"),
    },

    {
      name: `${t("vd_pc_C_Options")}`,
      sortable: true,
      cell: (row) => (
        <div className="flex gap-2">
          <span
            onClick={() => selectItemToEdit(row.coupon_id)}
            className="p-2 flex items-center border border-secondary hover:bg-secondary rounded-full text-secondary hover:text-white cursor-pointer duration-300"
            title={`${t("Edit")}`}
          >
            <AiOutlineEdit />
          </span>
          <span
            onClick={() => deleteItem(row.coupon_id)}
            className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300"
            title={`${t("Delete")}`}
          >
            <AiOutlineDelete />
          </span>
        </div>
      ),
    },
  ];

  const selectItemToEdit = async (id) => {
    navigate("/vendor/edit_coupon", {
      state: id,
    });
  };

  const deleteItem = async (id) => {
    confirmAlert({
      title: `${t("delete_alert_title")}`,
      message: `${t("delete_alert_des")}`,
      buttons: [
        {
          label: `${t("yes")}`,
          onClick: async () => {
            setLoading(true);

            const response = await deleteDataById("vendor/deletecoupon", id);

            if (response.status_code === 200) {
              const newdataList = coupons.filter(
                (item) => item.coupon_id !== id
              );
              setCoupons(newdataList);
              filteredItems = filteredItems.filter(
                (item) => item.coupon_id !== id
              );
            } else {
              toast.error("something went wrong");
            }

            setLoading(false);
          },
        },
        {
          label: `${t("no")}`,
          onClick: () => {},
        },
      ],
    });
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <div className="flex justify-between my-2">
        <h2 className="text-title text-lg md:text-2xl pb-2">
          {t("coupon_al_c")}
        </h2>
        <Link
          to="/vendor/add-coupon"
          className="ml-5 btn btn-primary text-white font-normal btn-sm rounded"
        >
          {t("coupon_al_add_c")}
        </Link>
      </div>
      <div>
        <DataTable
          columns={columns}
          data={filteredItems}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          // selectableRows
          pagination
          noDataComponent={
            <h4 className="py-8 text-gray-400 text-center">
              {t("No records")}
            </h4>
          }
        />
      </div>
    </>
  );
};

export default AllCoupon;
