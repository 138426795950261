import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import StoreFooter from "../StoreTemplate/StoreComponents/StoreFooter";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ImageUploading from "react-images-uploading";
import { Helmet } from "react-helmet-async";
import { domainProvider } from "../../contexts/DomainContext";
import axios from "../../components/utils/CustomerInterceptor";

const Profile = () => {
  const { t } = useTranslation();
  const storeName = window.location.pathname.split("/")[1];
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const customerInfo = JSON.parse(localStorage.getItem("customer"));
  const { customdomain } = useContext(domainProvider);

  useEffect(() => {
    if (!customerInfo?.token) {
      if (customdomain === "aitech.com.sa")
        return navigate(`/${storeName}/register-login`);
      else {
        return navigate(`/register-login`);
      }
    }
    setUser(customerInfo.data);
  }, []);

  const [images, setImages] = useState([]);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();

  const handleToUpdate = async (data) => {
    const info = new FormData();
    if (images[0]?.file) {
      info.append("profile_image", images[0]?.file);
    }
    if (data.cust_city) {
      info.append("cust_city", data.cust_city);
    }
    if (data.cust_country) {
      info.append("cust_country", data.cust_country);
    }
    if (data.cust_fullname) {
      info.append("cust_fullname", data.cust_fullname);
    }
    if (data.cust_phone) {
      info.append("cust_phone", data.cust_phone);
    }
    if (data.cust_zip) {
      info.append("cust_zip", data.cust_zip);
    }

    const res = await axios.patch(
      `${process.env.REACT_APP_APIURL}/vendor/store/customer/updateprofile`,
      info,
      {
        headers: {
          Authorization: `Bearer ${customerInfo.token}`,
        },
      }
    );
    const response = await res.data;
    if (response.status_code === 200) {
      toast.success(response.message);
      const customerInfo = JSON.parse(localStorage.getItem("customer"));
      customerInfo.data = response.data;
      localStorage.setItem("customer", JSON.stringify(customerInfo));
      window.location.reload();
      return;
    }
    toast.error(response.err);
  };

  return (
    <>
      <Helmet>
        <title>Profile - Dashboard</title>
      </Helmet>
      <h2 className="text-title text-lg md:text-2xl pb-2">
        {t("User_Profile")}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 pb-6">
        <div className="p-4 border border-gray-200 bg-white shadow-sm w-full">
          <h2 className="text-title text-md md:text-xl pb-2 uppercase">
            {t("Basic_Info")}
          </h2>
          <div className="overflow-x-auto">
            <div className="flex justify-center my-5">
              <div className="avatar online">
                <div className="w-24 rounded-full">
                  {!user?.profile_image ? (
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                      alt=""
                    />
                  ) : (
                    <img src={user?.profile_image} alt="" />
                  )}
                </div>
              </div>
            </div>

            <table className="table w-full">
              <tbody>
                <tr>
                  <td>{t("form_name")}: </td>
                  <td>{user?.cust_fullname}</td>
                </tr>
                <tr>
                  <td>{t("form_email")}</td>
                  <td>{user?.cust_email}</td>
                </tr>
                <tr>
                  <td>{t("Cus_phone")}</td>
                  <td>{user?.cust_phone}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="p-4 border border-gray-200 bg-white shadow-sm w-full">
          <h2 className="text-title text-md md:text-xl pb-2 uppercase">
            {t("Update_Info")}
          </h2>
          <form
            onSubmit={handleSubmit(handleToUpdate)}
            className="flex flex-col gap-4"
          >
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">
                  {t("Full_Name")}
                  <span className="text-red-600">*</span>
                </span>
              </label>
              <input
                {...register("cust_fullname")}
                type="text"
                defaultValue={user?.cust_fullname}
                placeholder={t("Full_Name")}
                required
                className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
              />
              <label className="label">
                {errors.cust_fullname?.type === "required" && (
                  <span className="label-text-alt text-red-500 font-Roboto">
                    {t("name_is_required")}
                  </span>
                )}
              </label>
            </div>

            <div className="border bg-white my-5">
              <div className="card-title border-b p-4">
                <h3 className="text-base font-medium">
                  {t("p_image")}{" "}
                  <span className="text-2xs text-gray-400"> ( 300x300 )</span>{" "}
                </h3>
              </div>
              <div className="py-5 px-5">
                <div className="col-span-8">
                  <ImageUploading
                    value={images}
                    onChange={onChange}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <p
                          className="text-xs cursor-pointer py-2 px-3"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          {t("add_new_product_T_img_info")}
                        </p>
                        &nbsp;
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <div className="image-item__btn-wrapper">
                              {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                              <button
                                className="text-red-600"
                                onClick={() => onImageRemove(index)}
                              >
                                X
                              </button>
                            </div>
                            <img
                              className="w-20 h-20"
                              src={image["data_url"]}
                              alt=""
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
                <small className="text-2xs text-gray-400">
                  {t("add_new_product_T_img_info2")}
                </small>
              </div>
            </div>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">
                  {t("e_address")} <span className="text-red-600">*</span>
                </span>
              </label>
              <input
                type="email"
                value={user?.cust_email}
                disabled
                placeholder={t("e_address")}
                className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
              />
            </div>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">
                  {t("create_mobile")} <span className="text-red-600">*</span>
                </span>
              </label>
              <input
                {...register("cust_phone")}
                type="number"
                defaultValue={user?.cust_phone}
                placeholder={t("create_mobile")}
                className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
              />
            </div>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">
                  {t("country_name")} <span className="text-red-600">*</span>
                </span>
              </label>
              <input
                {...register("cust_country")}
                type="text"
                defaultValue={user?.cust_country}
                placeholder={t("country_name")}
                className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
              />
            </div>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">
                  {t("city_name")} <span className="text-red-600">*</span>
                </span>
              </label>
              <input
                {...register("cust_city")}
                type="text"
                defaultValue={user?.cust_city}
                placeholder={t("city_name")}
                className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
              />
            </div>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">
                  {t("zip_code")} <span className="text-red-600">*</span>
                </span>
              </label>
              <input
                {...register("cust_zip")}
                type="text"
                defaultValue={user?.cust_zip}
                placeholder={t("zip_code")}
                className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary max-w-lg text-white"
            >
              {t("Update_Changes")}
            </button>
          </form>
        </div>
      </div>
      <StoreFooter />
    </>
  );
};

export default Profile;
