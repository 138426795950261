import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import i18next, { use } from "i18next";
import { useTranslation } from "react-i18next";
import Partners from "./Partners";
import FilterComponent2 from "../../../components/utils/FilterComponent2";
import axios from "../../../components/utils/Interceptors";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const ShippingMethods = () => {
  const { t } = useTranslation();
  let selectLanguage = i18next.language;
  const [shippings, setShippings] = useState([]);
  const [courierPartners, setCourierPartners] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [fatch, setFatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [storeDetails, setStoreDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [loginPwd, setLoginPwd] = useState("");
  const [showShip, setShowShip] = useState(false);

  useEffect(() => {
    const getAllshippings = async () => {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallshippingsetting`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      setLoading(false);
      if (response.data) {
        setShippings(response.data);
      }
    };
    const getstoreDetails = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getstorefulldetails`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        setStoreDetails(response.data);
      }
    };
    getAllshippings();
    getstoreDetails();
  }, [fatch]);

  useEffect(() => {
    const getAllCourierPartners = async () => {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/torod/courierpartners`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.status_code == 400) {
        toast.error(response.message);
      } else {
        response.map((partner) => {
          let findPartner = shippings.find(
            (shi) => shi.torod_shipping_id == partner.id
          );
          if (findPartner?.is_enabled == 1) {
            partner["is_enabled"] = true;
          }
        });
        setCourierPartners(response);
      }
      setLoading(false);
    };
    getAllCourierPartners();
  }, [shippings]);

  const setPartnerInfo = (id, name, name_ar, e, is_all = null) => {
    if (e == 1) {
      const data = {
        torod_shipping_id: id,
        torod_shipping_name: name,
        torod_shipping_name_arabic: name_ar,
        is_enabled: e,
      };
      const shippingSetting = async () => {
        setLoading(true);
        const res = await axios.post(
          `${process.env.REACT_APP_APIURL}/vendor/createshippingsetting`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        const response = await res.data;
        if (response.status_code == 200) {
          setFatch(!fatch);
          if (is_all == null) toast.success(response.message);
        } else {
          setLoading(false);
          if (is_all == null) toast.error(response.err || response.message);
        }
      };
      shippingSetting();
    }
    if (e == 0) {
      const data = {
        torod_shipping_id: id,
        torod_shipping_name: name,
        torod_shipping_name_arabic: name_ar,
        is_enabled: e,
      };
      const shippingSetting = async () => {
        setLoading(true);
        const res = await axios.post(
          `${process.env.REACT_APP_APIURL}/vendor/createshippingsetting`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        const response = await res.data;
        if (response.status_code == 200) {
          setFatch(!fatch);
          if (is_all == null) toast.success(response.message);
        } else {
          setLoading(false);
          if (is_all == null) toast.error(response.err || response.message);
        }
      };
      shippingSetting();
    }
  };

  const createShippingWarehouse = async () => {
    setLoading(true);
    const res3 = await axios.get(
      `${process.env.REACT_APP_APIURL}/vendor/createtorodwarehouse`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const response3 = await res3.data;
    if (response3.status_code == 200) {
      toast.success(response3.message);
      setFatch(!fatch);
    } else {
      setLoading(false);
      if (typeof response3.message === "string") {
        toast.error(response3.message);
      } else {
        toast.error(JSON.stringify(response3.message));
      }
    }
  };
  const enableShippingSetting = async () => {
    setLoading(true);
    const res3 = await axios.get(
      `${process.env.REACT_APP_APIURL}/vendor/enabletorodshipping`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const response3 = await res3.data;
    if (response3.status_code == 200) {
      toast.success(response3.message);
      setFatch(!fatch);
    } else {
      setLoading(false);
      toast.error(response3.err || response3.message);
    }
  };

  const [filterText, setFilterText] = useState("");
  let filteredItems = courierPartners?.filter(
    (item) =>
      item.title?.toLowerCase().includes(filterText.toLowerCase()) ||
      item.title_arabic?.includes(filterText)
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = (e) => {
      if (filterText) {
        setFilterText("");
        e.target.value = "";
      }
    };

    return (
      <div className="flex items-center">
        <FilterComponent2
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          setFilterText={setFilterText}
          filterText={filterText}
        />
      </div>
    );
  }, [filterText]);

  // check whether user exists or not
  useEffect(() => {
    if (
      storeDetails?.torod_user_id === null ||
      storeDetails?.torod_user_id === undefined
    )
      setButtonText(
        selectLanguage == "ar" ? "إنشاء حساب الشحن" : "Create shipping account"
      );
    else
      setButtonText(
        selectLanguage == "ar"
          ? "تسجيل الدخول إلى حساب الشحن"
          : "Login shipping account"
      );
  }, [storeDetails, selectLanguage]);

  const createShippingAccount = async () => {
    setLoading(true);
    if (
      storeDetails?.torod_user_id === null ||
      storeDetails?.torod_user_id === undefined
    ) {
      const url = `${process.env.REACT_APP_APIURL}/vendor/createtoroduser`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      const response = await res.data;

      if (response) {
        if (response.status_code == 200 || response.status_code == 400) {
          setShowShip(true);
          setLoading(false);
          toast.success(response.message);
          setButtonText("Login shipping account")
        } else {
          setLoading(false);
          if (typeof response.message === "string") {
            toast.error(response.message);
          } else {
            toast.error(JSON.stringify(response.message));
          }
          // let x = Object.keys(response.message);

          // toast.error(response.message[Object.keys(response.message)[0]]);
        }
      }
    } else {
      setLoading(false);
      setOpen(true);
    }
  };

  const handleUninstall = async () => {
    setLoading(true);

    // const formData = new FormData();
    // formData.append("app_id", storeDetails.torod_app_id);
    // formData.append("plugin", process.env.TOROD_PLUGIN_NAME);
    // formData.append("user_id", storeDetails.torod_user_id);

    const url = `${process.env.REACT_APP_APIURL}/vendor/dlttoroduser`;

    await axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  async function handleLoginPlugin(e) {
    e.preventDefault();
    setLoading(true);

    const data = {
      password: loginPwd,
      email: user?.vendor_email,
    };

    const url = `${process.env.REACT_APP_APIURL}/vendor/logintoroduser`;
    const res = await axios.post(url, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    const response = await res.data;

    if (response) {
      setLoading(false);
      setOpen(false);
      setLoginPwd("");
      if (response.status_code == 200) {
        toast.success("Login successful");
        setShowShip(true);
      } else {
        toast.error(response?.message);
      }
    }
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      {storeDetails.torod_user_id &&
      storeDetails.torod_warehouse_id &&
      storeDetails.torod_shipping_enabled &&
      showShip ? (
        <div>
          <div className="flex justify-between mb-5 mt-2">
            <div className="flex items-center">
              <h1 className="text-lg">
                {t("Available")} {t("Shipping_Methods")}
              </h1>
              {/* <button onClick={handleAllPartner} className='btn btn-primary btn-sm ml-3 hover:text-white'>Enable All Partner</button>  */}
            </div>
            {/* <Link className="btn btn-sm rounded btn-primary text-white font-thin" to={'/vendor/settings/shipping-methods/add'}>Add shipping</Link>  */}
            {subHeaderComponentMemo}
          </div>
          <div className="bg-gray-50 p-3">
            {filterText.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {filterText &&
                  filteredItems.map((partner) => (
                    <div key={partner.id}>
                      <Partners
                        partner={partner}
                        setPartnerInfo={setPartnerInfo}
                        courierPartners={courierPartners}
                      />
                    </div>
                  ))}
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {courierPartners.map((partner) => (
                  <div key={partner.id}>
                    <Partners
                      partner={partner}
                      setPartnerInfo={setPartnerInfo}
                      courierPartners={courierPartners}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <h3 className="mb-4">
            {t("Shipping_Methods")} ({t("How It Works")}):{" "}
          </h3>

          <div className="p-4 border border-gray-200 bg-white shadow-sm ">
            <div className="flex justify-between mb-4 sm:my-4 w-full xl:w-9/12 flex-col sm:flex-row">
              <div className="my-2 sm:my-0">
                <h4 className="text-xl font-bold">{buttonText}</h4>
                <p>
                  {selectLanguage == "ar"
                    ? "تسجيل الدخول إلى حسابك الحالي لتمكين إعدادات الشحن"
                    : "Login to your existing account to enable shipping settings"}
                </p>
              </div>

              <button
                onClick={createShippingAccount}
                className="btn-primary rounded-lg px-4 py-2 h-fit my-auto max-w-[13rem] font-semibold md:w-56 w-1/2 hover:text-white"
              >
                {buttonText}
              </button>
            </div>

            <div className="flex justify-between my-4 w-full xl:w-9/12 flex-col sm:flex-row">
              <div className="my-2 sm:my-0">
                <h4 className="text-xl font-bold">
                  {selectLanguage == "ar"
                    ? "إنشاء مستودع الشحن"
                    : "Create Shipping Warehouse"}
                </h4>
                <p className="w-11/12">
                  {selectLanguage == "ar"
                    ? "إنشاء مستودع الشحن إذا لم تقم بإنشائه حتى الآن. يرجى تجاهل هذه الخطوة إذا كنت قد قمت بإنشاء مستودع الشحن بالفعل."
                    : "Create your warehouse if you have not created till now. Please ignore this step if you have already created your warehouse"}
                </p>
              </div>
              <button
                onClick={createShippingWarehouse}
                className={`btn-primary rounded-lg px-4 py-2 h-fit my-auto max-w-[13rem] font-semibold md:w-56 w-1/2 hover:text-white disabled:opacity-50 ${
                  storeDetails.torod_warehouse_id
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                disabled={storeDetails.torod_warehouse_id ? true : false}
              >
                {selectLanguage == "ar" ? "إنشاء مستودع" : "Create Warehouse"}
              </button>
            </div>

            <div className="flex justify-between my-4 w-full xl:w-9/12 flex-col sm:flex-row">
              <div className="my-2 sm:my-0">
                <h4 className="text-xl font-bold">
                  {selectLanguage == "ar" ? "تمكين الشحن" : "Enable Shipping"}
                </h4>
                <p className="w-11/12">
                  {selectLanguage == "ar"
                    ? "لتمكين الشحن، انقر على الزر، تجاهل هذه الخطوة إذا تمت المهمة بالفعل"
                    : "To enable shipping click the button, ignore this step if already done"}
                </p>
              </div>

              <button
                onClick={enableShippingSetting}
                className={`btn-primary rounded-lg px-4 py-2 h-fit my-auto font-semibold max-w-[13rem] md:w-56 w-1/2 hover:text-white disabled:opacity-50 ${
                  storeDetails.torod_shipping_enabled
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                disabled={storeDetails.torod_shipping_enabled ? true : false}
              >
                {selectLanguage == "ar"
                  ? "تمكين الشحن"
                  : "Enable Shipping Setting"}
              </button>
            </div>

            <div className="flex justify-between my-4 w-full xl:w-9/12 flex-col sm:flex-row">
              <div className="my-2 sm:my-0">
                <h4 className="text-xl font-bold">
                  {selectLanguage == "ar"
                    ? "حذف حساب الشحن"
                    : "Delete shipping account"}
                </h4>
                <p className="w-11/12">
                  {selectLanguage == "ar"
                    ? "حذف حساب الشحن الخاص بك"
                    : "Delete your shipping account"}
                </p>
              </div>
              <button
                onClick={handleUninstall}
                className={`btn-primary rounded-lg px-4 py-2 h-fit my-auto font-semibold md:w-56 w-1/2 max-w-[13rem] hover:text-white disabled:opacity-50 ${
                  !storeDetails.torod_user_id
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                disabled={true}
                // disabled={!storeDetails.torod_user_id ? true : false}
              >
                {selectLanguage == "ar"
                  ? "حذف حساب الشحن"
                  : "Delete Shipping Account"}
              </button>
            </div>
          </div>
        </div>
      )}

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        center
        className="w-full"
        // styles={{"direction" selectLanguage:"ar" ? }}
      >
        <div>
          <h4 className="my-2">
            {selectLanguage == "ar"
              ? "تسجيل الدخول إلى حساب الشحن"
              : "Login Shipping Account"}
          </h4>
          <form onSubmit={handleLoginPlugin}>
            <div className="flex flex-col my-4 w-72 sm:w-80 md:w-96">
              <span>
                {selectLanguage == "ar"
                  ? "عنوان البريد الإلكتروني"
                  : "Email Address"}
              </span>
              {
                <input
                  readOnly
                  value={user?.vendor_email}
                  type="text"
                  required
                  className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
                />
              }
            </div>

            <div className="flex flex-col my-4 w-72 sm:w-80 md:w-96">
              <span>
                {selectLanguage == "ar" ? "كلمة المرور" : "Password"}{" "}
                <span className="text-xs text-gray-500">
                  (
                  {selectLanguage == "ar"
                    ? "أدخل كلمة المرور التي تم مشاركتها معك في بريدك الإلكتروني عند إنشاء حساب الشحن"
                    : "Enter the password shared to you on your mail when creating shiping account"}
                  )
                </span>
              </span>
              {
                <input
                  onChange={(e) => {
                    setLoginPwd(e.target.value);
                  }}
                  value={loginPwd}
                  type="text"
                  required
                  className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
                />
              }
            </div>

            <button
              type="submit"
              className="btn btn-primary h-[32px] mt-4 mb-8 w-full rounded text-white"
            >
              Submit
            </button>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ShippingMethods;
