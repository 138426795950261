import React from "react";
import user from "../../assets/user_male.png";
import store from "../../assets/shop.png";
import rocket from "../../assets/launch.png";
import SectionTitle from "../utils/SectionTitle";
import { FaGreaterThan } from "react-icons/fa";
import { AiFillCaretDown } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import Div from "../utils/Div";
import i18next from "i18next";
import { HiArrowLeft } from "react-icons/hi";

const HowItWorks = () => {
  const { t } = useTranslation();
  let selectLanguage = i18next.language;

  const contents = [
    {
      icon: user,
      step: "Step 1",
      title: t("How_IW_title1"),
      description: t("How_IW_desc1"),
    },
    {
      icon: store,
      step: "Step 2",
      title: t("How_IW_title2"),
      description: t("How_IW_desc2"),
    },
    {
      icon: rocket,
      step: "Step 3",
      title: t("How_IW_title3"),
      description: t("How_IW_desc3"),
    },
  ];

  return (
    <section className="bg-[#F7F7F7] my-8">
      <Div>
        <div className="py-5 px-2">
          <SectionTitle page={"howtoworks"} />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-y-10 gap-x-16 py-8 place-center">
            {contents.map((content, idx) => (
              <div key={idx} className="relative duration-300">
                <div className="bg-white py-10 shadow-2xl px-16 rounded-xl h-80">
                  <figure className="flex justify-center mt-[-20px] mb-3">
                    <img className="w-20" src={content.icon} alt="" />
                  </figure>
                  <p className="text-[16px] text-[#2E2E2E] uppercase font-bold font-poppin py-3 md:py-5 text-center">
                    {content.title}
                  </p>
                  <p className="text-[14px] text-[#2E2E2E] text-center">
                    {content.description}
                  </p>
                </div>
                <div
                  className={`${
                    (content.title === "The Launch Continues" ||
                      (selectLanguage === "ar" &&
                        content.title === t("How_IW_title1"))) &&
                    "hidden"
                  }`}
                >
                  <div className="absolute hidden md:block top-1/3  right-[-80px] z-10 bg-[#F4900C] rounded-full">
                    <p className="p-7 text-white">
                      {selectLanguage === "ar" ? (
                        <HiArrowLeft size={40} />
                      ) : (
                        <FaGreaterThan size={40} />
                      )}
                    </p>
                  </div>
                  <div className="absolute hidden  top-1 left-1 bg-[#F4900C] rounded-full">
                    <p>
                      <AiFillCaretDown />
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Div>
    </section>
  );
};

export default HowItWorks;
