import React, { useEffect, createContext, useState } from 'react'

export const userProvider = createContext()
const UserContext = ({ children }) => {
  const [isVendor, setIsVendor] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [userLoading, setUserLoading] = useState(false)
  const [action, setAction] = useState(false)
  const refetchUser = () => {
    setAction(!action)
  }
  useEffect(() => {
    setUserLoading(true)
    const vendor = JSON.parse(localStorage.getItem('user'))
    const customer = JSON.parse(localStorage.getItem('customer'));
    if (vendor?.token) {
      setIsVendor(true)
    }
    if (customer?.token) {
      setIsCustomer(true)
    }
    setUserLoading(false)
  }, [action])

  if (userLoading) {
    return
  }

  const info = { isVendor, isCustomer, refetchUser, userLoading }
  return (
    <userProvider.Provider value={info}>{children}</userProvider.Provider>
  )
}

export default UserContext