import React from "react";
import order1 from "../../assets/guide/order1.jpg";
import order2 from "../../assets/guide/order2.jpg";
import order1Ar from "../../assets/guide/order1-ar.jpg";
import order2Ar from "../../assets/guide/order2-ar.jpg";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const GuideOrders = () => {
  const selectLanguage = i18next.language;
  const { t } = useTranslation();

  return (
    <>
      <div>
        <h1 className="capitalize text-[30px] font-semibold text-[#236260] mt-[-4px] font-poppin">
          {t("Orders")}
        </h1>
        <ul className="list-disc">
          <li className="text-lg text-gray-600 my-3">
            <b>{t("V_allOrder_title")} : </b>
            {selectLanguage === "ar"
              ? " سيتم عرض كافة بيانات الأوامر هنا "
              : "Your all orders data will be displayed here like "}{" "}
            <b>
              {" "}
              {selectLanguage === "ar"
                ? " مثل الكل، معلق، تم شحنه، تم تسليمه، تم إلغاؤه."
                : "ALL, PENDING, SHIPPED, DELIVERED, CANCELLED"}
            </b>
            <img
              src={selectLanguage === "ar" ? order1Ar : order1}
              className="w-full md:w-[70%] m-auto my-2"
            />
          </li>
          <li className="text-lg text-gray-600 my-3">
            <b>{t("Return Request")} : </b>{" "}
            {selectLanguage === "ar"
              ? "سيتم عرض كافة بيانات طلب الإرجاع هنا."
              : "Your all Return Request data will be displayed here like"}{" "}
            <b>
              {" "}
              {selectLanguage === "ar"
                ? "مثل الكل ، طلبات الإرجاع، قبول الإرجاع، نجاح الاسترداد، تم إلغاء الطلب."
                : "ALL, RETURN ACCEPTED, REFUND SUCCESSFUL, REQUEST CANCELLED"}
            </b>
            <img
              src={selectLanguage === "ar" ? order2Ar : order2}
              className="w-full md:w-[70%] m-auto my-2"
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default GuideOrders;
