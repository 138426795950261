import React from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { GrCompliance } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import deleteDataById from "../../../hooks/deleteDataById";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import i18next from "i18next";
import axios from "../../../components/utils/Interceptors"

const ProductQueries = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [queries, setQueries] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const selectLanguage = i18next.language;

  useEffect(() => {
    setLoading(true);
    const fetchQueries = async () => {
      const baseUrl = process.env.REACT_APP_APIURL;

      const res = await axios.get(`${baseUrl}/vendor/getallcustomerquery`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const response = await res.data;

      if (response.status_code === 200) {
        setQueries(response.data);
      }
      setLoading(false);
    };
    fetchQueries();
  }, []);

  const selectItemToEdit = async (id) => {
    navigate("/vendor/update_queries", {
      state: id,
    });
  };

  const deleteItem = async (id) => {
    setLoading(true);
    confirmAlert({
      title: `${t("delete_alert_title")}`,
      message: `${t("delete_alert_des")}`,
      buttons: [
        {
          label: `${t("yes")}`,
          onClick: async () => {
            setLoading(true);
            const response = await deleteDataById(
              "vendor/deletecustomerquery",
              id
            );
            if (response.status_code === 200) {
              const newdataList = queries.filter((item) => item.id !== id);
              setQueries(newdataList);
            } else {
              toast.error(response.message);
            }
            setLoading(false);
          },
        },
        {
          label: `${t("no")}`,
          onClick: () => {},
        },
      ],
    });
    setLoading(false);
  };

  const columns = [
    {
      name: `${t("query_cust_name")}`,
      selector: (row) => row.customer_name,
    },
    {
      name: `${t("query_cust_email")}`,
      selector: (row) => row.customer_email,
    },
    {
      name: `${t("Query")}`,
      selector: (row) => (selectLanguage === "ar" ? row.query_ar : row.query),
    },
    {
      name: `${t("all_product_dataTable6")}`,
      cell: (row) => (
        <div className="flex gap-2">
          {!row.resolution ? (
            <>
              <span className="text-primary font-semibold">{t("Pending")}</span>
            </>
          ) : (
            <>
              <span>{row?.resolved_on?.slice(0, 10)}</span>
            </>
          )}
        </div>
      ),
    },
    {
      name: `${t("vd_pc_C_Options")}`,
      sortable: true,
      cell: (row) => (
        <div className="flex gap-2">
          {!row.resolution ? (
            <>
              <span
                onClick={() => selectItemToEdit(row.id)}
                className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300"
                title={`${t("Edit")}`}
              >
                <AiOutlineEdit />
              </span>
              <span
                onClick={() => deleteItem(row.id)}
                className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300"
                title={`${t("Delete")}`}
              >
                <AiOutlineDelete />
              </span>
            </>
          ) : (
            <>
              <span
                onClick={() => selectItemToEdit(row.id)}
                className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300"
                title={`${t("Edit")}`}
              >
                <GrCompliance />
              </span>
              <span
                onClick={() => deleteItem(row.id)}
                className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300"
                title={`${t("Delete")}`}
              >
                <AiOutlineDelete />
              </span>
            </>
          )}
        </div>
      ),
    },
  ];

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div>
        <span className="text-xl font-medium">{t("Query_all")}</span>
        <div className="my-5 bg-white border rounded">
          <DataTable
            columns={columns}
            data={queries}
            pagination
            noDataComponent={
              <h4 className="py-8 text-gray-400 text-center">
                {t("No records")}
              </h4>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ProductQueries;
