import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { createContext } from "react";
import { customerProvider } from "./CustomerContext";
import axios from "../components/utils/CustomerInterceptor"

export const cartProvider = createContext();
const CartContext = ({ children }) => {
  const { customer } = useContext(customerProvider)
  const [cartCount, setCartCount] = useState(0);
  const [refresh, setRefresh] = useState(false)
  const cartRefetch = () => {
    setRefresh(!refresh)
  }
  useEffect(() => {
    if (customer?.token) {
      axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/store/customer/getcartitemcount`,
        {
          headers: {
            Authorization: `Bearer ${customer?.token}`,
          },
        }
      )
        .then((res) => {
          setCartCount(res.data.data[0].total_items);
        });
    } else {
      setCartCount(0);
    }
  }, [customer, cartRefetch]);
  const info = { cartCount, cartRefetch };
  return <cartProvider.Provider value={info}>{children}</cartProvider.Provider>;
};

export default CartContext;
