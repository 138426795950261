import React from "react";
import { useContext } from "react";
import { storeInformation } from "../../../contexts/StoreInformationContext";
import demo_slider1 from "../../../assets/demo-slider1.jpg";
import demo_slider2 from "../../../assets/demo-slider2.jpg";
import demo_slider3 from "../../../assets/demo-slider3.jpg";
import demo_slider4 from "../../../assets/demo-slider4.jpg";
import demo_slider5 from "../../../assets/demo-slider5.jpg";
import demo_slider6 from "../../../assets/demo-slider6.jpg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const ShopCategories = () => {
  const { storeDetails } = useContext(storeInformation);
  const banner = storeDetails?.data?.banner;
  const { t } = useTranslation();

  const demoData = [
    {
      attribute_image: demo_slider6,
      attribute_description: `${t("Tshirts")}`,
    },
    {
      attribute_image: demo_slider3,
      attribute_description: `${t("Children")}`,
    },
    // {
    //   attribute_image: demo_slider2,
    //   attribute_description: `${t("Sunglasses")}`,
    // },
    // {
    //   attribute_image: demo_slider4,
    //   attribute_description: `${t("Shoes")}`,
    // },
    // {
    //   attribute_image: demo_slider5,
    //   attribute_description: `${t("Phone case")}`,
    // },
    // {
    //   attribute_image: demo_slider1,
    //   attribute_description: `${t("Bags")}`,
    // },
  ];

  const selectLanguage = i18next.language;

  return (
    <div className="my-12">
      <div className="Container">
        <div className="px-5">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            {banner && banner.length > 0
              ? banner?.map((banner, idx) => (
                  <div
                    className="relative h-[230px] duration-300 ease-linear hover:scale-105"
                    key={idx}
                  >
                    <div className="w-full h-full">
                      <img
                        className="w-full h-full"
                        src={banner?.attribute_image}
                        alt="t-shirt"
                      />
                    </div>
                    <div className="absolute bottom-0 left-0 w-full h-full flex justify-end flex-col text-black p-7 z-10">
                      <h2 className="capitalize card-title mb-1 text-teal-800 font-domain font-bold">
                        {banner?.attribute_description}
                      </h2>
                    </div>
                  </div>
                ))
              : demoData?.map((banner, idx) => (
                  <div
                    className="relative h-[230px] duration-300 ease-linear hover:scale-[1.03]"
                    key={idx}
                  >
                    <div className="w-full h-full">
                      <img
                        className="w-full h-full"
                        src={banner?.attribute_image}
                        alt="t-shirt"
                      />
                    </div>
                    <div className="absolute bottom-0 left-0 w-full h-full flex justify-end flex-col text-black p-7 z-10">
                      <h2
                        className={`${
                          selectLanguage === "ar" ? "text-3xl" : "text-xl"
                        } capitalize card-title mb-1 text-teal-800 font-domain font-bold`}
                      >
                        {banner?.attribute_description}
                      </h2>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopCategories;
