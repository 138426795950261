import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../../components/utils/LoadingSpinner";
import MapPicker from "../../../../components/utils/GoogleMapPicker";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "../../../../components/utils/Interceptors";

const LocationTab = () => {
  const [loading2, setLoading2] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [storeDetails, setStoreDetails] = useState(null);
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const getStoreDetails = async () => {
    const url = `${process.env.REACT_APP_APIURL}/vendor/getstorefulldetails`;
    const res = await axios.get(url, {
      headers: {
        // 'Content-type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    const response = await res.data;

    if (response.data) {
      setStoreDetails(response.data);
      setValue(response.data.store_phone.toString());
    } else {
      return toast.info(response.message || response.err);
    }
  };

  useEffect(() => {
    getStoreDetails();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const res = await axios.patch(
      `${process.env.REACT_APP_APIURL}/vendor/updatestoredetails`,
      JSON.stringify(data),
      {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${user ? user.token : ""}`,
        },
      }
    );
    let response = await res.data;

    if (response && response.status_code == 200) {
      getStoreDetails();
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div className={` ${!loading2 && "hidden"}`}>
        <LoadingSpinner />
      </div>

      <div>
        <h5 className="mb-5">{t("Enter Address")}</h5>

        <MapPicker
          onSubmit={(data) => {
            onSubmit(data);
          }}
          alternate={false}
          email={storeDetails?.store_email || ""}
          contact={value}
          address={storeDetails?.store_address || ""}
          city={
            storeDetails?.store_city_name ? storeDetails?.store_city_name : ""
          }
          country={
            storeDetails?.store_country_name
              ? storeDetails?.store_country_name
              : ""
          }
          region={
            storeDetails?.store_region_name
              ? storeDetails?.store_region_name
              : ""
          }
          zip={storeDetails?.store_zip ? storeDetails?.store_zip : ""}
        />
      </div>
    </div>
  );
};

export default LocationTab;
