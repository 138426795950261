import React, { useEffect, useRef } from 'react'
import {AiOutlineArrowUp} from 'react-icons/ai'

const ScrollTopButton = ({children}) => {
    const scrollBtn = useRef();
    useEffect(()=>{
        window.addEventListener('scroll', ()=>{
            if(window.scrollY >= 450 ){
                scrollBtn.current?.classList.add('scrollBtn_active')
            }else{
                scrollBtn.current?.classList.remove('scrollBtn_active')
            }
        })
    },[])
    const handleScroll = ()=>{
        window.scrollTo({top:0, behavior:'smooth'})
    }
  return (
    <>
        <div className='flex justify-center items-center w-[40px] h-[40px] fixed right-8 bottom-8 bg-blue-600 cursor-pointer scrollBtn' ref={scrollBtn} onClick={handleScroll}>
            <AiOutlineArrowUp size={20} className="text-white"/>
        </div>
        {children}
    </>
  )
}

export default ScrollTopButton