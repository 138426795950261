import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import ApiLoadingSpinner from "../../../components/utils/ApiLoadingSpinner";
import AllmediaGrid from "./AllmediaGrid";
import SelectableMedia from "./SelectableMedia";
import axios from "axios"

const UpLoadedFileAdmin = ({
  selectable,
  itemPerRow,
  setThumbnailImage,
  thumbnail_image,
  gallery_images,
  setGalleryImages,
  gallerySelect,
}) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  const user = JSON.parse(localStorage.getItem("admin"));

  useEffect(() => {
    const getAllFiles = async () => {
      setLoading(true);

      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/admin/getalltemplate`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;

      if (response.status_code === 200) {
        setFiles(response.data);
      }
      setLoading(false);
    };

    getAllFiles();
  }, []);

  // Get current Posts
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = files.slice(indexOfFirstItem, indexOfLastItem);

  // change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const deleteItem = async (key) => {
    setLoading(true);

    const res = await axios.delete(
      `${process.env.REACT_APP_APIURL}/admin/deletetemplate/${key}`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const response = await res.data;
    if (response.status_code === 200) {
      const newFiles = files.filter((i) => i.key !== key);
      setFiles(newFiles);
    }
    setLoading(false);
  };

  if (gallerySelect) {
    return (
      <SelectableMedia
        deleteItem={deleteItem}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        currentItems={currentItems}
        files={files}
        paginate={paginate}
        setGalleryImages={setGalleryImages}
        gallery_images={gallery_images}
        gallerySelect={gallerySelect}
      />
    );
  }

  return (
    <>
      {loading && <ApiLoadingSpinner />}
      {selectable ? (
        <SelectableMedia
          deleteItem={deleteItem}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          currentItems={currentItems}
          files={files}
          paginate={paginate}
          setThumbnailImage={setThumbnailImage}
          thumbnail_image={thumbnail_image}
        />
      ) : (
        <AllmediaGrid
          deleteItem={deleteItem}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          currentItems={currentItems}
          files={files}
          paginate={paginate}
        />
      )}
    </>
  );
};

export default UpLoadedFileAdmin;
