import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import SectionTitle from "../components/utils/SectionTitle";

const PolicyPage = () => {
  const { t } = useTranslation();
  let selectLanguage = i18next.language;
  return (
    <div id="privacyPolicy">
      <div className="py-7">
        <SectionTitle page="policy" />
      </div>

      <div>
        <h4 className="text-xl font-bold">
          {selectLanguage === "ar"
            ? "سياسة الخصوصية وسرية المعلومات :"
            : "Privacy policy and confidentiality of information :"}
        </h4>
        {selectLanguage === "ar" ? (
          <>
            <p className="my-3 text-justify">
              يرحب بك فريق منبر تكنولوجيا الذكاء الاصطناعي (AI Tech) لتكنولوجيا
              المعلومات من خلال منبره الإلكتروني. تشكرك منصة AI TechPlatform على
              ثقتك في المنصة. تخبرك منصة تكنولوجيا الذكاء الاصطناعي أنه، من
              منطلق الاهتمام بمنصة تكنولوجيا الذكاء الاصطناعي وإدراكها الكامل
              بأن للتاجر حقوقا معينة من خلال منصة تكنولوجيا الذكاء الاصطناعي،
              تسعى منصة تكنولوجيا الذكاء الاصطناعي إلى الحفاظ على معلومات التجار
              والمستهلكين وفقا لآلية سياسة الخصوصية وسرية المعلومات المعمول بها
              في منصة تكنولوجيا الذكاء الاصطناعي. بناء على ذلك، فإن منصة تقنية
              الذكاء الاصطناعي تشير إلى أنه وفقا لاتفاقية الاستخدام المبرمة
              بينكم كتجار ومنبر تقنيات الذكاء الاصطناعي، تفيدكم هذه الوثيقة
              بسياسة الخصوصية وسرية المعلومات السارية في المنبر الخاص
              بتكنولوجيات الذكاء الاصطناعي، وأنه وفقا للبند الرابع من المادة
              الخامسة عشرة من إتفاقية الاستخدام، أنشأت منصة لتكنولوجيا الذكاء
              الاصطناعي توضح هذه القواعد "سياسة الخصوصية وسرية المعلومات" ويحدد
              آلية السرية والخصوصية المطبقة في منصة تكنولوجيا الذكاء الاصطناعي
              الإلكترونية، ويرجى إبلاغكم بها عند دخولكم إلى منصة تكنولوجيا
              الذكاء الاصطناعي وتأسيس المتجر الإلكتروني، حيث تخضع كل معلوماتكم
              لهذه السياسة .
            </p>
          </>
        ) : (
          <>
            <p className="my-3 text-justify">
              The team of the artificial intelligence technologies (AI Tech)
              platform for information technology welcomes you through its
              electronic platform. The AI Techplatform thanks you for your trust
              in the platform. The AI Techplatform informs you that, out of
              concern for the AI Techplatform and its full awareness that the
              merchant has certain rights through the AI Techplatform, the The
              AI Techplatform seeks to preserve the information of merchants and
              consumers in accordance with the mechanism of the privacy policy
              and confidentiality of information in force in the AI
              Techplatform. Accordingly, the platform for AI Technotes that
              according to the usage agreement concluded between you as
              merchants and the platform for artificial intelligence
              technologies, this document informs you of the privacy policy and
              confidentiality of information in force in the platform for
              artificial intelligence technologies, and that in accordance with
              the fourth clause of Article fifteen of the usage agreement, it
              has established a platform Artificial Intelligence Technologies
              These rules “Privacy Policy and Confidentiality of Information”
              clarify and define the confidentiality and privacy mechanism
              applied in the electronic AI Techplatform, and please inform you
              of them as you access the AI Techplatform and your establishment
              of the online store, all your information is subject to this
              policy .
            </p>
          </>
        )}
      </div>

      <div className="mt-8">
        <h4 className="text-xl font-bold">
          {selectLanguage === "ar"
            ? "المعلومات التي حصلت عليها منصة AI Tech وتحفظ في أنظمتها الإلكترونية :"
            : "Information obtained by the  AI Tech platform and kept in its electronic systems :"}
        </h4>
        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li>
                المعلومات التي تحصل عليها منصة AI Tech وتحفظ في أنظمتها
                الإلكترونية: معلومات المستهلك الشخصية، مثل الاسم أو البريد
                الإلكتروني أو رقم الهوية الوطنية أو رقم الإقامة .
              </li>
              <li>
                المعلومات الشخصية للتاجر، مثل الاسم أو السن أو البريد الإلكتروني
                أو رقم الهوية الوطنية أو رقم الإقامة
              </li>
              <li>
                معلومات عن المتجر وكيانه القانوني مثل رقم التسجيل التجاري ونسخة
                عن التسجيل التجاري .
              </li>
              <li>
                معلومات عن المتجر وكيانه القانوني مثل رقم التسجيل التجاري ونسخة
                عن التسجيل التجاري .
              </li>
              <li>
                في حال لم يقدم التاجر المعلومات المطلوبة، قد تحاول منصة تقنية
                الذكاء الاصطناعي الحصول عليها من خلال مصادر أخرى .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal text-justify py-3 pl-4">
              <li className="mb-3">
                Consumer personal information, such as name, e-mail, national
                identity number or residence number .
              </li>
              <li className="mb-3">
                Merchant's personal information, such as name, age, e-mail,
                national identity number or residence number
              </li>
              <li className="mb-3">
                Information about the store and its legal entity, such as the
                commercial registration number and a copy of the commercial
                registration .
              </li>
              <li className="mb-3">
                Personal login information for the online store, such as the
                username, password, e-mail, and the password recovery question
                and answer .
              </li>
              <li className="mb-3">
                In the event that the merchant does not provide the required
                information, the artificial intelligence technology platform may
                try to obtain it through other sources .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-8 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              معلومات الإيراد أو السلع أو الخدمات للمحلات :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Revenue information, goods or services for stores :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] py-3 pr-4">
              <li>
                منصة AI Techis تعي دائما الإيرادات المتولدة في المتجر، لأن
                التاجر يستخدم بوابات الدفع التي توفرها منصة AI Tech.
              </li>
              <li>
                منصة آي آي تيكس تدرك نوع السلع أو الخدمات المقدمة على منصة
                المتجر .
              </li>
              <li>
                منصة AI Techis على دراية بأداء المتاجر في حال كانت هناك حاجة
                لتوجيه الدعم والمشورة والإرشاد للتجار أو المتاجر لمساعدتهم
                وتحسين أدائهم .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                The platform of AI Techis always aware of the revenue generated
                in the store, because the merchant uses the payment gateways
                provided by the platform of AI Tech.
              </li>
              <li className="mb-3">
                {" "}
                The platform of AI Techis aware of the type of goods or services
                offered on the store platform .
              </li>
              <li className="mb-3">
                {" "}
                The platform of AI Techis aware of the performance of stores, in
                the event that there is a need to direct support, advice and
                guidance to merchants or stores to help them and improve their
                performance .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-8 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              معلومات عن مستهلكي المتاجر أو زبائنها أو زبائنها :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Information of store consumers or customers or customers of
              merchants :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <p className="py-3">
              حيث أن منصة تكنولوجيا الذكاء الاصطناعي تسعى إلى الحفاظ على جودة
              عمل المتاجر، وتحسين جودة عملها، فإنها تنظر باستمرار إلى عدد
              المستهلكين في المتاجر، وعملائهم، وقطعاتهم .
            </p>
          </>
        ) : (
          <>
            <p className="py-3">
              As the platform of AI Techseeks to maintain the quality of the
              stores' work, and improve the quality of their work, it constantly
              looks at the number of store consumers, their customers, and their
              segments .
            </p>
          </>
        )}
      </div>

      <div className="mt-8 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              مشاركة المعلومات حول المتاجر والتجار :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Sharing information about stores and merchants :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] py-3 pr-4">
              <li className="mb-3">
                طبعا، فإن منصة تقنية الذكاء الاصطناعي تسعى إلى إبقاء هذه
                المعلومات بطريقة تحافظ على خصوصية التاجر، ومنصة تقنية الذكاء
                الاصطناعي لا تحتفظ بهذه المعلومات إلا بهدف تحسين نوعية المنبر
                ونوعية عمل المتاجر وتسهيل وتسهيل عمل التجار والمتاجر .
              </li>
              <li className="mb-3">
                وكقاعدة عامة، لا يطلع على كل هذه المعلومات سوى بعض المسؤولين عن
                منصة تكنولوجيا الذكاء الاصطناعي، بعد الحصول على تصريح لاستعراضها
                من إدارة منصة تكنولوجيا الذكاء الاصطناعي - وعادة ما يكون التصريح
                محددا ومحدودا ويخضع لرقابة قانونية وإدارية من قبل منصة تكنولوجيا
                الذكاء الاصطناعي - ولن يتم نشر هذه المعلومات أو بثها إلى الآخرين
                .
              </li>
              <li className="mb-3">
                وحيث أن المنصة التي تستخدمها تقنية الذكاء الاصطناعي تسعى إلى
                الحفاظ على سلامة المتاجر وحقوق التجار، فهي - في حال لاحظت منصة
                تقنية الذكاء الاصطناعي أي نشاط غير نظامي أو غير قانوني يقوم به
                التاجر - منصة تقنية الذكاء الاصطناعي وفقا لمواد وأحكام وشروط
                إتفاقية الاستخدام، وتسعى قدر المستطاع من أجل الحفاظ على سلامة
                عمل المتاجر، يمكنها أن تشارك في أي من هذا المعلومات مع الجهات
                المختصة لاتخاذ الإجراءات اللازمة ضد التاجر أو المتجر المخالف،
                وذلك لحماية منصة أي تي إي وبقية التجار والمتاجر والمستهلكين من
                أي مسؤولية قانونية قد تقع على المنصة أو أحد مستخدميها نتيجة
                لنشاط الطرف الثالث. قانوني أو غير قانوني .
              </li>
              <li className="mb-3">
                التاجر الذي استهل تسجيل متجره بمنصة آي تيشبي شركاء النجاح
                وبالتالي منح شريك النجاح الذي سجل معه الحق في الاطلاع على جميع
                المعلومات المتعلقة بالتاجر ومتجره .
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                Of course, the platform of AI Techseeks to keep this information
                in a way that preserves the privacy of the merchant, and the
                platform of AI Techdoes not keep this information except with
                the aim of improving the quality of the platform and the quality
                of the work of stores and to facilitate and facilitate the work
                of merchants and stores .
              </li>
              <li className="mb-3">
                {" "}
                As a general rule, all this information is seen only by some of
                those in charge of the AI Techplatform, after obtaining a permit
                to view it from the management of the AI Techplatform – the
                permit is usually specific and restricted and subject to legal
                and administrative control by the AI Techplatform – and will not
                This information is published or broadcasted to others .
              </li>
              <li className="mb-3">
                {" "}
                As the platform of AI Techseeks to preserve the safety of stores
                and the rights of merchants, it is - in the event that the
                platform of AI Technotices any irregular or illegal activity
                carried out by the merchant - the platform of AI Techin
                accordance with the articles, terms and conditions of the usage
                agreement and as it seeks as much as possible In order to
                maintain the integrity of the work of the stores, it may share
                any of this information with the competent authorities to take
                the necessary action against the violating merchant or store, in
                order to protect the platform of AI Techand the rest of the
                merchants, stores and consumers from any legal liability that
                may occur on the platform or one of its users as a result of
                this third-party activity. legal or illegal .
              </li>
              <li className="mb-3">
                {" "}
                The merchant who initiates the registration of his store with
                the platform of AI Techby the success partners, thus granting
                the success partner who registered with him the right to view
                all the information related to the merchant and his store .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-8 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              مدى أمان سرية المعلومات بالنسبة للتجار والمستهلكين أو المتاجر :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              How secure is the confidentiality of information for merchants,
              consumers or stores :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <p className="my-2">
              تسعى منصة تكنولوجيا الذكاء الاصطناعي إلى الحفاظ على سرية معلومات
              مستخدمي المنصة، وبما أن سياسة الخصوصية للتجار أو المتاجر لن تنتهك
              أحكام إتفاقية الاستخدام أو سياسة الخصوصية وسرية المعلومات. ومع
              ذلك، بما أنه لا يمكن ضمان ذلك بنسبة 100٪ في ( فضاء الإنترنت)،
              يلاحظ فريق منصة تقنيات الذكاء الاصطناعي ما يلي :
            </p>
            <ul className="list-[upper-roman] py-3 pr-4">
              <li className="mb-3">
                تسعى منصة تكنولوجيا الذكاء الاصطناعي إلى الحفاظ على جميع
                المعلومات حول المتاجر وعدم رؤيتها من قبل أي شخص في انتهاك
                للسياسة المعمول بها في منصة تكنولوجيا الذكاء الاصطناعي.
              </li>
              <li className="mb-3">
                تعمل منصة تكنولوجيا الذكاء الاصطناعي على حماية معلومات التجار
                والمتاجر في ظل أنظمة حماية إلكترونية وتقنية عالية الجودة يتم
                تحديثها باستمرار وبشكل دوري .
              </li>
              <li className="mb-3">
                ومع ذلك، بما أن الإنترنت لا يمكن ضمانه بنسبة 100٪ بسبب الاختراق
                أو الفيروسات التي قد تحدث على أنظمة الحماية الإلكترونية وعلى
                الجدران النارية المطبقة في منصة تقنيات الذكاء الاصطناعي، فإن
                منصة خبراء الذكاء الاصطناعي للتجار للحفاظ على سرية معلوماتهم
                بشكل صارم، وعدم الكشف عن أي معلومات يرى التاجر أن ذلك مهم جدا
                بالنسبة له، وهذا في مصلحة منصة تقنية الذكاء الاصطناعي للحماية
                والتوجيه ارشاد التجار والمحال .
              </li>
            </ul>
          </>
        ) : (
          <>
            <p className="my-2">
              The artificial intelligence technology platform seeks to maintain
              the confidentiality of the information of the platform users, and
              since the privacy policy of merchants or stores will not violate
              the provisions of the usage agreement or the privacy policy and
              confidentiality of information. However, since this cannot be
              guaranteed 100% in ( internet space), the team of the Artificial
              Intelligence Technologies Platform notes the following :
            </p>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-3">
                {" "}
                The platform of AI Techseeks to preserve all information about
                stores and not to be seen by anyone in violation of the policy
                in force in the platform of AI Tech.
              </li>
              <li className="mb-3">
                {" "}
                The artificial intelligence technology platform works to protect
                the information of merchants and stores under high-quality
                electronic and technical protection systems that are
                continuously and periodically updated .
              </li>
              <li className="mb-3">
                {" "}
                However, since the Internet cannot be 100% guaranteed due to the
                penetration or viruses that may occur on the electronic
                protection systems and on the firewalls applied in the platform
                of artificial intelligence technologies, the platform of AI
                Techadvises merchants to keep their information strictly
                confidential, and not to disclose any information The merchant
                sees it as very important to him, and this is in the interest of
                the AI Techplatform to protect, guide and guide merchants and
                stores .
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-8 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              الخدمات الاستراتيجية واللوجستية (خدمات طرف ثالث). :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Strategic and logistical services ( third party services). :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <p className="my-3 text-justify">
              يقر التاجر بعلمه التام والجهل أنه إذا تقدم بطلب الاشتراك في خدمة
              يقدمها طرف ثالث فإنه يمنح ترخيصا وترخيصا وترخيصا كاملين وكاملا
              لمنصة آي تي تكنيك لتزويد مزود الخدمة ببيانات التاجر أو المتجر أو
              المستخدم المشترك مع مزود الخدمة مثل: اسم المستخدم - الهاتف الشخصي
              - البريد الإلكتروني - الهوية أو رقم إيكاما - عنوان المنزل،
              ومعلومات أخرى مطلوبة بواسطة مزود الخدمة (الطرف الثالث). وهذا لكي
              يتمكن مزود الخدمة (الطرف الثالث) من توفير الخدمة المطلوبة التي
              اشترك فيها المستخدم .
            </p>
          </>
        ) : (
          <>
            <p className="my-2">
              The merchant acknowledges his full and ignorant knowledge that if
              he applies for a request to subscribe to a service provided by a
              third party , he grants full and complete permission,
              authorization, and authorization to the platform of AI Techto
              provide the service provider with the data of the merchant, store,
              or user subscribed to the service provider, such as: username –
              Personal phone – E-mail – Identity or Iqama number – Home address,
              and other information needed by the service provider (the third
              party). This is so that the service provider (the third party) can
              provide the required service that the user has subscribed to .
            </p>
          </>
        )}
      </div>

      <div className="mt-8 mb-10 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              القواعد والأحكام الخاصة باستخدام منصة تكنولوجيا الذكاء الاصطناعي :
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Rules and provisions for using the artificial intelligence
              technology platform :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <p className="my-3 text-justify">
              جميع التزامات منصة AI TechPlatform، وجميع التزامات التجار، وجميع
              الحقوق الواردة في العلاقة بين التاجر و AI TechPlatform موجودة هنا
              http://ai-tech.com.sa/terms/. حيث أن هذه القواعد هي "سياسة
              الخصوصية وسرية المعلومات" المنبثقة عن الاتفاقية المبرمة بين التاجر
              ومنصة AI Tech عند إنشاء المتجر .
            </p>
          </>
        ) : (
          <>
            <p className="my-2">
              All the obligations of the AI Techplatform, all the obligations of
              the merchants, and all the rights contained in the relationship
              between the merchant and the AI Techplatform are found here
              http://ai-tech.com.sa/terms/. Whereas, these rules are the
              “Privacy Policy and Confidentiality of Information” emanating from
              the agreement concluded between the merchant and the platform of
              AI Techwhen establishing the store .
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default PolicyPage;
