import React from 'react';
import SectionTitle from '../utils/SectionTitle';
import Div from '../utils/Div';
import decstop from '../../assets/doctop.png'
import decstop2 from '../../assets/doctop2.png'
import { useTranslation } from 'react-i18next';

const About = () => {
    const {t} = useTranslation();

    return (
        <section id="review" className="px-2 relative overflow-hidden mb-10">
            <Div>
                <div className='pb-7'>
                    <SectionTitle page={"about"} />
                </div>
                <div className='py-5'>  
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-3 justify-center'>
                        <div className='order-last md:order-first'>
                            <figure><img src={decstop} className="w-10/12" alt="Album" /></figure>
                        </div>
                        <div className="md:mt-7 text-[#236260]">
                            <h4 className='text-primary'>  {t("HA_title1")}</h4>
                            <ul className={`text-[19px] flex flex-col leading-5 gap-4 list-disc ml-4 pt-5`}>
       
                                <li className="">
                                    {t("HA_item1")}
                                </li>
                                <li className="">
                                {t("HA_item2")}
                                </li>
                                <li className="">
                                {t("HA_item3")}
                                </li>
                                <li className="">
                                {t("HA_item4")}
                                </li>
                                <li className="">
                                {t("HA_item5")}
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div className="py-19">
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-3 justify-center'>
                        <div className="md:mt-7 text-[#236260] md:order-first">
                        <h4 className='text-primary'>{t("HA_title2")}</h4>
                            <ul className={`text-[19px] flex flex-col leading-5 gap-4 list-disc ml-4 pt-5`}>
       
                                <li className="">
                                {t("HA_item6")}
                                </li>
                                <li className="">
                                {t("HA_item7")}
                                </li>
                                <li className="">
                                {t("HA_item8")}
                                </li>
                                <li className="">
                                {t("HA_item9")}
                                </li>
                                <li className="">
                                {t("HA_item10")}
                                </li>

                            </ul>
                        </div>
                        <div className='order-last '>
                            <figure><img src={decstop2} className="w-10/12" alt="Album" /></figure>
                        </div>
                    </div>
                </div>
            </Div>

        </section >
    );
};

export default About;